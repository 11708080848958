import {
  FileParameter,
  FormButton,
  ModelField,
  ModelFieldType,
} from "../utils/api";
import { makeAutoObservable } from "mobx";

interface IFieldWrapper {
  field: ModelField | undefined;
  fieldValue: any | undefined;
  imageUrl: any | undefined;
  hasError: boolean;
  isRequired: boolean;
  isVisible: boolean;
  onValueChange: ((name: string) => void) | undefined;
  canSave: () => boolean;
  validate: () => void;
  removedFiles: string[];
}

export class FieldWrapper implements IFieldWrapper {
  field: ModelField | undefined = undefined;
  fieldValue: any | undefined = undefined;
  imageUrl: any | undefined = undefined;
  hasError = false;
  isRequired = false;
  fileNames: string[] = [];
  originalFiles: string[] = [];
  removedFiles: string[] = [];
  isVisible: boolean = true;
  onValueChange: ((name: string) => void) | undefined = undefined;

  removeFile = (fileName: string) => {
    this.fileNames = this.fileNames.filter((name) => name !== fileName);
    if (this.fieldValue) {
      const newList = this.fieldValue.filter(
        (item: FileParameter) => item.fileName !== fileName
      );
      if (newList.length > 0) {
        this.fieldValue = newList;
      } else {
        this.fieldValue = undefined;
      }
    }

    if (this.originalFiles.includes(fileName)) {
      this.removedFiles.push(fileName);
    }
    this.hasError =
      (this.field?.isRequired ?? false) &&
      (!this.fieldValue || this.fieldValue.length === 0);
    if (this.onValueChange) {
      this.onValueChange(this.field?.label ?? "");
    }
  };

  validate = () => {
    this.hasError = !this.canSave();
  };

  canSave = () => {
    let canSaveData = true;
    if (this.field?.isRequired ?? false) {
      if (this.field?.fieldType === ModelFieldType.Image) {
        canSaveData =
          (this.imageUrl !== undefined && this.imageUrl !== null) ||
          this.fieldValue !== undefined;
      } else if (this.field?.fieldType === ModelFieldType.FileUpload) {
        const hasOriginalFiles =
          this.originalFiles.filter((x) => !this.removedFiles.includes(x))
            .length > 0;
        canSaveData =
          hasOriginalFiles || (this.fieldValue && this.fieldValue.length > 0);
        console.log(
          "Can save fileupload",
          hasOriginalFiles,
          this.originalFiles,
          this.removedFiles,
          this.fieldValue
        );
      } else {
        canSaveData = this.fieldValue !== undefined && this.fieldValue !== "";
      }
    }
    return canSaveData;
  };

  setFileUploadValues = (newFiles: FileParameter[]) => {
    if (newFiles) {
      if (this.fieldValue) {
        const existingFiles = this.fieldValue as FileParameter[];
        newFiles.forEach((file) => {
          if (!this.fileNames.includes(file.fileName)) {
            this.fileNames = [...this.fileNames, file.fileName];
            existingFiles.push(file);
          }
        });
        this.fieldValue = existingFiles;
      } else {
        this.fieldValue = newFiles;
        newFiles.forEach((file) => {
          if (!this.fileNames.includes(file.fileName)) {
            this.fileNames = [...this.fileNames, file.fileName];
          }
        });
      }
    }
  };

  setIsRequired = (isRequired: boolean) => {
    this.isRequired = isRequired;
  };

  setIsVisible = (isVisible: boolean) => {
    this.isVisible = isVisible;
  };

  setFieldValue = (value: any) => {
    if (this.field?.fieldType === ModelFieldType.FileUpload) {
      this.setFileUploadValues(value as FileParameter[]);
    } else {
      this.fieldValue = value;
    }

    this.hasError = (this.field?.isRequired ?? false) && !this.fieldValue;
    if (this.onValueChange) {
      this.onValueChange(this.field?.label ?? "");
    }
  };

  setField = (
    modelField: ModelField,
    onValueChange: (name: string) => void
  ) => {
    this.field = modelField;

    if (modelField.fieldType === ModelFieldType.Image) {
      this.imageUrl = modelField.value;
    } else if (modelField.fieldType === ModelFieldType.FileUpload) {
      this.fieldValue = undefined;
      if (modelField.value && modelField.value !== "") {
        const existingFiles = modelField.value.split(",");
        this.fileNames = [...existingFiles];
        this.originalFiles = [...existingFiles];
      }
    } else if (modelField.fieldType === ModelFieldType.BooleanField) {
      console.log("Steting boolean field", modelField.value);
      this.fieldValue = modelField.value === "true" ? true : false;
      console.log("Steting boolean field", modelField.value, this.fieldValue);
    } else {
      this.fieldValue = modelField.value;
    }
    this.onValueChange = onValueChange;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export interface IFormButtonWrapper {
  button: FormButton | undefined;
  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
  setButton: (button: FormButton, isEnabled: boolean) => void;
  onClick: () => void;
}

export class FormButtonWrapper implements IFormButtonWrapper {
  button: FormButton | undefined;
  isDisabled: boolean = false;

  setIsDisabled = (isDisabled: boolean) => {
    this.isDisabled = isDisabled;
  };

  setButton = (button: FormButton, isDisabled: boolean) => {
    this.button = button;
    this.isDisabled = isDisabled;
  };

  onClick = () => {
    this.clickHandleCallback?.(this.button?.value ?? "");
  };

  constructor(private clickHandleCallback: (key: string) => void) {
    makeAutoObservable(this);
  }
}
