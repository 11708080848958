import { useContext, useEffect, useState } from "react";
import { MSMESelectorView } from "./msme-selector/msmeSelector";
import { BasePage } from "../../controls/basePage";
import { LanguageContext } from "../../core/language";
import { CertificationManagerViewModel } from "./certificationManagerViewModel";
import { IBasePageViewModel } from "../../common/pageTitleViewModel";
import { MSMEGoals } from "./msme-goals/msmeGoalSelector";
import { useNavigate, useParams } from "react-router-dom";

export const CertificationManager = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<
    CertificationManagerViewModel | undefined
  >(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedLanguage) {
      setViewModel(
        new CertificationManagerViewModel(selectedLanguage, navigate)
      );
    }
  }, [selectedLanguage]);

  if (viewModel) {
    return (
      <BasePage pageDetails={viewModel as IBasePageViewModel}>
        <MSMESelectorView viewModel={viewModel.msmeSelectorViewModel} />
      </BasePage>
    );
  }
  return null;
};
