import { makeAutoObservable, runInAction } from "mobx";
import { IBaseScreenDto, WidgetClient, ResultOfWidgetDto, WidgetDto } from "../../utils/api";
import {
  IContextSensitiveViewModel,
  ContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import { DialogType } from "../../controls/dialogs";
export interface IDashboardListViewModel extends IBasePageViewModel {
    model: WidgetDto | undefined;
}
export class DashboardListViewModel implements IDashboardListViewModel
{
    contextViewModel: IContextSensitiveViewModel=new ContextSensitiveViewModel();
    dialogViewModel: IDialogViewModel=new DialogViewModel();
    pageTitleViewModel: IPageTitleViewModel=new PageTitleViewModel();
    model: WidgetDto | undefined = undefined;
    widthOverride?: "xl" | "xs" | "lg" | "md" | undefined = "xl";
    constructor (private selectedLanguage:string)
    {
        makeAutoObservable(this);
        runInAction(()=> {
            this.fetchData();    
        })
    }
    setWidgets=(result:ResultOfWidgetDto)=> {
        if (result.succeeded) {
            this.dialogViewModel.clearDialog();
            this.model=result.value;
            this.contextViewModel.setContextActivities(this.model?.contextActions??[]);
            this.pageTitleViewModel.setPageDetails(this.model as IBaseScreenDto, undefined);

        }
        else {
            this.dialogViewModel.setDialog(
                    result.errorCode,
                    result.error,
                    DialogType.Alert
                );            
        }
    }
    fetchData=() => {
      const client=new WidgetClient();
      client.getWidgets(this.selectedLanguage)
      .then((result)=> this.setWidgets(result))
      .catch((reason)=> {
        this.dialogViewModel.setDialog(reason,reason,DialogType.Alert);
      });  
    };
    testPaymentLink=()=> {
      const client=new WidgetClient();
      client.testPaymentEmail().then((result)=>{}).catch((reason)=> {
        this.dialogViewModel.setDialog(reason,reason,DialogType.Alert);
      });
    }
}