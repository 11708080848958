import { makeAutoObservable, runInAction } from "mobx";
import {
  ResultOfTenantProfileDto,
  TenantClient,
  IBaseScreenDto,
  TenantProfileDto,
} from "../../utils/api";
import {
  IContextSensitiveViewModel,
  ContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import { NavigateFunction } from "react-router-dom";
import { DialogType } from "../../controls/dialogs";
import { ROUTES } from "../../core/constants";
import { FieldWrapper, FormButtonWrapper } from "../../common/formTypes";

interface ITenantProfileViewModel extends IBasePageViewModel {
  model: ITenantProfileEditFormModel | undefined;
}

export class TenantProfileViewModel implements ITenantProfileViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  model: TenantProfileEditFormModel | undefined =
    new TenantProfileEditFormModel();

  constructor(
    private selectedLanguage: string,
    private navigate: NavigateFunction
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  onSave = () => {
    if (!this.model?.isValidData()) {
      return;
    }
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    const client = new TenantClient();
    client
      .updateTenantProfileLoginPage(
        this.model.title?.fieldValue,
        this.model.subTitle?.fieldValue,
        this.model.logoImage?.fieldValue,
        this.model.homepageImage?.fieldValue,
        this.selectedLanguage
      )
      .then((result) => {
        if (result.succeeded) {
          this.navigate(ROUTES.DASHBOARD);
        } else {
          this.dialogViewModel.setDialog(
            result.errorCode,
            result.error,
            DialogType.Alert
          );
        }
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog("Error", reason, DialogType.Alert);
      });
  };
  onCancel = () => {
    this.navigate(-1);
  };
  setTenantProfile = (result: ResultOfTenantProfileDto) => {
    if (result.succeeded) {
      this.contextViewModel.setContextActivities(
        result.value?.contextActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        ""
      );
      this.model?.setData(
        result.value ?? new TenantProfileDto(),
        this.onSave,
        this.onCancel
      );
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };
  fetchData = () => {
    const client = new TenantClient();
    client
      .getTenantProfileLoginPage(this.selectedLanguage)
      .then((result) => this.setTenantProfile(result))
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };
}
export interface ITenantProfileEditFormModel {
  pageTitle: string;
  title: FieldWrapper | undefined;
  subTitle: FieldWrapper | undefined;
  logoImage: FieldWrapper | undefined;
  homepageImage: FieldWrapper | undefined;
  actions: FormButtonWrapper[];
  cancelAction: FormButtonWrapper | undefined;
  setData: (
    model: TenantProfileDto,
    onSave: () => void,
    onCancel: () => void
  ) => void;
  isValidData: () => boolean;
  onSave?: () => void;
  onCancel?: () => void;
}
export class TenantProfileEditFormModel implements ITenantProfileEditFormModel {
  onValueChange = () => {
    const isValidData =
      this.title.canSave() &&
      this.subTitle.canSave() &&
      this.logoImage.canSave() &&
      this.homepageImage.canSave();
    this.actions.forEach((action) => action.setIsDisabled(!isValidData));
  };
  pageTitle: string = "";
  title: FieldWrapper = new FieldWrapper();
  subTitle: FieldWrapper = new FieldWrapper();
  logoImage: FieldWrapper = new FieldWrapper();
  homepageImage: FieldWrapper = new FieldWrapper();
  cancelAction: FormButtonWrapper | undefined = undefined;
  actions: FormButtonWrapper[] = [];
  isValidData = () => {
    this.title.validate();
    this.subTitle.validate();
    this.logoImage.validate();
    this.homepageImage.validate();

    return !(
      this.title.hasError ||
      this.subTitle.hasError ||
      this.logoImage.hasError ||
      this.homepageImage.hasError
    );
  };
  private cancelHandle = () => {
    if (this.onCancel) {
      this.onCancel();
    }
  };

  private actionHandle = (action: string) => {
    if (action === "SAVE") {
      this.onSave?.();
    }
  };
  onSave?: () => void;
  onCancel?: () => void;
  setData = (
    model: TenantProfileDto,
    onSave?: () => void,
    onCancel?: () => void
  ) => {
    this.pageTitle = model.formTitle;
    this.title.setField(model.title, this.onValueChange);
    this.subTitle.setField(model.subTitle, this.onValueChange);
    this.logoImage.setField(model.logoImage, this.onValueChange);
    this.homepageImage.setField(model.homepageImage, this.onValueChange);
    this.onSave = onSave;
    this.onCancel = onCancel;
    this.actions = model.actions.map((button) => {
      const wrapper = new FormButtonWrapper(this.actionHandle);
      wrapper.setButton(button, true);
      return wrapper;
    });
    this.cancelAction = new FormButtonWrapper(this.cancelHandle);
    this.cancelAction.setButton(model.cancelAction, true);
  };

  constructor() {
    makeAutoObservable(this);
  }
}
