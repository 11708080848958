import { makeAutoObservable } from "mobx";
import {
  CertificationClient,
  MSMECertificationDto,
  ResultOfMSMECertificationDto,
} from "../../../utils/api";
import { DialogResult, DialogType } from "../../../controls/dialogs";
import { IDialogViewModel } from "../../../common/dialogViewModel";

export interface IMSMEGoalsViewModel {
  selectedMsmeId: string | undefined;
  model: MSMECertificationDto | undefined;
  isVisible: boolean;
}

export class MSMEGoalsViewModel implements IMSMEGoalsViewModel {
  selectedMsmeId: string | undefined;
  model: MSMECertificationDto | undefined;
  isVisible = false;

  constructor(
    private selectedLanguage: string,
    private dialogViewModel: IDialogViewModel | undefined
  ) {
    makeAutoObservable(this);
  }

  setIsVisible = (visible: boolean) => {
    this.isVisible = visible;
  };
  setViewModelData = (result: ResultOfMSMECertificationDto) => {
    if (result.succeeded) {
      this.model = result.value;
      this.selectedMsmeId = result.value?.msmeId;
    } else {
      this.selectedMsmeId = undefined;
    }
  };

  setCertificate(id: string | undefined) {
    this.setIsVisible(true);
    const client = new CertificationClient();
    client
      .getCertificationModel(this.selectedLanguage, id)
      .then((result) => this.setViewModelData(result))
      .catch((error) => {
        this.selectedMsmeId = undefined;
        console.log(error);
      });
  }

  handleSelectionConfirmation = (badgeId: string) => {
    const client = new CertificationClient();
    client
      .registerForCertification(
        this.selectedLanguage,
        this.selectedMsmeId,
        badgeId
      )
      .then((result) => this.setViewModelData(result))
      .catch((error) => console.log(error));
  };

  handleGoalSelection = (id: string) => {
    const selectedGoal = this.model?.availableBadges?.rowData.filter(
      (x) => x.id === id
    );
    if (selectedGoal && selectedGoal.length === 1) {
      const rowAction = selectedGoal[0].rowActions[0];
      if (rowAction.confirmation) {
        this.dialogViewModel?.setDialog(
          rowAction.confirmation?.title,
          rowAction.confirmation?.message,
          DialogType.YesNo,
          (dialogResult) => {
            if (dialogResult === DialogResult.Yes) {
              this.handleSelectionConfirmation(id);
            }
          }
        );
      }
    }
  };
}
