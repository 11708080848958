import { LanguageEntryDto } from "../utils/api";

export interface IKeyedObject<T> {
  [id: string]: T;
}

export interface ILanguage {
  selected: boolean;
  Name: string;
  Flag: string;
}
export interface ILanguageContext {
  selectedLanguage: string;
  translate(category: any, source: IKeyedObject<string>): IKeyedObject<string>;
  changeLanguage: (language: string) => void;
  getCategory(category: any): { [key: string]: LanguageEntryDto };
  getLanguages: () => string[];
}

export interface IActionType {
  type: string;
  payload: any;
}

export interface IEventType {
  target: { name: any; value: any };
}
export enum UserAccountType {
  AppUser = 0,
  TenantUser = 1,
  MsmeUser = 2,
  AgentUser = 3,
}
export enum PageMode {
  List,
  Add,
  Edit,
  Delete,
  Custom,
}
