import { BusinessEditFormModel } from "./msmeEditViewModel";
import { FieldWrapperControl } from "../../controls/fieldWrapperControl";
import { Box } from "@mui/material";
import { observer } from "mobx-react";

export const BasicDetails = observer(
  (props: { model: BusinessEditFormModel | undefined }) => {
    if (!props.model) {
      return null;
    }
    return (
      <>
        <FieldWrapperControl {...props.model.companyType} />
        <FieldWrapperControl {...props.model.companyName} />
        <FieldWrapperControl {...props.model.address1} />
        <FieldWrapperControl {...props.model.address2} />
        <FieldWrapperControl {...props.model.address3} />
        <FieldWrapperControl {...props.model.address4} />
        <FieldWrapperControl {...props.model.postCode} />
        <FieldWrapperControl {...props.model.state} />
        <Box sx={{ mt: 2 }}>
          <FieldWrapperControl {...props.model.countryId} />
        </Box>
      </>
    );
  }
);
