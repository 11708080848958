import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../core/language";
import { BasePage } from "../../../controls/basePage";
import { QuestionnaireViewModel } from "./questionnaireViewModel";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { QuestionStepperView } from "./questionStepper";
import { QuestionView } from "./questionView";
import { QuestionActions } from "./QuestionActions";
import { TodoContext } from "../../../core/todoContext";

export const Questionnaire = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<
    QuestionnaireViewModel | undefined
  >(undefined);
  const { id } = useParams();
  const navigate = useNavigate();
  const { refreshTodo } = useContext(TodoContext);
  useEffect(() => {
    if (selectedLanguage) {
      setViewModel(
        new QuestionnaireViewModel(selectedLanguage, id ?? "", navigate, refreshTodo)
      );
    }
  }, [selectedLanguage]);

  return <QuestionnaireView viewModel={viewModel} />;
};

export interface IQuestionnaireViewProps {
  viewModel?: QuestionnaireViewModel;
}
const QuestionnaireView = observer((props: IQuestionnaireViewProps) => {
  console.log(props);
  if (props.viewModel) {
    return (
      <BasePage pageDetails={props.viewModel}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <QuestionStepperView viewModel={props.viewModel.questionStepper} />
          <QuestionView viewModel={props.viewModel.currentQuestion} />
          <QuestionActions viewModel={props.viewModel} />
        </Box>
      </BasePage>
    );
  }

  return null;
});
