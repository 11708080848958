import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./firebase";
import { ROUTES } from "./constants";

export const ProtectedRoute = () => {
  const { authToken } = useAuth();
  let location = useLocation();
  if (authToken) {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} />;
};