import { Box } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  ActivityToolBar,
  IActivityAction,
} from "../../controls/activityToolbar";
import { AddCircle, ListAlt } from "@mui/icons-material";
import { IKeyedObject, PageMode } from "../../core/types";
import { Tenant } from "./tenant";
import { TenantList } from "./tenantList";
import { ITenantDto, TenantClient, TenantDto } from "../../utils/api";
import { Language, LanguageContext } from "../../core/language";

const activities: IActivityAction[] = [
  {
    actionKey: "tenant_activity_list",
    icon: <ListAlt />,
    toolTip: "View Tenants",
  },
  {
    actionKey: "tenant_activity_add",
    icon: <AddCircle />,
    toolTip: "Add Tenant",
  },
];

const pageEntries: IKeyedObject<string> = {
  tenant_activity_title: "Tenant Management",
  tenant_activity_add: "Add Tenant",
  tenant_activity_list: "View Tenants",
  tenant_list_title: "Tenants",
  tenant_edit_action: "Edit",
  tenant_list_action: "Actions",
  tenant_name_label: "Name",
  tenant_url_label: "Url",
  tenant_db_label: "DB Connection",
  tenant_save_action: "Save",
  tenant_cancel_action: "Cancel",
  tenant_name_error: "Name is required",
  tenant_url_error: "Url is required",
  tenant_db_error: "DB Connection us required",
  tenant_detail_title: "Tenant Details",
  tenant_user_label: "Tenant's User Details",
  tenant_fname_label: "First Name",
  tenant_lname_label: "Last Name",
  tenant_email_label: "Email",
  tenant_fname_error: "First Name is required",
  tenant_lname_error: "Last Name is required",
  tenant_email_error: "Email is required",
  tenant_landing_label: "Landing Page Details",
  tenant_logo_label: "Logo Url",
  tenant_image_label: "Image Url",
  tenant_title_label: "Landing Page Title",
  tenant_subtitle_label: "Landing Page Subtitle",
  tenant_logo_error: "Logo Url is required",
  tenant_image_error: "Image Url is required",
  tenant_title_error: "Landing Page Title is required",
  tenant_subtitle_error: "Landing Page Subtitle is required",
};
const newTenant: ITenantDto = {
  id: "",
  name: "",
  url: "",
  dbConnection: "",
};
export const TenantPage = () => {
  const [mode, setMode] = useState<PageMode>(PageMode.List);
  const [selectedTenant, setTenant] = useState<ITenantDto>(newTenant);
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const [entries, setEntries] = useState<IKeyedObject<string>>(pageEntries);
  const [tenants, setTenants] = useState<TenantDto[]>([]);
  const [pageActivities, setPageActivities] =
    useState<IActivityAction[]>(activities);

  useEffect(() => {
    const entries = translate(Language.EntryCategory.Tenant, pageEntries);
    setEntries(entries);
  }, [selectedLanguage, translate]);

  const client = useMemo(() => {
    return new TenantClient();
  }, []);

  useEffect(() => {
    const pgActivities = activities.map((activity) => {
      const translation = entries[activity.actionKey];
      return { ...activity, toolTip: translation };
    });

    setPageActivities(pgActivities);
  }, [entries]);

  const getClients = useCallback(() => {
    client.getTenants().then((result) => {
      if (JSON.stringify(result.value) !== JSON.stringify(tenants)) {
        setTenants(result.value ?? []);
      }
    });
  }, [client, tenants]);

  useEffect(() => {
    getClients();
  }, [client, getClients]);

  useEffect(() => {
    if (mode === PageMode.List) {
      setTenant(newTenant);
    } else if (mode === PageMode.Add) {
      setTenant(newTenant);
    }
  }, [mode]);

  const clearSelectedTenant = useCallback(() => {
    setTenant(newTenant);
  }, []);

  const onActivityCallback = (key: string) => {
    clearSelectedTenant();
    if (key === "tenant_activity_list") {
      setMode(PageMode.List);
    } else {
      setMode(PageMode.Add);
    }
  };

  const onEditCallback = (tenant: TenantDto) => {
    setTenant(tenant);
    setMode(PageMode.Edit);
  };

  const onEditComplete = (refreshRequired: boolean) => {
    clearSelectedTenant();
    if (refreshRequired) {
      getClients();
    }
    setMode(PageMode.List);
  };

  const onShowUsers = (tenant: TenantDto) => {
    setTenant(tenant);
    setMode(PageMode.Custom);
  };

  return (
    <Box>
      <ActivityToolBar
        callback={onActivityCallback}
        actions={pageActivities}
        title={entries["tenant_activity_title"]}
      />
      {mode === PageMode.List && (
        <TenantList
          onEdit={onEditCallback}
          onShowUsers={onShowUsers}
          entries={entries}
          tenants={tenants}
        />
      )}
      {mode === PageMode.Edit && (
        <Tenant
          tenant={selectedTenant}
          entries={entries}
          onEditComplete={onEditComplete}
          createMode={false}
          language={selectedLanguage}
        />
      )}
      {mode === PageMode.Add && (
        <Tenant
          tenant={selectedTenant}
          entries={entries}
          onEditComplete={onEditComplete}
          createMode={true}
          language={selectedLanguage}
        />
      )}
      {mode === PageMode.Custom && (
        <Tenant
          tenant={selectedTenant}
          entries={entries}
          onEditComplete={onEditComplete}
          createMode={true}
          language={selectedLanguage}
        />
      )}
    </Box>
  );
};
