import { MSMESelectorViewModel } from "./msmeSelectorViewModel";
import { observer } from "mobx-react";
import { ResponsiveTable } from "../../../controls/responsiveTable";
import { Box, Typography } from "@mui/material";
import { ActionButton } from "../../../controls/actionButton";

interface IMSMESelectorViewProps {
  viewModel: MSMESelectorViewModel;
}

export const MSMESelectorView = observer((props: IMSMESelectorViewProps) => {
  if (props.viewModel.displaySelector) {
    return (
      <ResponsiveTable
        header={props.viewModel.model?.listTitle}
        noDataOverlay={undefined}
        headers={props.viewModel.model?.headers}
        rowData={props.viewModel.model?.rowData}
        rowActionHandler={(id, key) => props.viewModel.handleMSMESelection(id)}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingBottom: "5px",
        marginBottom: "5px",
      }}
    >
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
        {props.viewModel.model?.selectedMsmeTitle} {props.viewModel.msmeName}
      </Typography>{" "}
      <div style={{ alignContent: "right" }}>
        <ActionButton
          action={props.viewModel.model?.filterAction}
          onClick={() => props.viewModel.showFilter()}
        />
      </div>
    </Box>
  );
});
