import { makeAutoObservable } from "mobx";

export interface IQuestionStepper {
  label: string;
  selected: boolean;
  showBadge: boolean;
  badgeColor: any;
}

export interface IQuestionStepperViewModel {
  steppers: IQuestionStepper[];
  setQuestions(stepperData: IQuestionStepperData[]): void;
  setCurrentQuestion(questionNumber: number): void;
  setCurrentQuestionState: (isAnswered: boolean, isFlagged: boolean) => void;
}
export interface IQuestionStepperData {
  number: number;
  isAnswered: boolean;
  isFlagged: boolean;
}

export class QuestionStepperViewModel implements IQuestionStepperViewModel {
  questionCount: number = 0;
  currentQuestion: number = 0;
  steppers: IQuestionStepper[] = [];

  setCurrentQuestionState(isAnswered: boolean, isFlagged: boolean) {
    const steppers = this.steppers.map((item, index) => {
      if (index === this.currentQuestion) {
        if (isFlagged) {
          item.showBadge = true;
          item.badgeColor = "error";
        } else if (isAnswered) {
          item.showBadge = true;
          item.badgeColor = "secondary";
        } else {
          item.showBadge = false;
        }
      }
      return item;
    });
    this.steppers = steppers;
  }

  setQuestions(stepperData: IQuestionStepperData[]) {
    this.questionCount = stepperData.length;
    const queSteppers: IQuestionStepper[] = [];
    for (let i = 0; i < this.questionCount; i++) {
      queSteppers.push({
        label: (i + 1).toString(),
        selected: i === this.currentQuestion,
        showBadge: stepperData[i].isAnswered || stepperData[i].isFlagged,
        badgeColor: stepperData[i].isFlagged ? "error" : "secondary",
      });
    }
    this.steppers = queSteppers;
  }

  setCurrentQuestion(questionNumber: number) {
    const steppers = this.steppers.map((item, index) => {
      item.selected = index === questionNumber;
      return item;
    });
    this.steppers = steppers;
    this.currentQuestion = questionNumber;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
