import React, { useCallback, useContext, useEffect, useState } from "react";
import { TodoClient, TodoDto, TodoItemDto, TodoSectionDto } from "../utils/api";
import { IHigherContextProviderProps } from "./firebase";
import { SignedInUserContext } from "./signedInUser";
import { LanguageContext } from "./language";

export interface ITodoContext {
  todos: TodoSectionDto[];
  todoLabel: string;
  assignClicked: (todoItem: TodoItemDto) => void;
  refreshTodo: () => void;
}

export const TodoContext = React.createContext<ITodoContext>({
  todos: [],
  todoLabel: "",
  assignClicked: (todoItem: TodoItemDto) => {},
  refreshTodo: () => {},
});

export const TodoContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const { signedInUser } = useContext(SignedInUserContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const [todo, setTodo] = useState<TodoDto>();

  const refresh = useCallback(() => {
    const client = new TodoClient();
    client.getUserTodo(selectedLanguage).then((result) => {
      if (result.succeeded) {
        setTodo(result.value);
      }
    });
  }, [selectedLanguage]);

  useEffect(() => {
    if (signedInUser && selectedLanguage) {
      refresh();
    }
  }, [signedInUser, selectedLanguage, refresh]);

  const assignClicked = (todoItem: TodoItemDto) => {
    const client = new TodoClient();
    client
      .assignToMe(selectedLanguage, todoItem.code)
      .then((result) => refresh());
  };

  return (
    <TodoContext.Provider
      value={{
        todos: todo?.sections ?? [],
        todoLabel: todo?.label ?? "To Do",
        assignClicked,
        refreshTodo: refresh,
      }}
    >
      {children}
    </TodoContext.Provider>
  );
};
