import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useAuth } from "../../core/firebase";
import { Language, LanguageContext } from "../../core/language";
import { IKeyedObject } from "../../core/types";

const pageEntries: IKeyedObject<string> = {
  forgot_pwd_title: "Forgot Password?",
  forgot_pwd_email_label: "Email Address",
  forgot_pwd_confirm: "Password reset email sent",
  forgot_pwd_button_label: "Send Password Reset Email",
};

export const ForgotPassword = () => {
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const [email, setEmail] = useState<string>("");
  const [entries, setEntries] = useState(pageEntries);
  const { sendPasswordResetEmail } = useAuth();
  const [confirm, setConfirm] = useState<boolean>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const languageEntries = translate(
      Language.EntryCategory.SignIn,
      pageEntries
    );
    setEntries(languageEntries);
  }, [selectedLanguage, translate]);

  const handleSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();

    const resetResult = await sendPasswordResetEmail(email);
    console.log("Reset REsult", resetResult);
    if (resetResult?.hasEmailError) {
      setEmailError(resetResult.error);
    } else if (resetResult?.hasSomeOtherError) {
      setError(resetResult.error);
    } else {
      setConfirm(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {entries["forgot_pwd_title"]}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={emailError ? true : false}
            helperText={emailError ?? ""}
            margin="normal"
            required
            fullWidth
            id="email"
            label={entries["forgot_pwd_email_label"]}
            name="email"
            autoComplete="email"
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {entries["forgot_pwd_button_label"]}
          </Button>
          {confirm && (
            <Typography
              component="div"
              variant="body2"
              sx={{ mt: 3, alignContent: "center" }}
            >
              {entries["forgot_pwd_confirm"]}
            </Typography>
          )}
          {error && (
            <Typography
              component="div"
              variant="body2"
              color="error"
              sx={{ mt: 3, alignContent: "center" }}
            >
              Error : {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};
