import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LanguageContext } from "../../core/language";
import { ResponsiveTable } from "../../controls/responsiveTable";
import { BasePage } from "../../controls/basePage";
import { BusinessListViewModel } from "./msmeListViewModel";
import { ICompanyType } from "./companyType";

export const BusinessList = observer((props: ICompanyType) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<BusinessListViewModel | undefined>(
    undefined
  );
  useEffect(() => {
    setViewModel(
      new BusinessListViewModel(selectedLanguage, props.companyType)
    );
  }, [selectedLanguage, props.companyType]);
  if (viewModel) {
    return <BusinessListView viewModel={viewModel} />;
  }
  return null;
});
interface IBusinessListViewProps {
  viewModel: BusinessListViewModel;
}

const BusinessListView = observer((props: IBusinessListViewProps) => {
  return (
    <BasePage pageDetails={props.viewModel}>
      <ResponsiveTable
        header={props.viewModel.model?.businesses.listTitle}
        noDataOverlay={props.viewModel.model?.businesses.noDataOverlay}
        headers={props.viewModel.model?.businesses.columnHeaders}
        rowData={props.viewModel.model?.businesses.rowData}
      />
    </BasePage>
  );
});
