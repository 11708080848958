import { createContext, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { IHigherContextProviderProps } from "./firebase";
import { useMediaQuery } from "@mui/material";


declare module '@mui/material/styles' {
  interface TypographyVariants {
    smallfont: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    smallfont?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallfont: true;
  }
}


interface ThemeContextValue {
  theme: any;
  changeTheme: (theme: string) => void;
  getAvailableThemes: () => string[];
  isDesktop: boolean;
}

//#E9EFFC
//#002763
const pastelGreen = {
  light: "#A3B18A",
  main: "#588157",
  dark: "#2D6A4F",
};
const secondaryColors = {
  pastelBlue: {
    light: "#A2CFFF",
    main: "#578CCC",
    dark: "#2D4F6A",
  },
  pastelPink: {
    light: "#FFA2C4",
    main: "#CC578C",
    dark: "#6A2D4F",
  },
};

const darkTheme = {
  palette: {
    mode: "dark",
    primary: { light: "#999999", main: "#373d43", dark: "#121212" },
  },
  custom: {
    toolbar: "#1976D2", // Example color value, change it to your desired color
  },
  typography: {
    smallfont: {
      fontSize: 8,
    },
  },
};

const lightTheme = {
  palette: {
    mode: "light",
    background: {
      default: "#b6dcfb", // Set a lighter shade of green as the background color
    },
  },
  custom: {
    toolbar: "#1976D2", // Example color value, change it to your desired color
  },
  typography: {
    smallfont: {
      fontSize: 8,
    },
  },
};
const customTheme = {
  palette: {
    primary: {
      light: "#E9EFFC", // pastelGreen.light,
      main: "#002763", //pastelGreen.main,
      dark: "#000204" // pastelGreen.dark,
    },
    secondary: {
      light: secondaryColors.pastelBlue.light,
      main: secondaryColors.pastelBlue.main,
      dark: secondaryColors.pastelBlue.dark,
    },
    info: {
      light: secondaryColors.pastelBlue.light,
      main: secondaryColors.pastelBlue.main,
      dark: secondaryColors.pastelBlue.dark,
    },
    background: {
      default: "#FFFFFF", 
    },
  },
  typography: {
    smallfont: {
      fontSize: 8,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          smallfont: 'body2',
        },
      },
    },
  },
};


export const ThemeContext = createContext<ThemeContextValue>({
  theme: darkTheme,
  isDesktop: true,

  changeTheme: (x) => {},
  getAvailableThemes: () => [],
});

export const ThemeContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const [currentTheme, setTheme] = useState<any>(customTheme);
  const isDesktop = useMediaQuery("(min-width: 960px)");

  const muiTheme = React.useMemo(() => {
    return createTheme(currentTheme);
  }, [currentTheme]);

  const changeTheme = (theme: string) => {
    if (theme === "Dark" && currentTheme.palette.mode !== "Dark") {
      setTheme(darkTheme);
    } else if (theme === "Light" && currentTheme.palette.mode !== "Light") {
      setTheme(lightTheme);
    } else if (theme === "Inclusive Trade" && currentTheme.palette.mode !== "Inclusive Trade") {
      setTheme({
        ...customTheme,
        overrides: {
          MuiPaper: {
            root: {
              backgroundColor: customTheme.palette.background.default,
            },
          },
          MuiBox: {
            root: {
              backgroundColor: customTheme.palette.background.default,
            },
          },
        },
      });
    }
  };

  const getAvailableThemes = () => {
    return ["Dark", "Light", "Inclusive Trade"];
  };
  return (
    <ThemeContext.Provider
      value={{
        theme: currentTheme,
        changeTheme,
        getAvailableThemes,
        isDesktop,
      }}
    >
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
