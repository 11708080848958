import { Box, Typography } from "@mui/material";
import { IPageTitleViewModel } from "../common/pageTitleViewModel";
import { ActionButton, FabActionButton } from "./actionButton";
import { useContext, useEffect, useState } from "react";
import { IUIAction, UIActionType } from "../utils/api";
import { FavoritesContext } from "../core/favoritesContext";

export const PageTitle = (viewModel: IPageTitleViewModel) => {
  const [favoriteAction, setFavoriteAction] = useState<IUIAction>();
  const { favorites, addFavorite, removeFavorite } =
    useContext(FavoritesContext);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  useEffect(() => {
    if (viewModel?.favoriteAction) {
      setFavoriteAction({
        code: viewModel.favoriteAction?.code ?? "",
        tooltip: viewModel.favoriteText ?? "",
        title: viewModel.favoriteText ?? "",
        icon: "star",
        url: "",
        enabled: viewModel.favoriteAction.enabled,
        visible: viewModel.favoriteAction.visible,
        showText: false,
        actionType: UIActionType.ButtonAction,
        requiresVariableSub:
          viewModel.favoriteAction?.requiresVariableSub ?? false,
      });
    }
  }, [viewModel]);

  const onClick = () => {
    if (isFavorite) {
      removeFavorite(viewModel.favoriteAction?.code ?? "");
    } else {
      addFavorite(
        viewModel.favoriteAction?.code ?? "",
        viewModel.favoriteVariableSub
      );
    }
  };

  useEffect(() => {
    const hasFavorite = favorites.favoriteActions.find(
      (x) => x.code === favoriteAction?.code
    );
    setIsFavorite(hasFavorite !== undefined);
  }, [favorites, favoriteAction]);

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow="1">
        <Typography variant="h5">
          {viewModel?.title} {"  "}
          {favoriteAction && (
            <ActionButton
              action={favoriteAction}
              onClick={onClick}
              overrideColor={isFavorite ? "primary" : undefined}
            />
          )}
        </Typography>
      </Box>
      <Box flexGrow="none">
        {viewModel?.pageActions?.map((action) => {
          return (
            <FabActionButton
              action={action}
              key={action.code}
            ></FabActionButton>
          );
        })}
      </Box>
    </Box>
  );
};
