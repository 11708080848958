import { observer } from "mobx-react";
import { Box, Typography, useTheme } from "@mui/material";
import { IQuestionViewProps } from "./questionView";

export const QuestionSDGView = observer((props: IQuestionViewProps) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="row"
      borderBottom="1px solid"
      borderColor={theme.palette.primary.dark}
      paddingBottom="5px"
      marginBottom="5px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        marginRight="10px"
        alignSelf="end"
        flexGrow="1"
      >
        <Typography variant="h6">
          {props.viewModel?.question.sdgName}
        </Typography>
        <Typography variant="body1">
          {props.viewModel?.question.section}
        </Typography>
      </Box>
      <Box
        marginTop="5px"
        component="img"
        height="75px"
        width="75px"
        src={props.viewModel?.question.sdgImage}
      ></Box>
    </Box>
  );
});
