import { observer } from "mobx-react-lite";
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EditOutlined, LockResetOutlined } from "@mui/icons-material";
import { IUserManagementViewProps } from "./userManagement";
import { ActivityActionButton } from "../../controls/activityToolbar";
import { AlertDialog, DialogResult, YesNoDialog } from "../../controls/dialogs";

export const UserListView = observer(
  ({ viewModel }: IUserManagementViewProps) => {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box width="100%">
          <Typography variant="h6" sx={{ mb: 2, mt: 5 }}>
            {viewModel.pageEntries["usermng_list_title"]}
          </Typography>
          <Paper sx={{ minHeight: "400px", padding: "5px" }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      {viewModel.pageEntries["usermng_fname_label"]}
                    </TableCell>
                    <TableCell component="th">
                      {viewModel.pageEntries["usermng_lname_label"]}
                    </TableCell>
                    <TableCell component="th">
                      {viewModel.pageEntries["usermng_email_label"]}
                    </TableCell>
                    <TableCell component="th" align="center">
                      {viewModel.pageEntries["usermng_actions_label"]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewModel.users.map((user) => {
                    return (
                      <TableRow key={user.id}>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell align="center">
                          {viewModel.editAllowed && (
                            <ActivityActionButton
                              toolTip={
                                viewModel.pageEntries["usermng_edit_action"]
                              }
                              actionKey="edit"
                              callback={() => viewModel.editUser(user.id)}
                              icon={<EditOutlined />}
                            />
                          )}
                          <ActivityActionButton
                            toolTip={
                              viewModel.pageEntries[
                                "usermng_passwordreset_action"
                              ]
                            }
                            actionKey="passwordreset"
                            callback={() => viewModel.resetPassword(user.email)}
                            icon={<LockResetOutlined />}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <YesNoDialog
            isOpen={viewModel.resetDialogOpen}
            isOkAllowed={true}
            handleClose={(result) =>
              viewModel.handlePasswordResetConfirmation(
                result === DialogResult.Yes
              )
            }
            title={viewModel.pageEntries["usermng_passwordreset_title"]}
            description={`${viewModel.pageEntries["usermng_pwdresetconfirmation_message"]} ${viewModel.passwordResetEmail}`}
          />
          <AlertDialog
            isOpen={viewModel.hasMessage}
            title={viewModel.dialogTitle}
            description={viewModel.dialogMessage}
            handleClose={viewModel.handleDialogClose}
            isOkAllowed={true}
          />
        </Box>
      </Container>
    );
  }
);
