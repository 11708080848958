import {
  LanguageClient,
  LanguageEntriesDto,
  LanguageEntryDto,
  ResultOfLanguageEntriesDto,
} from "../utils/api";
import { atom } from "@hungry-egg/rx-state";
import { distinctUntilChanged, filter } from "rxjs/operators";

export interface ILanguageCache {
  [languageId: string]: LanguageEntriesDto | undefined;
}

export class LanguageService {
  public language$ = atom("");
  private languageCache: ILanguageCache = {};
  private setLanguageCalls$ = atom("");
  private client = new LanguageClient();

  constructor() {
    this.setLanguageCalls$
      .pipe(
        distinctUntilChanged(),
        filter((value) => value !== "")
      )
      .subscribe((value) => this.handleSetLanguage(value));
  }

  private async handleSetLanguage(language: string) {
    if (language !== "") {
      if (!this.languageCache[language]) {
        await this.fetchLanguageEntries(language).then((result) => {
          this.setLanguageCache(result, language);
        });
      }
      this.language$.set(language);
    }
  }

  public setLanguage(language: string) {
    this.setLanguageCalls$.set(language);
  }

  public getCategory = (category: any): { [key: string]: LanguageEntryDto } => {
    const language = this.language$.get();
    console.log("Current lamguage cache", this.languageCache);
    return this.languageCache[language]?.categories[category] ?? {};
  };

  private setLanguageCache = (
    cacheResult: ResultOfLanguageEntriesDto,
    language: string
  ) => {
    if (cacheResult.succeeded) {
      this.languageCache[language] = cacheResult.value;
    }
  };

  private fetchLanguageEntries = (
    language: string
  ): Promise<ResultOfLanguageEntriesDto> => {
    return this.client.getEntries(language);
  };
}
const languageService: LanguageService = new LanguageService();

export default languageService;
