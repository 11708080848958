import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LanguageContext } from "../../core/language";
import { ResponsiveTable } from "../../controls/responsiveTable";
import { BasePage } from "../../controls/basePage";
import { DashboardListViewModel } from "./dashboardViewModel";
import { Button } from "@mui/material";
export const DashboardList = observer(() => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<
    DashboardListViewModel | undefined
  >(undefined);
  useEffect(() => {
    setViewModel(new DashboardListViewModel(selectedLanguage));
  }, [selectedLanguage]);
  if (viewModel) {
    return <DashboardListView viewModel={viewModel} />;
  }

  return null;
});
interface IDashboardListViewProps {
  viewModel: DashboardListViewModel;
}

const DashboardListView = observer((props: IDashboardListViewProps) => {
  const handleTestLink = () => {
    console.log("Test Button Clicked");
    props.viewModel.testPaymentLink();
  };
  return (
    <BasePage pageDetails={props.viewModel}>
      <ResponsiveTable
        showSearch={false}
        header={props.viewModel.model?.screenTitle}
        noDataOverlay={props.viewModel.model?.widgets.noDataOverlay}
        headers={props.viewModel.model?.widgets.columnHeaders}
        rowData={props.viewModel.model?.widgets.rowData}
        barCharts={props.viewModel.model?.widgets.barCharts}
        pieCharts={props.viewModel.model?.widgets.pieCharts}
        barCharts2={props.viewModel.model?.widgets.barCharts2}
        isSingleItemList={true}
      />
      <Button variant="outlined" onClick={handleTestLink}>
        Test Payment Link
      </Button>
    </BasePage>
  );
});
