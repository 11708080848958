import { Box, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import { IHigherContextProviderProps } from "../core/firebase";

const BorderBox: React.FC<IHigherContextProviderProps> = ({ children }) => {
  const theme = useTheme();
  //border: "2px solid rgb(165 151 151 / 23%)",
  return (
    <Box
      sx={{
        borderRadius: "4px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingTop: "6px",
        paddingBottom: "6px",
        verticalAlign: "top",
        width: "100%",
        marginTop: "16px",
        marginBottom: "8px",
        border: "1px solid",
        borderColor: theme.palette.primary.light,
      }}
    >
      {children}
    </Box>
  );
};

export const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
/*   border: "2px solid rgb(165 151 151 / 23%)",*/
  border: "1px solid",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.text.disabled,
}));

export const Label = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-17px",
  left: "5px",
  padding: "8px",
  fontSize: "12px",
  color: "rgb(165 151 151)",
  backgroundColor: "transparent",
}));

export default BorderBox;
