import { observer } from "mobx-react";
import { QuestionStepperViewModel } from "./QuestionStepperViewModel";
import { useTheme } from "@mui/material/styles";
import { Badge, Box, Chip } from "@mui/material";

export interface IQuestionStepperViewProps {
  viewModel: QuestionStepperViewModel;
}
export const QuestionStepperView = observer(
  (props: IQuestionStepperViewProps) => {
    const theme = useTheme();
    if (props.viewModel) {
      const last = props.viewModel.steppers.length - 1;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "10px",
            paddingLeft: "10px",
            flexWrap: "wrap",
          }}
        >
          {props.viewModel.steppers.map((item, index) => (
            <Box
              key={"qno" + index}
              display="flex"
              flexDirection="row"
              //flexGrow={index !== last ? "1" : "0"}
              alignItems="center"
            >
              {item.showBadge && (
                <Badge
                  badgeContent={" "}
                  color={item.badgeColor}
                  variant="dot"
                  overlap="circular"
                >
                  <Chip
                    label={item.label}
                    variant={item.selected ? "filled" : "outlined"}
                    color="primary"
                  />
                </Badge>
              )}
              {!item.showBadge && (
                <Chip
                  label={item.label}
                  variant={item.selected ? "filled" : "outlined"}
                  color="primary"
                />
              )}
              {index !== last && (
                <div
                  style={{
                    width: "10px",
                    height: "1px",
                    backgroundColor: theme.palette.primary.dark,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  }
);
