import { makeAutoObservable, runInAction } from "mobx";
import {
  CertificationClient,
  MSMEListDto,
  ResultOfMSMEListDto,
} from "../../../utils/api";

export interface IMSMESelectorViewModel {
  model: MSMEListDto | undefined;
  error: string | undefined;
  handleMSMESelection: (id: string) => void;
  clearMSMESelection: () => void;
  displaySelector: boolean;
  msmeName: string | undefined;
}

export class MSMESelectorViewModel implements IMSMESelectorViewModel {
  model: MSMEListDto | undefined;
  error: string | undefined;
  displaySelector: boolean = false;
  msmeName: string | undefined = undefined;
  constructor(
    private selectedLanguage: string,
    private onMsmeSelectionChanged: (selectedMsme: string) => void
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  setDisplaySelector = (display: boolean) => {
    this.displaySelector = display;
  };

  showFilter = () => {
    this.setMsme(undefined);
    this.setDisplaySelector(true);
  };

  hideFilter = () => {
    this.setDisplaySelector(false);
  };

  clearMSMESelection = () => {
    this.setDisplaySelector(true);
  };

  handleMSMESelection = (id: string) => {
    this.setMsme(id);
  };

  setMsme = (msme: string | undefined) => {
    if (msme) {
      const row = this.model?.rowData.filter((x) => x.id === msme);
      if (row) {
        this.msmeName = row[0].cellData[0].value;
      }
      this.setDisplaySelector(false);
    }
    if (msme) {
      this.onMsmeSelectionChanged(msme);
    }
  };

  setMsmeList = (result: ResultOfMSMEListDto) => {
    if (result.succeeded) {
      this.model = result.value;
      if (this.model?.rowData.length === 1) {
        this.setMsme(this.model.rowData[0].id);
      } else {
        this.setDisplaySelector(true);
      }
    } else {
      this.setError("Could not fetch MSME");
    }
  };

  setError = (error: string) => {};

  fetchData = () => {
    const client = new CertificationClient();
    client
      .getMsmeList(this.selectedLanguage)
      .then((result) => this.setMsmeList(result))
      .catch((reason) => this.setError("Could not fetch MSME"));
  };
}
