import React, { useContext, useEffect, useState } from "react";
import { SignedInUserDto, UserClient } from "../utils/api";
import { FirebaseContext, IHigherContextProviderProps } from "./firebase";
import { ActivityContext } from "./activityContext";

export interface ISignedInUserContext {
  signedInUser: SignedInUserDto | undefined;
}
export const SignedInUserContext = React.createContext<ISignedInUserContext>({
  signedInUser: undefined,
});

export const SignedInUserContextProvider: React.FC<
  IHigherContextProviderProps
> = ({ children }) => {
  const { authToken, getSignedInUser } = useContext(FirebaseContext);
  const [signedInUser, setSignedInUser] = useState<SignedInUserDto | undefined>(
    undefined
  );
  const { registerActivities, deRegisterActivities } =
    useContext(ActivityContext);

  useEffect(() => {
    if (authToken) {
      const user = getSignedInUser();
      if (user) {
        const client = new UserClient();
        client
          .signin(user)
          .then((result) => {
            setSignedInUser(result.value);
          })
          .catch((reason) => setSignedInUser(undefined));
      }
    } else {
      setSignedInUser(undefined);
    }
  }, [authToken, getSignedInUser]);

  useEffect(() => {}, [signedInUser, registerActivities, deRegisterActivities]);
  return (
    <SignedInUserContext.Provider value={{ signedInUser }}>
      {children}
    </SignedInUserContext.Provider>
  );
};
