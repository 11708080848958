import {
  BarChart2Dto,
  BarChartDto,
  BarChartSeriesDataDto,
  CellData,
  CellValueType,
  PieChartDto,
  RowData,
  UIAction,
} from "../utils/api";
import {
  Box,
  FormLabel,
  Grid,
  Icon,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import { ActionButton } from "./actionButton";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  BarChart,
  BarChartProps,
  BarSeriesType,
  PieChart,
  axisClasses,
} from "@mui/x-charts";

export interface IResponsiveTableProps {
  header: string | undefined;
  noDataOverlay: string | undefined;
  headers: CellData[] | undefined;
  rowData: RowData[] | undefined;
  barCharts?: BarChartDto[] | undefined;
  pieCharts?: PieChartDto[] | undefined;
  barCharts2?: BarChart2Dto[] | undefined;
  isSingleItemList?: boolean | undefined;
  showSearch?: boolean | undefined;

  rowActionHandler?: (id: string, key: string) => void;
  customCellHandler?: (
    cellData: CellData,
    columnIndex: number,
    rowIndex: number
  ) => JSX.Element;
}
export const ResponsiveTable = (props: IResponsiveTableProps) => {
  const theme = useTheme();
  const [filter, setFilter] = useState<string>("");
  const [filteredRows, setFilteredRows] = useState<RowData[] | undefined>(
    props.rowData
  );
  const [showSearch] = useState<boolean>(props?.showSearch ?? true);
  const charts = props.barCharts;
  const piecharts = props.pieCharts;
  const barcharts2 = props.barCharts2;

  useEffect(() => {
    if (filter && filter.length > 0) {
      const lowerFilter = filter.toLowerCase();
      const filterData = props.rowData?.filter((row) => {
        const anyData = row.cellData.filter((x) =>
          x.value.toLowerCase().includes(lowerFilter)
        );
        if (anyData.length > 0) return true;
        return false;
      });

      setFilteredRows(filterData);
    } else {
      setFilteredRows(props.rowData);
    }
  }, [filter, props.rowData]);

  const getCellImage = (cellData: CellData) => {
    return (
      <Box
        component="img"
        sx={{
          maxWidth: "100px",
          maxHeight: "100px",
          padding: "5px",
        }}
        src={cellData.value}
      />
    );
  };
  const getCellIcon = (cellData: CellData) => {
    return (
      <Icon
        className="material-symbols-outlined"
        fontSize="medium"
        sx={{ marginLeft: "10px" }}
      >
        {cellData.value}
      </Icon>
    );
  };
  const getCellLink = (cellData: CellData) => {
    return (
      <Link
        color="inherit"
        component={RouterLink}
        to={cellData.linkPath}
        marginLeft={1}
        marginTop={1}
        underline="none"
      >
        <Typography variant="body2">{cellData.value}</Typography>
      </Link>
    );
  };
  /*   const getCellWidget = (cellData: CellData, rowIndex: number) => {
    if (cellData.cellValue != null) {
      const cellList = cellData.cellValue;
      const outerBox = (
        <div>
          {cellData.value != "" && (
            <Typography
            variant = "h6"
              sx={{
                textDecoration: "underline",
              }}
              align="center"
            >
              {cellData.value}
            </Typography>
          )
          <Grid
            sx={{ 
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.light,
              borderRadius: "5px",
            }}
            key={`row-${rowIndex}`}
          >
            {Object.keys(cellList).map((key, index) => {
              const listData = cellList[key];
              // if (listData.showLabel) {
              //   <Label>{listData.label}</Label>;
              // }
              return (
                <ListItem
                  key={`LI-${rowIndex}${index}`}
                  sx={{ alignContent: "center" }}
                >
                  {listData.showLabel && (
                    <FormLabel>{listData.label}:</FormLabel>
                  )}
                  <ListItemText
                    key={`W-${rowIndex}${index}`}
                    primary={listData.value}
                    primaryTypographyProps={{
                      fontSize: "x-large",
                    }}
                  ></ListItemText>
                </ListItem>
              );
            })}
          </Grid>
        </div>
      );
      return outerBox;
    }

    return null;
  }; */

  function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          p: 1,
          m: 1,
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
          ...sx,
        }}
        {...other}
      />
    );
  }
  const getCellWidget = (cellData: CellData, rowIndex: number) => {
    if (cellData.cellValue != null) {
      const cellList = cellData.cellValue;
      const outerBox = (
        <div>
          <div
            style={{
              backgroundColor: theme.palette.primary.light,
/*               borderTopLeftRadius: 15,
              borderTopRightRadius: 15, */
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textDecoration: "underline",
              }}
              align="center"
            >
              {cellData.value}
            </Typography>
          </div>
          {/* <Grid container
            sx={{ 
              display: "flex",
              //flexDirection: "column",
              // justifyContent: "center",
              // backgroundColor: theme.palette.primary.light,
              // borderRadius: "5px",
            }}
          > */}
          {Object.keys(cellList).map((key, index) => {
            const listData = cellList[key];
            return (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 1,
                  borderBottom: "1px solid",
                  borderColor: theme.palette.primary.light,
                }}
              >
                {/* if label needs to be displayed */}
                {listData.showLabel && (
                  <Box
                    sx={{
                      p: 1,
                      m: 1,
                      borderRight: 1,
                      gridColumn: "span 2",
                      borderColor: theme.palette.primary.light,
                    }}
                  >
                    <Typography variant="body1"> {listData.label}:</Typography>
                  </Box>
                )}

                {/* display value in 1 col if label is present */}
                {listData.showLabel && (
                  <Box
                    sx={{
                      p: 1,
                      m: 1,
                      justifySelf: "center",
                    }}
                  >
                    <Typography variant="body1"> {listData.value}</Typography>
                  </Box>
                )}
                {/*display value in 3 cols if labe is not present */}
                {!listData.showLabel && (
                  <Box
                    sx={{
                      p: 1,
                      m: 1,
                      justifySelf: "center",
                      gridColumn: "span 3",
                    }}
                  >
                    <Typography variant="body1"> {listData.value}</Typography>
                  </Box>
                )}
              </Box>
            );
          })}
          {/* </Grid> */}
        </div>
      );
      return outerBox;
    }

    return null;
  };
  const getRowActions = (actions: UIAction[], id: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: theme.palette.primary.light,
        }}
      >
        {actions.map((action) => {
          return (
            <span key={action.code}>
              <ActionButton
                key={action.code}
                action={action}
                onClick={() => props.rowActionHandler?.(id, action.code)}
              />
            </span>
          );
        })}
      </Box>
    );
  };
  const getCellRender = (
    cellData: CellData,
    actions: UIAction[],
    id: string,
    columnIndex: number,
    rowIndex: number
  ) => {
    switch (cellData.valueType) {
      case CellValueType.Text:
        return <Typography variant="body2">{cellData.value}</Typography>;
      case CellValueType.Image:
        return getCellImage(cellData);
      case CellValueType.RowAction:
        return getRowActions(actions, id);
      case CellValueType.Icon:
        return getCellIcon(cellData);
      case CellValueType.Link:
        return getCellLink(cellData);
      case CellValueType.Widget:
        return getCellWidget(cellData, rowIndex);
      case CellValueType.Custom:
        if (props.customCellHandler) {
          return props.customCellHandler(cellData, columnIndex, rowIndex);
        } else {
          return null;
        }
    }
  };

  const getNoDataOverlay = () => {
    return (
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ minHeight: "200px" }}
      >
        <Grid item>{props.noDataOverlay}</Grid>
      </Grid>
    );
  };
  const getColHeader = (columnIndex: number, cellData: CellData) => {
    if (cellData.valueType === CellValueType.Text) {
      return (
        <Typography variant="body2" fontWeight="bold">
          {props.headers?.at(columnIndex)?.value + ": "}
        </Typography>
      );
    } else {
      return "";
    }
  };

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };

  const getDataCard = () => {
    if (props.rowData?.length === 0) {
      return getNoDataOverlay();
    }

    const rows = (
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row" }}
        spacing={1}
      >
        {filteredRows?.map((rowData, rowIndex) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={props?.isSingleItemList ? 6 : 4}
              //lg={4}
              alignItems="center"
              key={`row-${rowIndex}`}
            >
              <Paper
                sx={{
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                elevation={2}
              >
                {rowData.cellData?.map((cellData, colIndex) => {
                  return (
                    <Box
                      key={`cell-${colIndex}`}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        flex:
                          cellData.valueType === CellValueType.RowAction
                            ? "1"
                            : "0",
                        p: 1,
                      }}
                    >
                      {getColHeader(colIndex, cellData)}

                      {getCellRender(
                        cellData,
                        rowData.rowActions,
                        rowData.id,
                        colIndex,
                        rowIndex
                      )}
                    </Box>
                  );
                })}
              </Paper>
            </Grid>
          );
        })}
        {charts?.map((barChart: BarChartDto, chartIndex) => {
          const seriesData: number[] | undefined =
            barChart.series[0].seriesdata[0].data;
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={props?.isSingleItemList ? 6 : 4}
              //lg={4}
              alignItems="center"
              key={`chart-row-${chartIndex}`}
            >
              <Paper
                key={`barchart-p-${chartIndex}`}
                sx={{
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding:"8px"
                }}
                elevation={2}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.primary.light,
/*                     borderTopLeftRadius: 15,
                    borderTopRightRadius: 15, */
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                    }}
                    align="center"
                  >
                    {barChart.title}
                  </Typography>
                </div>
                <BarChart
                  key={`barchart-${chartIndex}`}
                  xAxis={[
                    {
                      id: barChart.xAxis[0].id,
                      data: barChart.xAxis[0].data,
                      scaleType: "band",
                      label: barChart.xAxis[0].label,
                    },
                  ]}
                  yAxis={[{ label: barChart.yLabel }]}
                  series={[{ data: seriesData }]}
                  sx={{ width: "100%" }}
                  height={barChart.height}
                ></BarChart>
              </Paper>
            </Grid>
          );
        })}

        {piecharts?.map((pieChart: PieChartDto, chartIndex) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={props?.isSingleItemList ? 6 : 4}
              //lg={4}
              alignItems="center"
              key={`pchart-row-${chartIndex}`}
            >
              <Paper
                key={`piechart-p-${chartIndex}`}
                sx={{
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding:"8px"
                }}
                elevation={2}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.primary.light,
/*                     borderTopLeftRadius: 15,
                    borderTopRightRadius: 15, */
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                    }}
                    align="center"
                  >
                    {pieChart.title}
                  </Typography>
                </div>
                <PieChart
                  key={`piechart-${chartIndex}`}
                  series={[
                    {
                      data: pieChart.series,
                      innerRadius: 80,
                      outerRadius: 120,
                    },
                  ]}
                  margin={{ top: 0, bottom: 0, left: 10, right: 10 }}
                  slotProps={{
                    legend: {
                      direction: "row",
                      labelStyle: { fontSize: 12, padding: 0, margin: 5 },
                      position: { vertical: "bottom", horizontal: "middle" },
                    },
                  }}
                  sx={{ width: "100%" }}
                  height={pieChart.height}
                ></PieChart>
              </Paper>
            </Grid>
          );
        })}
        {barcharts2?.map((barchart2: BarChart2Dto, chartIndex) => {
          let series2Data: BarSeriesType[] = [];
          barchart2.series[0].seriesdata.map((b: BarChartSeriesDataDto, i) => {
            series2Data?.push({
              type: "bar",
              dataKey: b.datakey,
              label: b.label,
            });
          });
          const dataSet: any[] | undefined = barchart2.dataset;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={props?.isSingleItemList ? 6 : 4}
              //lg={4}
              alignItems="center"
              key={`bchart2-row-${chartIndex}`}
            >
              <Paper
                key={`barchart2-p-${chartIndex}`}
                sx={{
                  backgroundColor: "transparent",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding:"8px"
                }}
                elevation={2}
              >
                <div
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    /* borderTopLeftRadius: 15,
                    borderTopRightRadius: 15, */
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textDecoration: "underline",
                    }}
                    align="center"
                  >
                    {barchart2.title}
                  </Typography>
                </div>
                <BarChart
                  key={`barchart-${chartIndex}`}
                  xAxis={[
                    {
                      id: barchart2.xAxis[0].id,
                      scaleType: "band",
                      dataKey: "month",
                    },
                  ]}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "bottom", horizontal: "middle" },
                      labelStyle: { fontSize: 12, padding: 0, margin: 0 },
                      padding: 0,
                      markGap: 5,
                    },
                  }}
                  series={series2Data}
                  height={barchart2.height}
                  dataset={dataSet}
                  yAxis={[{ label: barchart2.yLabel }]}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: "translate(-10px,0)",
                    },
                  }}
                ></BarChart>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
    return rows;
  };
  return (
    <Paper
      sx={{
        minHeight: "400px",
        p: 1,
        backgroundColor: "transparent",
      }}
      elevation={0}
    >
      {showSearch && (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "4px",
            alignItems: "center",
            padding: "4px",
            backgroundColor: "transparent",
            marginBottom: "8px",
          }}
          elevation={0}
        >
          <Icon sx={{ flexGrow: "none", ml: "4px" }}>search</Icon>
          <TextField
            type="search"
            fullWidth
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
            sx={{
              ml: "5px",
              mr: "5px",
              flexGrow: "1",
            }}
            size="small"
          />
        </Paper>
      )}

      {getDataCard()}
    </Paper>
  );
};
