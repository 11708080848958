import { useNavigate } from "react-router-dom";
import { IUIAction, UIActionType } from "../utils/api";
import { Button, Fab, Icon, IconButton, Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { buttonWidth } from "../core/constants";

export interface IActionButtonProps {
  action: IUIAction | undefined;
  overrideColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  onClick?: () => void;
}

const NavigateActionButton = (props: IActionButtonProps) => {
  const navigate = useNavigate();

  if (props.action) {
    const onClick = () => {
      if (props.action?.url) {
        navigate(props.action?.url);
      }
    };

    if (props.action.showText) {
      return <TextActionButton action={props.action} onClick={onClick} />;
    }

    return <IconActionButton action={props.action} onClick={onClick} />;
  }

  return null;
};

const TextActionButton = (props: IActionButtonProps) => {
  const isDesktop = useMediaQuery("(min-width:600px)");
  return (
    <>
      {props.action && props.action.visible && (
        <Tooltip title={props.action.tooltip}>
          <Button
            variant="contained"
            color={props.overrideColor}
            onClick={props.onClick}
            disabled={!props.action.enabled}
            startIcon={<Icon>{props.action.icon}</Icon>}
            sx={{ width:`calc(${buttonWidth}px)` }}
          >
            {/* {isDesktop ? props.action.title : ""} */}
            {props.action.title}
          </Button>
        </Tooltip>
      )}
    </>
  );
};
export const OutlinedTextActionButton = (props: IActionButtonProps) => {
  return (
    <>
      {props.action && props.action.visible && (
        <Tooltip title={props.action.tooltip}>
          <Button
            variant="outlined"
            onClick={props.onClick}
            disabled={!props.action.enabled}
            startIcon={
              <Icon color={props.overrideColor}>{props.action.icon}</Icon>
            }
          >
            {props.action.title}
          </Button>
        </Tooltip>
      )}
    </>
  );
};
const IconActionButton = (props: IActionButtonProps) => {
  return (
    <>
      {props.action && props.action.visible && (
        <Tooltip title={props.action.tooltip}>
          <span>
            <IconButton
              onClick={props.onClick}
              disabled={!props.action.enabled}
            >
              <Icon color={props.overrideColor}>{props.action.icon}</Icon>
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export const ActionButton = (props: IActionButtonProps | undefined) => {
  if (props) {
    if (props.action?.actionType === UIActionType.UrlClick) {
      return <NavigateActionButton {...props} />;
    }
    if (props.action?.showText) {
      return <TextActionButton {...props} />;
    }

    return <IconActionButton {...props} />;
  }
  return null;
};

export const FabActionButton = (props: IActionButtonProps) => {
  const navigate = useNavigate();

  if (props.action) {
    const onClick = () => {
      if (props.action?.url) {
        navigate(props.action?.url);
      }
    };
    return (
      <Tooltip title={props.action.tooltip}>
        <Fab
          size="small"
          color="primary"
          onClick={onClick}
          sx={{ marginLeft: "4px" }}
        >
          <Icon>{props.action.icon}</Icon>
        </Fab>
      </Tooltip>
    );
  }

  return null;
};
