import { observer } from "mobx-react";
import { MSMEGoalsViewModel } from "./msmeGoalsViewModel";
import {
  AppBar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CellData,
  GoalDto,
  MSMECertificationDetailDto,
  MSMECertificationStatus,
  Verification,
} from "../../../utils/api";
import { ActionButton } from "../../../controls/actionButton";
import { ResponsiveTable } from "../../../controls/responsiveTable";
import BatteryGauge from "react-battery-gauge";
import { useState } from "react";
import { mobileWidth } from "../../../core/constants";

interface IMSMEGoalsProps {
  viewModel: MSMEGoalsViewModel;
}

interface IMSMECertificateProps {
  goal: GoalDto | undefined;
}

interface IVerificationProps {
  verification: Verification;
}

interface IMSMEGoalStatusProps {
  certificate: MSMECertificationDetailDto | undefined;
}
export const MSMEGoals = observer((props: IMSMEGoalsProps) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState<number>(0);
  const isNotMobile = useMediaQuery("(min-width:" + mobileWidth + "px)");
  if (props.viewModel.isVisible && props.viewModel.model) {
    const tab0Underline = tabValue === 0 ? "5px solid white" : "0px";
    const tab1Underline = tabValue === 1 ? "5px solid white" : "0px";
    return (
      <Box>
        <Box sx={{ borderBottom: 2, borderColor: theme.palette.primary.main }}>
          <Tabs
            value={tabValue}
            onChange={(e, index: number) => setTabValue(index)}
            textColor="inherit"
            variant="fullWidth"
            TabIndicatorProps={{
              sx: { display: "none" },
            }}
            sx={{
              "& button": { borderRadius: 1 },
              "& button.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <Tab label={props.viewModel.model.goalLabel} />

            <Tab
              label={props.viewModel.model.historyLabel}
              disabled={props.viewModel.model.history.length === 0}
            />
          </Tabs>
        </Box>
        <div role="tabpanel" hidden={tabValue !== 0}>
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
            spacing={1}
          >
            <Grid item xs={12} sm={6} md={8} alignItems="center">
              <CertificateView goal={props.viewModel.model?.inProgress?.goal} />
              <CertificateStatus
                goal={props.viewModel.model.inProgress?.goal}
              />
              {isNotMobile && (
                <CertficateSDGStatus
                  certificate={props.viewModel.model.inProgress}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} alignItems="center">
              <CertificateVerficiatonsView
                goal={props.viewModel.model.inProgress?.goal}
              />
            </Grid>

            {!isNotMobile && (
              <Grid item xs={12}>
                <CertficateSDGStatus
                  certificate={props.viewModel.model.inProgress}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {/*           <CertficateSDGStatus certificate={props.viewModel.model.inProgress} /> */}

              <CertificateSelectorView viewModel={props.viewModel} />
            </Grid>
          </Grid>
        </div>
        <div role="tabpanel" hidden={tabValue !== 1}>
          <CertificateHistoryView viewModel={props.viewModel} />
        </div>
      </Box>
    );
  }
  return null;
});
export const CertificateVerficiatonsView = observer(
  (props: IMSMECertificateProps) => {
    return (
      <Box sx={{ maxHeight: "300px" }} overflow={"auto"}>
        {props.goal?.verifications.map((verification) => {
          return <VerificationView verification={verification} />;
        })}
      </Box>
    );
  }
);

export const VerificationView = observer((props: IVerificationProps) => {
  return (
    <Paper
      elevation={4}
      sx={{
        background: "transparent",
        padding: "8px",
        marginRight: "10px",
        marginBottom: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "4px",
        }}
        color={props.verification.accepted ? "green" : "red"}
      >
        <Icon sx={{ marginRight: "4px" }}>{props.verification.icon}</Icon>
        {props.verification.verificationLabel}
      </Box>
      <Rating
        value={props.verification.stars}
        precision={0.5}
        readOnly={true}
      />
      <Typography variant="body1">{props.verification.rating}</Typography>
      <Typography variant="body2">{props.verification.comment}</Typography>
      <Typography variant="body2">
        {props.verification.verifiedOn.toDateString()}
      </Typography>
    </Paper>
  );
});

export const CertificateHistoryView = observer((props: IMSMEGoalsProps) => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery("(min-width:" + mobileWidth + "px)");
  return (
    <Box sx={{ marginTop: "10px" }}>
      {props.viewModel.model?.history.map((x) => {
        return (
          <Paper
            key={x.goal?.goalName}
            elevation={0}
            sx={{
              background: "transparent",
              padding: "8px",
              borderColor: theme.palette.primary.light,
              borderBottom: 1,
            }}
          >
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row" }}
              spacing={1}
            >
              <Grid item xs={12} sm={6} md={8} alignItems="center">
                <CertificateView goal={x.goal} />
                <CertificateStatus goal={x.goal} />
                {isNotMobile && <CertficateSDGStatus certificate={x} />}
              </Grid>
              <Grid item xs={12} sm={6} md={4} alignItems="center">
                <CertificateVerficiatonsView goal={x.goal} />
              </Grid>
              {!isNotMobile && (
                <Grid item xs={12}>
                  <CertficateSDGStatus certificate={x} />
                </Grid>
              )}
            </Grid>
          </Paper>
        );
      })}
    </Box>
  );
});

const CertificateSelectorView = observer((props: IMSMEGoalsProps) => {
  const handleSDGCell = (
    cellData: CellData,
    colIndex: number,
    rowIndex: number
  ) => {
    const sdgQuestions =
      props.viewModel.model?.availableBadges?.customData[rowIndex];
    if (sdgQuestions) {
      return (
        <Box>
          {sdgQuestions.map((sdg) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    maxWidth: "50px",
                    maxHeight: "50px",
                    marginRight: "5px",
                  }}
                  src={sdg.sdgImage}
                />
                <Box>
                  <Typography variant="body1">{sdg.sdgName}</Typography>
                  <Typography variant="body2">
                    {sdg.questionsInBadgeLabel}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <></>;
  };

  if (props.viewModel.model?.hasGoal) {
    return null;
  }

  return (
    <ResponsiveTable
      header={props.viewModel.model?.availableBadges?.listTitle}
      noDataOverlay={undefined}
      headers={props.viewModel.model?.availableBadges?.headers}
      rowData={props.viewModel.model?.availableBadges?.rowData}
      rowActionHandler={(id, key) => props.viewModel.handleGoalSelection(id)}
      customCellHandler={handleSDGCell}
    />
  );
});

export const CertificateStatus = observer((props: IMSMECertificateProps) => {
  if (props.goal) {
    const percentage = props.goal?.percentageComplete ?? 0;
    const color =
      percentage < 30 ? "error" : percentage < 70 ? "secondary" : "success";
    return (
      <Box>
        <Typography variant="h6">
          {props.goal?.certificationStatusLabel} {" : "}{" "}
          {props.goal?.certificationStatusText}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "10%", mr: 1, ml: "5px" }}>
            <LinearProgress
              variant="determinate"
              color={color}
              sx={{ height: "20px" }}
              value={props.goal?.percentageComplete}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {`${props.goal?.percentageComplete}%`}
            </Typography>
          </Box>
          <Box>
            {props.goal?.certificationStatus ===
              MSMECertificationStatus.NotStarted && (
              <ActionButton action={props.goal?.startAction} />
            )}
            {props.goal?.certificationStatus ===
              MSMECertificationStatus.InProgress && (
              <ActionButton action={props.goal?.resumeAction} />
            )}
            {(props.goal?.certificationStatus ===
              MSMECertificationStatus.VerificationPending ||
              props.goal?.certificationStatus ===
                MSMECertificationStatus.Complete) && (
              <ActionButton action={props.goal?.resumeAction} />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
  return null;
});

const CertficateSDGStatus = observer((props: IMSMEGoalStatusProps) => {
  const batteryOptions = {
    batteryBody: {
      strokeWidth: 4,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#111",
    },
    batteryCap: {
      fill: "none",
      strokeWidth: 4,
      strokeColor: "#111",
      cornerRadius: 2,
      capToBodyRatio: 0.4,
    },
    batteryMeter: {
      fill: "green",
      lowBatteryValue: 0,
      lowBatteryFill: "red",
      outerGap: 1,
      noOfCells: 10, // more than 1, will create cell battery
      interCellsGap: 3,
    },
    readingText: {
      lightContrastColor: "#111",
      darkContrastColor: "#fff",
      lowBatteryColor: "red",
      fontFamily: "Helvetica",
      fontSize: 14,
      showPercentage: true,
    },
    chargingFlash: {
      scale: undefined,
      fill: "orange",
      animated: true,
      animationDuration: 1000,
    },
  };
  const theme = useTheme();
  if (props.certificate) {
    return (
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", marginTop: 1 }}
        spacing={1}
      >
        {/*       <Box sx={{ display: "flex", flexDirection: "row", flexWrap:"wrap", marginTop: 1 }}> */}
        {props.certificate.badgeQuestionStatus.map((bdg) => {
          return (
            <Grid
              key={bdg.sdgName}
              item
              xs={12}
              sm={3}
              sx={{ minWidth: "250px" }}
              alignItems="center"
            >
              <Card
                key={bdg.sdgName}
                sx={{
                  margin: "5px",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                      }}
                      src={bdg.sdgImage}
                    />
                    <Box>
                      <BatteryGauge
                        value={bdg.answeredQuestions}
                        maxValue={bdg.totalQuestions}
                        size={100}
                        orientation="vertical"
                        customization={batteryOptions}
                      />
                    </Box>
                  </Box>
                </CardContent>
                <CardActionArea
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: 2,
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                  ></Typography>
                  <Typography variant="body2" color="text.secondary">
                    {bdg.questionsCompletedLabel}
                  </Typography>
                </CardActionArea>{" "}
              </Card>
            </Grid>
          );
        })}
        {/*       </Box> */}
      </Grid>
    );
  }
  return null;
});

export const CertificateView = observer((props: IMSMECertificateProps) => {
  if (props.goal) {
    return (
      <Box sx={{ padding: "5px" }}>
        <Box>
          <Box
            component="img"
            height="100px"
            src={props.goal?.goalImage}
            alt="certificate"
          />
          <Typography gutterBottom variant="h5" component="div">
            {props.goal?.goalName}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
});
