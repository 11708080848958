import { makeAutoObservable } from "mobx";
import {
  DialogResult,
  DialogType,
  IComponentDialogProps,
} from "../controls/dialogs";

export interface IDialogViewModel {
  hasMessage: boolean;
  dialogMessage: string;
  dialogTitle: string;
  dialogType: DialogType;
  componentProps?: IComponentDialogProps;
  okAllowed: boolean;
  clearDialog: () => void;
  setDialog: (
    title: string,
    message: string,
    dialogType: DialogType,
    callback?: (result: DialogResult) => void,
    componentProps?: IComponentDialogProps,
    isOkAllowed?: boolean
  ) => void;
  handleDialogClose: (result: DialogResult) => void;
  setOkAllowed: (value: boolean) => void;
}

export class DialogViewModel implements IDialogViewModel {
  hasMessage = false;
  dialogMessage = "";
  dialogTitle = "";
  dialogType = DialogType.Alert;
  private callback?: (result: DialogResult) => void;
  componentProps?: IComponentDialogProps;
  okAllowed: boolean = true;

  clearDialog = () => {
    this.hasMessage = false;
    this.dialogTitle = "";
    this.dialogMessage = "";
    this.dialogType = DialogType.None;
  };

  setDialog = (
    title: string,
    message: string,
    dialogType: DialogType,
    callback?: (result: DialogResult) => void,
    componentProps?: IComponentDialogProps,
    isOkAllowed: boolean = true
  ) => {
    this.hasMessage = true;
    this.dialogTitle = title;
    this.dialogMessage = message;
    this.dialogType = dialogType;
    this.callback = callback;
    this.componentProps = componentProps;
    this.okAllowed = isOkAllowed;
  };

  setOkAllowed = (value: boolean) => {
    this.okAllowed = value;
  };

  handleDialogClose = (result: DialogResult) => {
    this.clearDialog();
    this.callback?.(result);
    this.callback = undefined;
  };

  constructor() {
    makeAutoObservable(this);
  }
}
