import React, { useEffect } from "react";
import { IHigherContextProviderProps } from "./firebase";
import { LanguageEntryCategory, LanguageEntryDto } from "../utils/api";
import { useUnwrap } from "@hungry-egg/rx-react";
import languageService from "./languageService";
import { IKeyedObject, ILanguageContext } from "./types";

export namespace Language {
  export const EN: string = "EN";
  export const OTHER: string = "OTH";
  export namespace EntryCategory {
    export const MainMenu: string = "MainMenu";
    export const UserMenu: string = "UserMenu";
    export const FirebaseError: string = "FirebaseError";
    export const Error: string = "Error";
    export const SignIn: string = "SignInPage";
    export const Register: string = "RegisterPage";
    export const RegisterBasicDetail: string = "RegisterBasicDetail";
    export const RegisterCompanyDetail: string = "RegisterCompanyDetail";
    export const RegisterAdditionalInfo: string = "RegisterAdditionalInfo";
    export const Tenant: string = "Tenant";
    export const UserManagement: string = "UserManagement";
  }
}

export const LanguageContext = React.createContext<ILanguageContext>({
  selectedLanguage: Language.EN,
  translate: (category, source) => source,
  changeLanguage: () => {},
  getLanguages: () => [],
  getCategory: (
    category: LanguageEntryCategory
  ): { [key: string]: LanguageEntryDto } => {
    return {};
  },
});

export const LanguageContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const selectedLanguage = useUnwrap(languageService.language$);

  useEffect(() => {
    if (selectedLanguage === "") {
      languageService.setLanguage(Language.EN);
    }
  }, [selectedLanguage]);

  const changeLanguage = (language: string) => {
    if (selectedLanguage === Language.EN && language === Language.OTHER) {
      languageService.setLanguage(Language.OTHER);
    } else if (
      selectedLanguage === Language.OTHER &&
      language === Language.EN
    ) {
      languageService.setLanguage(Language.EN);
    }
  };

  const getCategory = (category: any): { [key: string]: LanguageEntryDto } => {
    return languageService.getCategory(category);
  };

  const translate = (
    category: LanguageEntryCategory,
    source: IKeyedObject<string>
  ): IKeyedObject<string> => {
    const returnObj = { ...source };
    if (selectedLanguage === "") {
      return returnObj;
    }

    const categoryObj = getCategory(category);
    Object.keys(source).forEach((key: string) => {
      if (categoryObj[key]?.value) {
        returnObj[key] = categoryObj[key]?.value;
      }
    });
    return returnObj;
  };

  const getLanguages = () => {
    return [Language.EN, Language.OTHER];
  };
  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        translate,
        getCategory,
        changeLanguage,
        getLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
