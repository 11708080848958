import { makeAutoObservable } from "mobx";
import { IDialogViewModel } from "./dialogViewModel";
import { IContextSensitiveViewModel } from "./contextSensitiveViewModel";
import { IBaseScreenDto, UIAction } from "../utils/api";

export interface IBasePageViewModel {
  pageTitleViewModel: IPageTitleViewModel;
  contextViewModel: IContextSensitiveViewModel;
  dialogViewModel: IDialogViewModel;
  widthOverride?: "xl" | "xs" | "lg" | "md" |  undefined;
}

export interface IPageTitleViewModel {
  title: string;
  favoriteAction: UIAction | undefined;
  favoriteText: string;
  favoriteVariableSub: string | undefined;
  pageActions: UIAction[] | undefined;

  setPageDetails: (
    pageDetails: IBaseScreenDto,
    favoriteVariableSub: string | undefined
  ) => void;
}

export class PageTitleViewModel implements IPageTitleViewModel {
  title = "";
  favoriteAction: UIAction | undefined;
  favoriteText = "";
  favoriteVariableSub: string | undefined = undefined;
  pageActions: UIAction[] | undefined = [];

  setPageDetails = (
    pageDetails: IBaseScreenDto,
    favoriteVariableSub: string | undefined
  ) => {
    this.title = pageDetails.screenTitle;
    this.favoriteAction = pageDetails.favoriteAction;
    this.favoriteText = pageDetails.favoriteText;
    this.favoriteVariableSub = favoriteVariableSub;
    this.pageActions = pageDetails.screenActions;
  };

  constructor() {
    makeAutoObservable(this);
  }
}
