import { CssBaseline, styled, Box, Grid } from "@mui/material";
import { Footer } from "./footer";
import { IHigherContextProviderProps } from "../core/firebase";
import NavBar from "./navbar";
import ActivityBar from "../controls/activityBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ActivityContext } from "../core/activityContext";
import { useEffect, useContext } from "react";
import { ClassNames } from "@emotion/react";
import Divider from "@mui/material/Divider";
import SwipeableActivityBar from "../controls/swipeableActivityBar";
import { desktopWidth, drawerBleeding, drawerWidth, mobileWidth, swipeableHeight } from "../core/constants";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
/*   padding: theme.spacing(0, 1), TO DO*/ 
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Layout: React.FC<IHigherContextProviderProps> = ({ children }) => {
  const isNotMobile = useMediaQuery("(min-width:" + mobileWidth + "px)");
  const isDesktop = useMediaQuery("(min-width:" + desktopWidth + "px)");
  const { activityBarVisible } = useContext(ActivityContext);

 return (

    <Box sx={{ display: "flex", flex:"1", flexDirection:"column" , height:"100vh"}}>
    <CssBaseline /> 
    <NavBar />
    
    <Box component="main"  sx={{ display:"flex", flex: "1", paddingLeft: isDesktop?8:0, flexDirection:"column" }}>
      <DrawerHeader />
      { activityBarVisible && isNotMobile? 
      (
      <Grid container  sx={{ display:"flex", flex: "1"}}>
        <Grid item xs={12} sm= {12} md={9.5}>
          <Box sx={{ display:"flex", flex: "1"}} >{children}</Box>
        </Grid>
        
        <Grid item xs={12} sm= {12} md={2.5} sx={{display:"flex", flex:"1", flexDirection:"column"}}>
        <Divider />
          <Box sx={{display:"flex", flex: "1", justifyContent:"flex-end"}}>
            <ActivityBar />
          </Box>
        </Grid>
        
      </Grid>
      )
      :
      (
      <Grid container sx={{ display:"flex", flex: "1", pb:"60px" }} >
        <Grid item xs={12} >
          <Box sx={{ display:"flex", flex: "1",}}>{children}</Box>
        </Grid>
      </Grid>
      )
      }

    </Box>
    <Footer />
    {!isNotMobile && (<SwipeableActivityBar/>)}
  </Box>
  ); 

};
export default Layout;

  
