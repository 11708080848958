import { observer } from "mobx-react";
import {
  Box,
  Checkbox,
  Button,
  FormControlLabel,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  Icon,
  Typography,
  IconButton,
  FormGroup,
  useTheme,
  Autocomplete,
  Rating,
  useMediaQuery,
} from "@mui/material";
import { FieldWrapper, FormButtonWrapper } from "../common/formTypes";
import { useEffect, useRef, useState } from "react";
import { FileParameter, ModelFieldType } from "../utils/api";
import BorderBox, { StyledBox } from "./borderBox";
import { buttonWidth, iconbuttonWidth, mobileWidth } from "../core/constants";
import React from "react";

export const FieldWrapperControl = observer((model: FieldWrapper) => {
  console.log("model visibility", model.field?.label, model.isVisible);
  if (model.isVisible) {
    if (model.field?.fieldType === ModelFieldType.Image) {
      return <ImageUploaderWrapper {...model} />;
    }
    if (model.field?.fieldType === ModelFieldType.NumberField) {
      return <NumberFieldWrapper {...model} />;
    }

    if (model.field?.fieldType === ModelFieldType.Selection) {
      return <SelectFieldWrapper {...model} />;
    }

    if (model.field?.fieldType === ModelFieldType.BooleanField) {
      return <CheckBoxWrapper {...model} />;
    }

    if (model.field?.fieldType === ModelFieldType.SingleChoice) {
      return <SingleChoiceWrapper {...model} />;
    }

    if (model.field?.fieldType === ModelFieldType.MultipleChoice) {
      return <MultipleChoiceWrapper {...model} />;
    }

    if (model.field?.fieldType === ModelFieldType.FileUpload) {
      return <FileUploadWrapperControl {...model} />;
    }
    if (model.field?.fieldType === ModelFieldType.RadioList) {
      return <RadioListFieldWrapper {...model} />;
    }
    if (model.field?.fieldType === ModelFieldType.Rating) {
      return <RatingWrapper {...model} />;
    }
    if (model.field?.fieldType === ModelFieldType.MultiLine) {
      return <MultilineWrapper {...model} />;
    }
    return <TextFieldWrapper {...model} />;
  }
  return null;
});

export const RatingWrapper = observer((model: FieldWrapper) => {
  const isMobile = !useMediaQuery("(min-width:" + mobileWidth + "px)");

  if (model.field?.isVisible) {
    return (
      <Box>
        {!isMobile && (
          <Typography component="legend">{model.field.label}</Typography>
        )}
        {isMobile && (
          <Box sx={{ mt: "10px" }}>
            <SelectFieldWrapper {...model} />
          </Box>
        )}
        <Rating
          value={+model.fieldValue}
          onChange={(e, newValue) => model.setFieldValue(newValue)}
          precision={0.5}
          readOnly={model.field.isDisabled || isMobile}
        />
      </Box>
    );
  }
  return null;
});

export const MultipleChoiceWrapper = observer((model: FieldWrapper) => {
  const [selection, setSelection] = useState<string[]>(
    model.fieldValue?.split(",") ?? []
  );

  const getSelected = (value: string) => {
    return selection.includes(value);
  };

  const setSelected = (value: string, selected: boolean) => {
    let currentSelection = selection;
    if (selected) {
      currentSelection.push(value);
    } else {
      currentSelection = currentSelection.filter((item) => item !== value);
    }
    model.setFieldValue(currentSelection.join(","));
    setSelection(currentSelection);
  };

  if (model.field?.isVisible) {
    return (
      <BorderBox>
        <FormGroup>
          {model.field.selectionKeyValues.map((item) => {
            return (
              <FormControlLabel
                key={item.key}
                value={item.key}
                disabled={model.field?.isDisabled}
                control={
                  <Checkbox
                    checked={getSelected(item.key)}
                    onChange={(e) => setSelected(item.key, e.target.checked)}
                  />
                }
                label={item.value}
              />
            );
          })}
        </FormGroup>
      </BorderBox>
    );
  }
  return null;
});

export const FileUploadWrapperControl = observer((model: FieldWrapper) => {
  const [key, setKey] = useState(Date.now());
  const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files.length > 0) {
        const files: FileParameter[] = [];

        for (let i = 0; i < event.target.files.length; i++) {
          const uploadedFile = event.target.files[i];
          const imageUri = URL.createObjectURL(uploadedFile);
          const response = await fetch(imageUri);
          const blob = await response.blob();
          const value: FileParameter = {
            data: blob,
            fileName: uploadedFile.name,
          };
          files.push(value);
        }
        model.setFieldValue(files);
        setKey(Date.now());
      }
    }
  };

  const removeFile = (fileName: string) => {
    model.removeFile(fileName);
  };

  const theme = useTheme();

  if (model.field) {
    return (
      <StyledBox sx={{ mt: 2 }}>
        {/*         <Label>{model.field?.label}</Label> */}
        {model.fileNames.length > 0 && (
          <Typography
            variant="caption"
            sx={{
              position: "absolute",
              top: "-17px",
              left: "5px",
              paddingTop: "8px",
              paddingLeft: "8px",
              color: model.field?.isDisabled
                ? theme.palette.text.disabled
                : theme.palette.text.secondary,
              backgroundColor: theme.palette.background.default,
            }}
          >
            {model.field?.label}
          </Typography>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          {model.fileNames.length === 0 && (
            <Typography
              variant="body1"
              sx={{
                position: "absolute",
                left: "5px",
                padding: "8px",
                color: model.field?.isDisabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
              }}
            >
              {model.field?.label}
            </Typography>
          )}
          <Box
            flexGrow="1"
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="wrap"
          >
            {model.fileNames &&
              model.fileNames.map((file) => (
                <Box
                  key={file}
                  display="flex"
                  flexDirection="row"
                  border="1px solid gray"
                  alignItems="center"
                  borderRadius="5px"
                  margin="2px"
                  padding="2px"
                >
                  <Typography variant="body2">{file}</Typography>
                  <IconButton
                    size="small"
                    color="error"
                    disabled={model.field?.isDisabled}
                    onClick={() => removeFile(file)}
                  >
                    <Icon sx={{ fontSize: "20px", marginLeft: "5px" }}>
                      {"cancel"}
                    </Icon>
                  </IconButton>
                </Box>
              ))}
          </Box>
          <Button
            variant="contained"
            component="label"
            /* sx={{ minWidth: "250px" }} */
            sx={{ width: `calc(${iconbuttonWidth}px)` }}
            disabled={model.field.isDisabled}
            startIcon={<Icon>{"upload_file"}</Icon>}
          >
            <input
              type="file"
              hidden
              multiple
              accept="image/*, application/pdf"
              onChange={(e) => selectFile(e)}
              key={key}
            />
          </Button>
        </Box>
      </StyledBox>
    );
  }
  return null;
});

export const SingleChoiceWrapper = observer((model: FieldWrapper) => {
  if (model.field?.isVisible) {
    return (
      <BorderBox>
        <RadioGroup
          value={model.fieldValue}
          onChange={(e) => model.setFieldValue(e.target.value)}
        >
          {model.field.selectionKeyValues.map((item) => {
            return (
              <FormControlLabel
                key={item.key}
                value={item.key}
                control={<Radio />}
                label={item.value}
                disabled={model.field?.isDisabled}
              />
            );
          })}
        </RadioGroup>
      </BorderBox>
    );
  }
  return null;
});

export const CheckBoxWrapper = observer((model: FieldWrapper) => {
  if (model.field?.isVisible) {
    const labelId = model.field.label.replaceAll(" ", "") + "label";
    const controlId = model.field.label.replaceAll(" ", "");
    return (
      <Box
        sx={{ border: 1, borderRadius: 1, borderColor: "grey.500", padding: 1 }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={model.fieldValue ?? false}
              name={model.field?.label.replaceAll(" ", "")}
              onChange={(e) => model.setFieldValue(e.target.checked)}
              disabled={model.field?.isDisabled}
              id={controlId}
            />
          }
          label={model.field.label}
          id={labelId}
        />
      </Box>
    );
  }
  return null;
});

export const ImageUploaderWrapper = observer((model: FieldWrapper) => {
  const [imageUrl, setImageUrl] = useState(model.imageUrl);

  const selectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      if (event.target.files.length > 0) {
        const imageUri = URL.createObjectURL(event.target.files[0]);

        const response = await fetch(imageUri);
        const blob = await response.blob();
        const file = event.target.files[0];
        const value: FileParameter = {
          data: blob,
          fileName: file.name,
        };
        model.setFieldValue(value);
        setImageUrl(imageUri);
      } else {
        setImageUrl("");
        model.setFieldValue(undefined);
      }
    }
  };
  const theme = useTheme();

  if (!model.field?.isVisible) {
    return null;
  }

  return (
    <StyledBox sx={{ mt: 2 }}>
      {imageUrl && (
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            top: "-17px",
            left: "5px",
            paddingTop: "8px",
            paddingLeft: "8px",
            color: model.field?.isDisabled
              ? theme.palette.text.disabled
              : theme.palette.text.secondary,
            backgroundColor: theme.palette.background.default,
          }}
        >
          {model.field?.label}
        </Typography>
      )}

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box
          flexGrow="1"
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
        >
          {imageUrl && (
            <Box
              component="img"
              src={imageUrl}
              maxHeight={"150px"}
              maxWidth={"150px"}
            />
          )}

          {!imageUrl && (
            <Typography
              variant="body1"
              sx={{
                position: "absolute",
                left: "5px",
                padding: "8px",
                color: model.field?.isDisabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
              }}
            >
              {model.field?.label}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          component="label"
          disabled={model.field.isDisabled}
          sx={{ width: `calc(${iconbuttonWidth}px)` }}
          startIcon={<Icon>{"upload_file"}</Icon>}
        >
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => selectFile(e)}
          />
        </Button>
      </Box>
    </StyledBox>
  );
});
export const MultilineWrapper = observer((model: FieldWrapper) => {
  console.log("I am in multiline", model.field?.label, model.field?.isDisabled);
  if (model.field?.isVisible) {
    return (
      <>
        <TextField
          margin="normal"
          error={model.hasError}
          helperText={model.hasError ? model.field?.fieldError ?? "" : ""}
          required={!!model.field.isRequired}
          fullWidth
          multiline={true}
          name={model.field?.label.replaceAll(" ", "")}
          label={model.field?.label}
          type={
            model.field.fieldType === ModelFieldType.Password
              ? "password"
              : "text"
          }
          minRows={model.field.maxNumberOfLines}
          maxRows={model.field.maxNumberOfLines}
          id={model.field?.label.replaceAll(" ", "")}
          value={model.fieldValue ?? ""}
          onChange={(e) => model.setFieldValue(e.target.value)}
          disabled={model.field?.isDisabled}
        />
      </>
    );
  }
  return null;
});

export const TextFieldWrapper = observer((model: FieldWrapper) => {
  if (model.field?.isVisible) {
    return (
      <>
        <TextField
          margin="normal"
          error={model.hasError}
          helperText={model.hasError ? model.field?.fieldError ?? "" : ""}
          required={!!model.field.isRequired}
          fullWidth
          name={model.field?.label.replaceAll(" ", "")}
          label={model.field?.label}
          type={
            model.field.fieldType === ModelFieldType.Password
              ? "password"
              : "text"
          }
          id={model.field?.label.replaceAll(" ", "")}
          value={model.fieldValue ?? ""}
          onChange={(e) => model.setFieldValue(e.target.value)}
          disabled={model.field?.isDisabled}
        />
      </>
    );
  }
  return null;
});

export const NumberFieldWrapper = observer((model: FieldWrapper) => {
  if (model.field?.isVisible) {
    return (
      <>
        <TextField
          margin="normal"
          error={model.hasError}
          helperText={model.hasError ? model.field?.fieldError ?? "" : ""}
          required={!!model.field.isRequired}
          fullWidth
          name={model.field?.label.replaceAll(" ", "")}
          label={model.field?.label}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          id={model.field?.label.replaceAll(" ", "")}
          value={model.fieldValue ?? ""}
          onChange={(e) => model.setFieldValue(e.target.value)}
          disabled={model.field?.isDisabled}
        />
      </>
    );
  }
  return null;
});

interface IOption {
  id: string;
  label: string;
}
export const SelectFieldWrapper = observer((model: FieldWrapper) => {
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
  const [options, setOptions] = useState<IOption[]>([]);
  if (model.field?.isVisible) {
    useEffect(() => {
      if (model.field) {
        const selectOptions = model.field?.selectionKeyValues.map((x) => {
          const option: IOption = {
            id: x.key,
            label: x.value,
          };
          return option;
        });
        setOptions(selectOptions);
      }
    }, [model.field]);

    useEffect(() => {
      if (options && model.fieldValue) {
        const selectedValue = options.find((x) => x.id === model.fieldValue);
        if (selectedValue) {
          setSelectedOption(selectedValue);
        }
      }
    }, [options]);

    const handleChange = (
      event: React.ChangeEvent<{}>,
      newValue: IOption | null
    ) => {
      setSelectedOption(newValue);
      model.setFieldValue(newValue?.id);
    };

    return (
      <>
        <Autocomplete
          options={options ?? []}
          value={selectedOption}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label={model.field?.label} />
          )}
          
        />
      </>
    );
  }

  return null;
});

export const RadioListFieldWrapper = observer((model: FieldWrapper) => {
  if (model.field?.isVisible) {
    const labelId = model.field?.label.replaceAll(" ", "") + "label";
    const controlId = model.field?.label.replaceAll(" ", "");
    const controlIdGrid = model.field?.label.replaceAll(" ", "") + "grid";
    var keyCodeG = "";
    var keyCode = "";
    return (
      <>
        <RadioGroup
          name={labelId}
          sx={{ width: "100%" }}
          color="primary"
          value={model.fieldValue}
          onChange={(e) => model.setFieldValue(e.target.value)}
          id={controlId}
        >
          <span>{model.field.label}</span>
          <Grid container direction="row">
            {model.field.selectionKeyValues.map((x) => {
              keyCode = controlId + x.key;
              keyCodeG = controlIdGrid + x.key;
              return (
                <Grid item xs key={keyCodeG}>
                  <FormControlLabel
                    value={x.value}
                    control={<Radio />}
                    label={x.key}
                    key={keyCode}
                  />
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      </>
    );
  }
  return null;
});
interface IFormButtonSection {
  cancelAction: FormButtonWrapper | undefined;
  actions: FormButtonWrapper[];
}

export const FormButtonSection = observer((props: IFormButtonSection) => {
  return (
    <Box
      sx={{ mt: 2, display: "flex", justifyContent: "space-evenly", flex: "1" }}
    >
      {props.actions.map((action) => {
        const buttonKey = `btn-${action.button?.label}`;
        return (
          <Button
            key={buttonKey}
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("action button clicked");
              action.onClick();
            }}
            disabled={action.isDisabled}
            sx={{ width: `calc(${buttonWidth}px)` }}
          >
            {action.button?.label}
          </Button>
        );
      })}

      <Button
        variant="outlined"
        color="primary"
        onClick={() => props.cancelAction?.onClick()}
        sx={{ width: `calc(${buttonWidth}px)` }}
      >
        {props.cancelAction?.button?.label}
      </Button>
    </Box>
  );
});
