import { NavigateFunction } from "react-router-dom";
import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  DialogViewModel,
  IDialogViewModel,
} from "../../common/dialogViewModel";
import { FormButtonWrapper, FieldWrapper } from "../../common/formTypes";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import { DialogType } from "../../controls/dialogs";
import {
  IBaseScreenDto,
  ResultOfBusinessModelWrapperDto,
  BusinessClient,
  BusinessModelWrapperDto,
  UserClient,
  BusinessUser,
  BusinessUserType,
  BusinessUserCompany,
  BusinessUserAddress,
  UserAccountType,
} from "../../utils/api";
import { makeAutoObservable, runInAction } from "mobx";
import { ROUTES } from "../../core/constants";

import { UserInformation } from "./userInformation";
import { AdditionalInformation } from "./addtionalInformation";
import { BasicDetails } from "./basicDetails";

interface IBusinessEditViewModel extends IBasePageViewModel {
  model: IBusinessEditFormModel | undefined;
  fromSettings:boolean|undefined;
}

export class BusinessEditViewModel implements IBusinessEditViewModel {
  
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  model: BusinessEditFormModel | undefined = new BusinessEditFormModel(this.id);
  rowVersion: string | undefined = undefined;
  coType: number | undefined = undefined;
  fromSettings: boolean | undefined=false;
  constructor(
    private selectedLanguage: string,
    private id: string,
    private companyType: number,
    private isFromSettings:boolean,
    private navigate: NavigateFunction
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData(companyType);
    });
    this.coType = companyType;
    this.fromSettings=isFromSettings;
    
  }
  getBusinessUser = (): BusinessUser => {
    const user = new BusinessUser();
    user.firstName = this.model?.firstName.fieldValue;
    user.lastName = this.model?.lastName.fieldValue;
    user.userType =
      this.coType === 0 ? BusinessUserType.MSME : BusinessUserType.Agency;
    if (this.id === "new") {
      user.userType =
        this.model?.companyType.fieldValue === "MSME"
          ? BusinessUserType.MSME
          : BusinessUserType.Agency;
    }
    user.providerId = "";
    user.email = this.model?.email.fieldValue;
    user.uId = "";
    user.password = this.model?.password.fieldValue;
    user.language = this.selectedLanguage;
    user.companyDetails = new BusinessUserCompany();
    user.companyAddress = new BusinessUserAddress();
    user.companyDetails.numberOfDirectors =
      this.model?.numberOfDirectors.fieldValue;
    user.companyDetails.maleFemaleSplit =
      this.model?.maleFemaleSplit.fieldValue;
    user.companyDetails.businessSectorId =
      this.model?.businessSectorId.fieldValue;
    user.companyDetails.businessTypeId = this.model?.businessTypeId.fieldValue;
    user.companyDetails.registrationNumber =
      this.model?.registrationNumber.fieldValue;
    user.companyAddress.companyName = this.model?.companyName.fieldValue;
    user.companyAddress.address1 = this.model?.address1.fieldValue;
    user.companyAddress.address2 = this.model?.address2.fieldValue;
    user.companyAddress.address3 = this.model?.address3.fieldValue;
    user.companyAddress.address4 = this.model?.address3.fieldValue;
    user.companyAddress.postCode = this.model?.postCode.fieldValue;
    user.companyAddress.state = this.model?.state.fieldValue;
    user.companyAddress.countryId = this.model?.countryId.fieldValue;
    
    return user;
  };
  handleSave = () => {
    if (!this.model?.isValidData()) {
      console.log("Invalid data");
      return;
    }
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    if (this.id === "new") {
      const businessUserClient = new UserClient();
      businessUserClient
        .registerBusinessUser(this.getBusinessUser())
        .then((result) => {
          if (result.succeeded) {
            this.dialogViewModel.clearDialog();
            if (this.coType === 0) {
              this.navigate(ROUTES.MSMES);
            } else {
              this.navigate(ROUTES.AGENCIES);
            }
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog("Error", reason, DialogType.Alert);
        });
    } else {
      const client = new BusinessClient();
      
      if (this.coType === 0) {
        client
          .updateMSME(
            this.id,
            this.model.companyName.fieldValue,
            this.model.address1.fieldValue,
            this.model.address2.fieldValue,
            this.model.address3.fieldValue,
            this.model.address4.fieldValue,
            this.model.postCode.fieldValue,
            this.model.state.fieldValue,
            +this.model.countryId.fieldValue,
            +this.model.numberOfDirectors.fieldValue,
            +this.model.maleFemaleSplit.fieldValue,
            +this.model.businessTypeId.fieldValue,
            +this.model.businessSectorId.fieldValue,
            this.model.registrationNumber.fieldValue,
            this.rowVersion,
            this.selectedLanguage
          )
          .then((result) => {
            if (result.succeeded) {
              this.dialogViewModel.clearDialog();
              if (this.fromSettings)
                {
                  this.navigate(ROUTES.SETTINGS);
                }
                else
                {
                  this.navigate(ROUTES.MSMES);
                }
              
            } else {
              this.dialogViewModel.setDialog(
                result.errorCode,
                result.error,
                DialogType.Alert
              );
            }
          })
          .catch((reason) =>
            this.dialogViewModel.setDialog("Error", reason, DialogType.Alert)
          );
      } else {
        client
          .updateAgency(
            this.id,
            this.model.companyName.fieldValue,
            this.model.address1.fieldValue,
            this.model.address2.fieldValue,
            this.model.address3.fieldValue,
            this.model.address4.fieldValue,
            this.model.postCode.fieldValue,
            this.model.state.fieldValue,
            +this.model.countryId.fieldValue,
            +this.model.numberOfDirectors.fieldValue,
            +this.model.maleFemaleSplit.fieldValue,
            +this.model.businessTypeId.fieldValue,
            +this.model.businessSectorId.fieldValue,
            this.model.registrationNumber.fieldValue,
            this.rowVersion,
            this.selectedLanguage
          )
          .then((result) => {
            if (result.succeeded) {
              this.dialogViewModel.clearDialog();
              if (this.fromSettings) {
                this.navigate(ROUTES.SETTINGS);
              }
              else {
                this.navigate(ROUTES.AGENCIES);
              }
            } else {
              this.dialogViewModel.setDialog(
                result.errorCode,
                result.error,
                DialogType.Alert
              );
            }
          })
          .catch((reason) =>
            this.dialogViewModel.setDialog("Error", reason, DialogType.Alert)
          );
      }
    }
  };
  handleAcceptReject = (action: string) => {};
  onSave = (action: string) => {
    if (action === "SAVE") {
      this.handleSave();
    }
  };
  onCancel = () => {
    this.navigate(-1);
  };
  setBusiness = (result: ResultOfBusinessModelWrapperDto) => {
    if (result.succeeded) {
      this.rowVersion = result.value?.rowVersion;
      this.contextViewModel.setContextActivities(
        result.value?.screenActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        this.id
      );
      this.dialogViewModel.clearDialog();
      if (result.value?.business) {
        this.model?.setData(result.value, this.onSave, this.onCancel);
      }
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };
  fetchData = (companyType: number) => {
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    if (this.id === "new") {
      const client = new BusinessClient();
      client
        .getMSMENewModel(this.selectedLanguage, companyType)
        .then((result) => {
          this.setBusiness(result);
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    } else {
      const client = new BusinessClient();
      if (companyType === 0) {
        client
          .getMSMEModel(this.selectedLanguage, this.id)
          .then((result) => {
            this.setBusiness(result);
          })
          .catch((reason) => {
            this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
          });
      } else {
        client
          .getAgencyModel(this.selectedLanguage, this.id)
          .then((result) => {
            this.setBusiness(result);
          })
          .catch((reason) => {
            this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
          });
      }
    }
  };
}
interface stepTypes {
  label: string;
  component: any;
}
export interface IBusinessEditFormModel {
  title: string;
  companyName: FieldWrapper | undefined;
  address1: FieldWrapper | undefined;
  address2: FieldWrapper | undefined;
  address3: FieldWrapper | undefined;
  address4: FieldWrapper | undefined;
  postCode: FieldWrapper | undefined;
  state: FieldWrapper | undefined;
  countryId: FieldWrapper | undefined;
  businessSectorId: FieldWrapper | undefined;
  businessTypeId: FieldWrapper | undefined;
  registrationNumber: FieldWrapper | undefined;
  maleFemaleSplit: FieldWrapper | undefined;
  numberOfDirectors: FieldWrapper | undefined;
  firstName: FieldWrapper | undefined;
  lastName: FieldWrapper | undefined;
  email: FieldWrapper | undefined;
  password: FieldWrapper | undefined;
  confirmPassword: FieldWrapper | undefined;
  companyType: FieldWrapper | undefined;
  companyId: string;
  //acceptTerms:FieldWrapper|undefined;
  termsLink1:string,
  termsLink2:string,
  actions: FormButtonWrapper[];
  cancelAction: FormButtonWrapper | undefined;
  setData: (model: BusinessModelWrapperDto) => void;
  isValidData: () => boolean;
  steps: stepTypes[];
  registerSteps: stepTypes[];
  activeStep: number;
  registrationStepsLabels: string[];
  stepperButtonLabels: string[];
  
}
export class BusinessEditFormModel implements IBusinessEditFormModel {
  onValueChange = () => {
    var isValidData =
      this.companyName.canSave() &&
      this.address1.canSave() &&
      this.address2.canSave() &&
      this.postCode.canSave() &&
      this.state.canSave();
    if (this.companyType.fieldValue === "MSME") {
      isValidData =
        isValidData &&
        this.registrationNumber.canSave() &&
        this.maleFemaleSplit.canSave() &&
        this.numberOfDirectors.canSave();
    }
    if (this.companyId === "new") {
      isValidData =
        isValidData &&
        this.firstName.canSave() &&
        this.lastName.canSave() &&
        this.email.canSave() &&
        this.password.canSave() &&
        this.confirmPassword.canSave() &&
        this.password.fieldValue === this.confirmPassword.fieldValue &&
        this.acceptTerms.fieldValue === true;
    }
    this.actions.forEach((action) => action.setIsDisabled(!isValidData));
  };
  title: string = "";
  companyName: FieldWrapper = new FieldWrapper();
  address1: FieldWrapper = new FieldWrapper();
  address2: FieldWrapper = new FieldWrapper();
  address3: FieldWrapper = new FieldWrapper();
  address4: FieldWrapper = new FieldWrapper();
  postCode: FieldWrapper = new FieldWrapper();
  state: FieldWrapper = new FieldWrapper();
  countryId: FieldWrapper = new FieldWrapper();
  businessSectorId: FieldWrapper = new FieldWrapper();
  businessTypeId: FieldWrapper = new FieldWrapper();
  registrationNumber: FieldWrapper = new FieldWrapper();
  maleFemaleSplit: FieldWrapper = new FieldWrapper();
  numberOfDirectors: FieldWrapper = new FieldWrapper();
  firstName: FieldWrapper = new FieldWrapper();
  lastName: FieldWrapper = new FieldWrapper();
  email: FieldWrapper = new FieldWrapper();
  password: FieldWrapper = new FieldWrapper();
  confirmPassword: FieldWrapper = new FieldWrapper();
  acceptTerms: FieldWrapper = new FieldWrapper();
  companyId: string = "";
  companyType: FieldWrapper = new FieldWrapper();
  termsLink1: string="";
  termsLink2: string="";
  actions: FormButtonWrapper[] = [];
  cancelAction: FormButtonWrapper | undefined = undefined;
  
  registrationStepsLabels: string[] = [];
  stepperButtonLabels: string[] = [];
  steps: stepTypes[] = [
    { label: "Basic Details", component: UserInformation },
    { label: "Company Address", component: BasicDetails },
    { label: "Additional Information", component: AdditionalInformation },
  ];
  registerSteps: stepTypes[] = [this.steps[0], this.steps[1], this.steps[2]];
  activeStep: number = 0;
  
  isValidData = () => {
    this.companyName.validate();
    this.address1.validate();
    this.address2.validate();
    this.state.validate();
    this.postCode.validate();
    var msmeError = false;
    if (this.companyType.fieldValue === "MSME") {
      this.maleFemaleSplit.validate();
      this.numberOfDirectors.validate();
      this.registrationNumber.validate();
      msmeError =
        this.maleFemaleSplit.hasError ||
        this.numberOfDirectors.hasError ||
        this.registrationNumber.hasError;
    }

    var userInfoError = false;
    if (this.companyId === "new") {
      this.firstName.validate();
      this.lastName.validate();
      this.email.validate();
      this.password.validate();
      this.confirmPassword.validate();
      this.acceptTerms.validate();
      userInfoError =
        this.firstName.hasError ||
        this.lastName.hasError ||
        this.email.hasError ||
        this.password.hasError ||
        this.confirmPassword.hasError ||
        this.acceptTerms.fieldValue !== true;
    }

    return !(
      this.companyName.hasError ||
      this.address1.hasError ||
      this.address2.hasError ||
      this.state.hasError ||
      this.postCode.hasError ||
      userInfoError ||
      msmeError
    );
  };
  onCompanyTypeChange = () => {
    this.onValueChange();
    if (this.companyType.fieldValue === "MSME") {
      this.registerSteps = [this.steps[0], this.steps[1], this.steps[2]];
    } else {
      this.registerSteps = [this.steps[0], this.steps[1]];
    }
  };

  cancelHandle = (action: string) => {
    if (this.onCancel) {
      this.onCancel();
    }
  };
  actionHandle = (action: string) => {
    this.onSave?.(action);
  };
  private onSave?: (action: string) => void;
  private onCancel?: () => void;

  setData = (
    model: BusinessModelWrapperDto,
    onSave?: (action: string) => void,
    onCancel?: () => void
  ) => {
    this.registerSteps =
      model.business.companyType.value === "MSME"
        ? [this.steps[0], this.steps[1], this.steps[2]]
        : [this.steps[0], this.steps[1]];
    this.title = model.business.formTitle;
    this.companyName.setField(model.business.companyName, this.onValueChange);
    this.address1.setField(model.business.address1, this.onValueChange);
    this.address2.setField(model.business.address2, this.onValueChange);
    this.address3.setField(model.business.address3, this.onValueChange);
    this.address4.setField(model.business.address4, this.onValueChange);
    this.postCode.setField(model.business.postCode, this.onValueChange);
    this.state.setField(model.business.state, this.onValueChange);
    this.countryId.setField(model.business.countryId, this.onValueChange);
    this.businessTypeId.setField(
      model.business.businessTypeId,
      this.onValueChange
    );
    this.businessSectorId.setField(
      model.business.businessSectorId,
      this.onValueChange
    );
    this.registrationNumber.setField(
      model.business.registrationNumber,
      this.onValueChange
    );
    this.maleFemaleSplit.setField(
      model.business.maleFemaleSplit,
      this.onValueChange
    );
    this.numberOfDirectors.setField(
      model.business.numberOfDirectors,
      this.onValueChange
    );
    this.firstName.setField(model.businessUser.firstName, this.onValueChange);
    this.lastName.setField(model.businessUser.lastName, this.onValueChange);
    this.email.setField(model.businessUser.email, this.onValueChange);
    this.password.setField(model.businessUser.password, this.onValueChange);
    this.confirmPassword.setField(
      model.businessUser.confirmPassword,
      this.onValueChange
    );
    this.acceptTerms.setField(model.business.acceptTerms, this.onValueChange);
    this.companyType.setField(
      model.business.companyType,
      this.onCompanyTypeChange
    );
    this.registrationStepsLabels = model.business.registrationStepLabels;
    this.stepperButtonLabels = model.business.stepperButtonLabels;
    this.termsLink1=model.business.termsLink1;
    this.termsLink2=model.business.termsLink2;
    if (this.steps.length > 0 && this.registrationStepsLabels.length > 0) {
      this.steps[0].label = this.registrationStepsLabels[0];
      this.registerSteps[0].label = this.registrationStepsLabels[0];
    }
    if (this.steps.length > 1 && this.registrationStepsLabels.length > 1) {
      this.steps[1].label = this.registrationStepsLabels[1];
      this.registerSteps[1].label = this.registrationStepsLabels[1];
    }
    if (this.steps.length > 2 && this.registrationStepsLabels.length > 2) {
      this.steps[2].label = this.registrationStepsLabels[2];
      if (this.registerSteps.length > 2) {
        this.registerSteps[2].label = this.registrationStepsLabels[2];
      }
    }

    this.onSave = onSave;
    this.onCancel = onCancel;
    this.actions = model.business.actions.map((button) => {
      const wrapper = new FormButtonWrapper(this.actionHandle);
      wrapper.setButton(button, true);
      return wrapper;
    });
    this.cancelAction = new FormButtonWrapper(this.cancelHandle);
    this.cancelAction.setButton(model.business.cancelAction, true);
    

  };
  constructor(private coId: string) {
    this.companyId = coId;
    makeAutoObservable(this);
  }
}
