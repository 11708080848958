import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  CreateTenant,
  ITenantDto,
  TenantClient,
  UpdateTenant,
  UserAccountType,
} from "../../utils/api";
import { IEventType, IKeyedObject } from "../../core/types";
import { useState } from "react";
import { useAuth } from "../../core/firebase";
import AutoWidthBox from "../../controls/autoWidthBox";

interface ITenantProps {
  tenant: ITenantDto | null;
  entries: IKeyedObject<string>;
  createMode: boolean;
  language: string;
  onEditComplete: (refreshRequired: boolean) => void;
}

interface ITenantEdit {
  name: string;
  url: string;
  firstName: string;
  lastName: string;
  id: string;
  email: string;
  defaultUserId: string;
  accountType: UserAccountType;
  dbConnection: string;
  nameError: boolean;
  urlError: boolean;
  dbConnectionError: boolean;
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  logoError: boolean;
  titleError: boolean;
  subTitleError: boolean;
  imageError: boolean;
  language: string;
  isAdmin: boolean;
}

export const Tenant = (props: ITenantProps) => {
  const [error, setSaveError] = useState<string | undefined>(undefined);
  const { sendPasswordResetEmail } = useAuth();
  const [tenant, setTenant] = useState<ITenantEdit>({
    nameError: false,
    urlError: false,
    dbConnectionError: false,
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    firstName: "",
    lastName: "",
    email: "",
    id: props.tenant?.id ?? "",
    name: props.tenant?.name ?? "",
    url: props.tenant?.url ?? "",
    dbConnection: props.tenant?.dbConnection ?? "",
    defaultUserId: "",
    accountType: UserAccountType.TenantUser,
    language: props.language,
    isAdmin: false,
    logoError: false,
    titleError: false,
    subTitleError: false,
    imageError: false,
  });

  const handleChange = (e: IEventType) => {
    const { name, value } = e.target;
    setTenant((previous) => {
      if (name === "name") {
        return { ...previous, [name]: value ?? "", nameError: !value };
      } else if (name === "url") {
        return { ...previous, [name]: value ?? "", urlError: !value };
      } else if (name === "dbConnection") {
        return {
          ...previous,
          [name]: value ?? "",
          dbConnectionError: !value,
        };
      } else if (name === "firstName") {
        return {
          ...previous,
          [name]: value ?? "",
          firstNameError: !value,
        };
      } else if (name === "lastName") {
        return {
          ...previous,
          [name]: value ?? "",
          lastNameError: !value,
        };
      } else if (name === "email") {
        return {
          ...previous,
          [name]: value ?? "",
          emailError: !value,
        };
      }
      //  else if (name === "title") {
      //   return {
      //     ...previous,
      //     profile: {...previous.profile, [name]: value ?? "",},
      //     titleError: !value,
      //   };
      // }
      // else if (name === "subtitle") {
      //   return {
      //     ...previous,
      //     profile: {...previous.profile, [name]: value ?? "",},
      //     subTitleError: !value,
      //   };
      // } else if (name === "logo") {
      //   return {
      //     ...previous,
      //     profile: {...previous.profile, [name]: value ?? "",},
      //     logoError: !value,
      //   };
      // } else if (name === "image") {
      //   return {
      //     ...previous,
      //     profile: {...previous.profile, [name]: value ?? "",},
      //     imageError: !value,
      //   };
      // }
      return { ...previous };
    });
  };

  const createTenant = () => {
    const newTenant = new CreateTenant();

    newTenant.id = "";
    newTenant.name = tenant.name;
    newTenant.accountType = UserAccountType.TenantUser;
    newTenant.email = tenant.email;
    newTenant.firstName = tenant.firstName;
    newTenant.lastName = tenant.lastName;
    newTenant.url = tenant.url;
    newTenant.language = props.language;
    newTenant.isAdmin = false;
    newTenant.defaultUserId = "";
    const client = new TenantClient();

    client.createTenant(newTenant).then((result) => {
      if (result.succeeded) {
        sendPasswordResetEmail(newTenant.email);
        props.onEditComplete(true);
      } else {
        setSaveError(result.error);
      }
    });
  };

  const updateTenant = () => {
    const newTenant = new UpdateTenant();

    newTenant.id = tenant.id;
    newTenant.name = tenant.name;
    newTenant.url = tenant.url;
    newTenant.dbConnection = tenant.dbConnection;
    newTenant.language = props.language;
    const client = new TenantClient();
    client.updateTenant(newTenant).then((result) => {
      if (result.succeeded) {
        props.onEditComplete(true);
      } else {
        setSaveError(result.error);
      }
    });
  };

  const handleSave = () => {
    if (props.createMode) {
      createTenant();
    } else {
      updateTenant();
    }
  };

  const handleCancel = () => {
    props.onEditComplete(false);
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <AutoWidthBox>
        <Paper sx={{ p: 2, mt: 3 }} elevation={5}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {props.entries["tenant_detail_title"]}
          </Typography>
          <Box alignContent="center" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              error={tenant?.nameError ? true : false}
              helperText={
                tenant?.nameError ? props.entries["tenant_name_error"] : ""
              }
              required
              fullWidth
              name="name"
              label={props.entries["tenant_name_label"]}
              type="text"
              id="name"
              value={tenant?.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              error={tenant?.urlError ? true : false}
              helperText={
                tenant?.urlError ? props.entries["tenant_url_error"] : ""
              }
              required
              fullWidth
              name="url"
              label={props.entries["tenant_url_label"]}
              type="text"
              id="url"
              value={tenant?.url}
              onChange={handleChange}
            />
            {!props.createMode && (
              <TextField
                margin="normal"
                error={tenant?.dbConnectionError ? true : false}
                helperText={
                  tenant?.dbConnectionError
                    ? props.entries["tenant_db_error"]
                    : ""
                }
                required
                fullWidth
                name="dbConnection"
                label={props.entries["tenant_db_label"]}
                type="text"
                id="dbConnection"
                value={tenant?.dbConnection}
                onChange={handleChange}
              />
            )}
          </Box>
          {props.createMode && (
            <Paper sx={{ p: 2 }} elevation={3}>
              <Typography>{props.entries["tenant_user_label"]}</Typography>
              <TextField
                margin="normal"
                error={tenant?.firstNameError}
                helperText={
                  tenant?.firstNameError
                    ? props.entries["tenant_fname_error"]
                    : ""
                }
                required
                fullWidth
                name="firstName"
                label={props.entries["tenant_fname_label"]}
                type="text"
                id="firstName"
                value={tenant?.firstName}
                onChange={handleChange}
              />

              <TextField
                margin="normal"
                error={tenant?.lastNameError}
                helperText={
                  tenant?.lastNameError
                    ? props.entries["tenant_lname_error"]
                    : ""
                }
                required
                fullWidth
                name="lastName"
                label={props.entries["tenant_lname_label"]}
                type="text"
                id="lastName"
                value={tenant?.lastName}
                onChange={handleChange}
              />
              <TextField
                error={tenant?.emailError}
                helperText={
                  tenant?.emailError ? props.entries["tenant_email_error"] : ""
                }
                margin="normal"
                required
                fullWidth
                id="email"
                label={props.entries["tenant_email_label"]}
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={tenant?.email}
              />
            </Paper>
          )}
          {/* <Paper sx={{ p: 2,mt:3 }} elevation={3}>
              <Typography>{props.entries["tenant_landing_label"]}</Typography>
              <TextField
                margin="normal"
                error={tenant?.titleError}
                helperText={
                  tenant?.titleError
                    ? props.entries["tenant_title_error"]
                    : ""
                }
                required
                fullWidth
                name="title"
                label={props.entries["tenant_title_label"]}
                type="text"
                id="title"
                value={tenant?.profile.title}
                onChange={handleChange}
              />

              <TextField
                margin="normal"
                error={tenant?.subTitleError}
                helperText={
                  tenant?.subTitleError
                    ? props.entries["tenant_subtitle_error"]
                    : ""
                }
                required
                fullWidth
                name="subtitle"
                label={props.entries["tenant_subtitle_label"]}
                type="text"
                id="subtitle"
                value={tenant?.profile.subTitle}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                error={tenant?.logoError}
                helperText={
                  tenant?.logoError
                    ? props.entries["tenant_logo_error"]
                    : ""
                }
                required
                fullWidth
                name="logo"
                label={props.entries["tenant_logo_label"]}
                type="text"
                id="logo"
                value={tenant?.profile.logo}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                error={tenant?.imageError}
                helperText={
                  tenant?.imageError
                    ? props.entries["tenant_image_error"]
                    : ""
                }
                required
                fullWidth
                name="image"
                label={props.entries["tenant_image_label"]}
                type="text"
                id="image"
                value={tenant?.profile.image}
                onChange={handleChange}
              />              
              </Paper> */}
          <Box display="flex" sx={{ mt: 2 }}>
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}
              disabled={
                tenant?.dbConnectionError ||
                tenant?.nameError ||
                tenant?.urlError
              }
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ ml: 2 }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          {error && (
            <Typography
              component="div"
              variant="body2"
              color="error"
              sx={{ mt: 3, alignContent: "center" }}
            >
              {error}
            </Typography>
          )}
        </Paper>
      </AutoWidthBox>
    </Container>
  );
};
