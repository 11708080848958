import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { ReactNode } from "react";
import { observer } from "mobx-react";

export interface IComponentDialogProps {
  okDisabled: boolean;
  okLabel: string;
  cancelLabel: string;
  component: ReactNode;
  okVisible: boolean;
  cancelVisible: boolean;
  contentDisabled?: boolean;
}

export interface IDialog {
  title: string;
  description: string;
  isOpen: boolean;
  componentProps?: IComponentDialogProps;
  handleClose: (result: DialogResult, returnValue?: string | undefined) => void;
  isOkAllowed: boolean;
}

export enum DialogType {
  None,
  YesNo,
  Alert,
  Progress,
  ComponentDialog,
}

export enum DialogResult {
  None,
  Yes,
  No,
}

export const AlertDialog = (props: IDialog) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(DialogResult.None)} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const YesNoDialog = (props: IDialog) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.handleClose(DialogResult.No)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(DialogResult.Yes)} autoFocus>
          Yes
        </Button>
        <Button onClick={() => props.handleClose(DialogResult.No)} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface IProgressDialog {
  title: string;
  isOpen: boolean;
}
export const ProgressDialog = (props: IProgressDialog) => {
  const onClose = () => {};
  return (
    <Dialog
      open={props.isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100px",
            minWidth: "200px",
          }}
        >
          <CircularProgress />
        </div>
        <Typography align="center" variant="body2">
          {props.title}
        </Typography>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export const ComponentDialog = observer((props: IDialog) => {
  if (props.componentProps) {
    return (
      <Dialog
        open={props.isOpen}
        onClose={() => props.handleClose(DialogResult.None, undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              pointerEvents: props.componentProps.contentDisabled
                ? "none"
                : "inherit",
            }}
          >
            {props.componentProps.component}
          </Box>
        </DialogContent>
        <DialogActions>
          {props.componentProps.cancelVisible && (
            <Button onClick={() => props.handleClose(DialogResult.No)}>
              {props.componentProps.cancelLabel}
            </Button>
          )}
          {props.componentProps.okVisible && (
            <Button
              onClick={() => props.handleClose(DialogResult.Yes)}
              autoFocus
              disabled={!props.isOkAllowed}
            >
              {props.componentProps.okLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
  return null;
});
