import { observer } from "mobx-react";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { ActionButton } from "../../../controls/actionButton";
import { FieldWrapperControl } from "../../../controls/fieldWrapperControl";
import { useState } from "react";
import { IQuestionViewProps } from "./questionView";
import { QuestionMessageView } from "./QuestionMessageView";

export const QuestionFieldView = observer((props: IQuestionViewProps) => {
  
  console.log("Approver notes", props.viewModel?.notesToApproverField.isVisible);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  console.log("In edit mode", props.viewModel?.question.inEdit);
  const handleInfoClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (props.viewModel) {
    const open = Boolean(anchorEl);
    const hasInfo = (props.viewModel?.question.targets.length ?? 0) > 0;
    return (
      <Box flexGrow="1" marginRight="20px">
        <Box
          display="flex"
          flexDirection="row"
          marginTop="10px"
          flexGrow="1"
          alignSelf="end"
          alignItems="center"
        >
          <Typography variant="h6">
            {props.viewModel?.question.label} {props.viewModel?.question.number}{" "}
          </Typography>
          <ActionButton
            action={props.viewModel?.question.flagAction}
            overrideColor={props.viewModel?.isFlagged ? "error" : undefined}
            onClick={props.viewModel.handleIsFlaggedClick}
          />
          <ActionButton
            action={props.viewModel?.question.listAction}
            onClick={props.viewModel.handleQuestionListClick}
          />
          {hasInfo && (
            <>
              <IconButton
                onClick={handleInfoClick}
                aria-describedby="info-popup"
              >
                <Icon>{props.viewModel?.question.targetInfoAction.icon}</Icon>
              </IconButton>
              <Popover
                id="info-popup"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <ul>
                  {props.viewModel.question.targets &&
                    props.viewModel.question.targets.map((x, index) => (
                      <li key={index}>{x}</li>
                    ))}
                </ul>
              </Popover>
            </>
          )}
        </Box>

        <Typography variant="h6">
          {props.viewModel?.question.questionText}
        </Typography>
        <Grid
          container
          sx={{ display: "flex", flexDirection: "row" }}
          spacing={1}
        >
          {/* if Notes to approver is visible, then it will have 6 cols */}
          
          <Grid item xs={12} md= {props.viewModel?.notesToApproverField.isVisible ? 6 : 9}>
            <FieldWrapperControl {...props.viewModel?.questionField} />
            <FieldWrapperControl {...props?.viewModel?.additionalInfoField} />
            <FieldWrapperControl {...props?.viewModel?.evidence} />
            {props.viewModel.question.hint && (
              <Box display="flex" justifyContent="flex-end" width="100%">
                <Typography variant="body2">
                  ({props.viewModel.question.hint})
                </Typography>
              </Box>
            )}
          </Grid>

            {props.viewModel.question.approverEdit && (
              <Grid item xs={12} sm={12} md={3} >
              {props.viewModel.question.approverEdit}
              <FieldWrapperControl {...props.viewModel?.notesToApproverField} />
              <FieldWrapperControl {...props.viewModel?.ratingField} />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={3} >
{/*           <Box
            width="250px"
            margin={"5px"}
            display="flex"
            flexDirection="column"
          > */}
            <Box flexGrow={1} marginTop={"15px"}>
              <QuestionMessageView messages={props.viewModel.messages} />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <FieldWrapperControl {...props.viewModel?.commentField} />
              <ActionButton
                action={props.viewModel?.question.commentAction}
                onClick={props.viewModel.handleMessageSend}
                overrideColor="secondary"
              />
            </Box>
{/*           </Box> */}
          </Grid>
        </Grid>
      </Box>
    );
  }
  return null;
});
