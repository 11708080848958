import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LanguageContext } from "../../core/language";
import { ResponsiveTable } from "../../controls/responsiveTable";
import { BasePage } from "../../controls/basePage";
import { SettingsMenuListViewModel } from "./settingsListViewModel";

export const SettingsMenuList = observer(() => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<
    SettingsMenuListViewModel | undefined
  >(undefined);
  useEffect(() => {
    setViewModel(new SettingsMenuListViewModel(selectedLanguage));
  }, [selectedLanguage]);
  if (viewModel) {
    return <SettingsMenuListView viewModel={viewModel} />;
  }
  return null;
});
interface ISettingsMenuListViewProps {
  viewModel: SettingsMenuListViewModel;
}
const SettingsMenuListView = observer((props: ISettingsMenuListViewProps) => {
  return (
    <BasePage pageDetails={props.viewModel}>
      <ResponsiveTable
        showSearch={false}
        header={props.viewModel.model?.settingsMenu.listTitle}
        noDataOverlay={props.viewModel.model?.settingsMenu.noDataOverlay}
        headers={props.viewModel.model?.settingsMenu.columnHeaders}
        rowData={props.viewModel.model?.settingsMenu.rowData}
      />
    </BasePage>
  );
});
