import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import { makeAutoObservable, runInAction } from "mobx";
import {
  DialogViewModel,
  IDialogViewModel,
} from "../../common/dialogViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import { MSMESelectorViewModel } from "./msme-selector/msmeSelectorViewModel";
import { NavigateFunction } from "react-router-dom";
import {
  CertificationClient,
  IBaseScreenDto,
  ResultOfCertificationManagementDto,
} from "../../utils/api";
import { ROUTES } from "../../core/constants";

interface ICertificationManagerViewModel extends IBasePageViewModel {
  msmeSelectorViewModel: MSMESelectorViewModel;
}

export class CertificationManagerViewModel
  implements ICertificationManagerViewModel
{
  msmeSelectorViewModel: MSMESelectorViewModel;
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();

  constructor(
    private selectedLanguage: string,
    private navigate: NavigateFunction
  ) {
    this.msmeSelectorViewModel = new MSMESelectorViewModel(
      this.selectedLanguage,
      this.handleMsmeSelection
    );
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  handleMsmeSelection = (selectedMsme: string) => {
    this.navigate(ROUTES.MSMECERTIFICATION.replace(":id", selectedMsme));
  };

  setModel = (result: ResultOfCertificationManagementDto) => {
    if (result.succeeded) {
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        undefined
      );
    }
  };

  fetchData = () => {
    const client = new CertificationClient();
    client
      .getCertificationManagementModel(this.selectedLanguage)
      .then((result) => this.setModel(result))
      .catch((reason) => console.log("COuld not fetch certification model"));
  };
}
