import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./core/constants";
import "./App.css";
import { ProtectedRoute } from "./core/protectedRoute";
import { ErrorPage } from "./layouts/error";
import { Landing } from "./pages/landing/landing";
import { LandingTNC } from "./pages/landing/landingTNC";
import { LandingPrivacy } from "./pages/landing/landingPrivacy";
import { DashboardList } from "./pages/dashboard/dashboard";
import { Login } from "./pages/login/login";
import { ForgotPassword } from "./pages/login/forgotPassword";
import { Badges } from "./pages/badge-management/badges";
import { SDGList } from "./pages/sdg-managment/sdgmain";
import { TenantPage } from "./pages/tenant-management/tenantPage";
import { TenantProfile } from "./pages/tenantprofile-management/tenantprofileView";

import { SettingsMenuList } from "./pages/settings/settingsmain";
import { UserManagement } from "./pages/user-management/userManagement";
import Layout from "./layouts/layout";
import { SDGEdit } from "./pages/sdg-managment/sdgEdit";
import { CertificationManager } from "./pages/certifications/certification-manager";
import { Questionnaire } from "./pages/certifications/questionnaire/questionnaire";
import { BusinessList } from "./pages/msme-management/msmemain";
import { BusinessEdit } from "./pages/msme-management/msmeEdit";
import { RegisterBusiness } from "./pages/login/registerUser/register";
import { AgencyEdit } from "./pages/msme-management/agencyEdit";
import { MSMECertificationManager } from "./pages/certifications/msme-certification-manager";
import { SDGQuestions } from "./pages/sdg-questions/sdgQuestions";

function App() {
  return (
    <Layout>
      <Routes>
        <Route errorElement={<ErrorPage />}>
          <Route index path={ROUTES.LANDING} element={<Landing />} />
          <Route path={ROUTES.SIGN_IN} element={<Login />} />
          <Route path={ROUTES.PASSWORD_FORGOT} element={<ForgotPassword />} />
          <Route path={ROUTES.TNC} element={<LandingTNC />} />
          <Route path={ROUTES.PRIVACY} element={<LandingPrivacy />} />
          <Route
            path={ROUTES.SIGN_UP}
            element={<RegisterBusiness companyType={0} />}
          />
          <Route element={<ProtectedRoute />}>
            <Route path={ROUTES.DASHBOARD} element={<DashboardList />} />
            <Route path={ROUTES.USERS} element={<UserManagement />} />
            <Route path={ROUTES.BADGES} element={<Badges />} />
            <Route path={ROUTES.SDGS} element={<SDGList />} />
            <Route
              path={ROUTES.MSMES}
              element={<BusinessList companyType={0} />}
            />
            <Route
              path={ROUTES.MSME_EDIT}
              element={<BusinessEdit companyType={0} />}
            />
            <Route
              path={ROUTES.AGENCIES}
              element={<BusinessList companyType={1} />}
            />
            <Route
              path={ROUTES.AGENCY_EDIT}
              element={<AgencyEdit companyType={1} />}
            />
            <Route
              path={ROUTES.CERTIFICATIONS}
              element={<CertificationManager />}
            />
            <Route
              path={ROUTES.MSMECERTIFICATION}
              element={<MSMECertificationManager />}
            />
            <Route path={ROUTES.QUESTIONNAIRE} element={<Questionnaire />} />
            <Route path={ROUTES.SDGQUESTIONS} element={<SDGQuestions />} />
            <Route
              path={ROUTES.SDGQUESTIONS_APPORVE}
              element={<SDGQuestions />}
            />
            <Route
              path={ROUTES.SDGS_EDIT}
              element={<SDGEdit isApprove={false} />}
            />
            <Route
              path={ROUTES.SDGS_APPROVE}
              element={<SDGEdit isApprove={true} />}
            />
            <Route path={ROUTES.TENANTS} element={<TenantPage />} />
            <Route path={ROUTES.TENANTPROFILE} element={<TenantProfile />} />
            <Route path={ROUTES.SETTINGS} element={<SettingsMenuList />} />
          </Route>
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
