import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { SDGListViewModel } from "./sdgListViewModel";
import { LanguageContext } from "../../core/language";
import { ResponsiveTable } from "../../controls/responsiveTable";
import { BasePage } from "../../controls/basePage";

export const SDGList = observer(() => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<SDGListViewModel | undefined>(
    undefined
  );

  useEffect(() => {
    setViewModel(new SDGListViewModel(selectedLanguage));
  }, [selectedLanguage]);

  if (viewModel) {
    return <SDGlistView viewModel={viewModel} />;
  }

  return null;
});

interface ISDGListViewProps {
  viewModel: SDGListViewModel;
}

const SDGlistView = observer((props: ISDGListViewProps) => {
  console.log(props);
  return (
    <BasePage pageDetails={props.viewModel}>
      <ResponsiveTable
        header={props.viewModel.model?.goals.listTitle}
        noDataOverlay={props.viewModel.model?.goals.noDataOverlay}
        headers={props.viewModel.model?.goals.columnHeaders}
        rowData={props.viewModel.model?.goals.rowData}
      />
    </BasePage>
  );
});
