import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../core/language";
import {
  BusinessEditFormModel,
  BusinessEditViewModel,
} from "./msmeEditViewModel";
import { useNavigate, useParams } from "react-router-dom";
import { BasePage } from "../../controls/basePage";
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  FieldWrapperControl,
  FormButtonSection,
} from "../../controls/fieldWrapperControl";
import { IBasePageViewModel } from "../../common/pageTitleViewModel";
import { ICompanyType } from "./companyType";
import { buttonWidth } from "../../core/constants";
import { SignedInUserContext } from "../../core/signedInUser";
import { UserAccountType } from "../../utils/api";

export const BusinessEdit = observer((props: ICompanyType) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<BusinessEditViewModel | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const { signedInUser } = useContext(SignedInUserContext);
  const { id } = useParams();
  const [isFromSettings] = useState<boolean>(
    signedInUser?.userType == UserAccountType.MsmeUser
  );
  useEffect(() => {
    setViewModel(
      new BusinessEditViewModel(
        selectedLanguage,
        id ?? "new",
        props.companyType,
        isFromSettings,
        navigate
      )
    );
  }, [selectedLanguage, id, props.companyType, isFromSettings, navigate]);
  if (viewModel) {
    return <BusinessEditView viewModel={viewModel} companyId={id} />;
  }
  return null;
});

interface IBusinessEditViewProps {
  viewModel: BusinessEditViewModel;
  companyId: string | undefined;
}
const BusinessEditView = observer((props: IBusinessEditViewProps) => {
  return (
    <BasePage pageDetails={props.viewModel as IBasePageViewModel}>
      <BusinessEditForm
        model={props.viewModel.model}
        companyId={props.companyId}
      ></BusinessEditForm>
    </BasePage>
  );
});
const BusinessEditForm = observer(
  (props: {
    model: BusinessEditFormModel | undefined;
    companyId: string | undefined;
  }) => {
    if (!props.model) {
      return null;
    }

    const ActiveStepComponent =
      props.model.steps[props.model.activeStep].component;
    const handleNext = () => {
      //setActiveStep((prevStep) => prevStep + 1);
      if (props.model) {
        props.model.activeStep = props.model?.activeStep + 1;
      }
    };

    const handleBack = () => {
      //setActiveStep((prevStep) => prevStep - 1);
      if (props.model) {
        props.model.activeStep = props.model?.activeStep - 1;
      }
    };
    return (
      <Paper sx={{ background: "transparent" }} elevation={0}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {props.model.title}
        </Typography>
        <Stepper sx={{ width: "100%" }} activeStep={props.model.activeStep}>
          {props.model.registerSteps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ width: "100%" }}>
          <ActiveStepComponent model={props?.model} />
        </Box>
        {props.companyId === "new" &&
          props.model.activeStep === props.model.registerSteps.length - 1 && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <FieldWrapperControl {...props.model.acceptTerms} />
              <span>{props.model.termsLink1}</span>
              <Link
                href="https://ivetfilestore.blob.core.windows.net/ivetstorepublic/InclusiveTrade/TermsAndConditions.html"
                target="_blank"
              >
                &nbsp;{props.model.termsLink2}&nbsp;
              </Link>
            </Box>
          )}

        <Grid container sx={{ display: "flex", flexDirection: "row" }}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flex: "1",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                disabled={props.model.activeStep === 0}
                color="primary"
                onClick={handleBack}
                variant="contained"
                sx={{ width: `calc(${buttonWidth}px)` }}
              >
                {props.model.stepperButtonLabels[1]}
              </Button>

              <Button
                disabled={
                  props.model.activeStep ===
                  props.model.registerSteps.length - 1
                }
                color="primary"
                onClick={handleNext}
                variant="contained"
                sx={{ width: `calc(${buttonWidth}px)` }}
              >
                {props.model.stepperButtonLabels[0]}
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <FormButtonSection
              actions={props.model.actions}
              cancelAction={props.model.cancelAction}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
