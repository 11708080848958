import React, { useContext, useEffect, useState } from "react";
import { IHigherContextProviderProps } from "./firebase";
import { SignedInUserContext } from "./signedInUser";

export interface IMSMESelectionContext {
  selectedMSME: { id: string; name: string } | undefined;
  selectMSME: (msme: { id: string; name: string }) => void;
  clearMSME: () => void;
}

export const MSMESelectionContext = React.createContext<IMSMESelectionContext>({
  selectedMSME: undefined,
  selectMSME: (msme: { id: string; name: string }) => {},
  clearMSME: () => {},
});

export const MSMEContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const { signedInUser } = useContext(SignedInUserContext);
  const [selectedMSME, setSelectedMsme] = useState<
    { id: string; name: string } | undefined
  >(undefined);

  useEffect(() => {
    if (signedInUser) {
      // const client = new TenantUserClient();
      // client.getUserFavorites(selectedLanguage).then((result) => {
      //   if (result.succeeded) {
      //     setFavoritesDto(result.value ?? defaultFavorites);
      //   }
      // });
    }
  }, [signedInUser]);

  const selectMSME = (msme: { id: string; name: string }) => {
    setSelectedMsme(msme);
  };

  const clearMSME = () => {
    setSelectedMsme(undefined);
  };
  return (
    <MSMESelectionContext.Provider
      value={{ selectedMSME, selectMSME, clearMSME }}
    >
      {children}
    </MSMESelectionContext.Provider>
  );
};
