import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../core/language";
import {
  QuestionSectionViewModel,
  QuestionViewModel,
  SDGQuestionsViewModel,
} from "./sdgQuestionsViewModel";
import { useParams } from "react-router-dom";
import { BasePage } from "../../controls/basePage";
import { IBasePageViewModel } from "../../common/pageTitleViewModel";
import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import { ActionButton } from "../../controls/actionButton";
import { FieldWrapper } from "../../common/formTypes";
import { FieldWrapperControl } from "../../controls/fieldWrapperControl";

export const SDGQuestions = observer(() => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<SDGQuestionsViewModel | undefined>(
    undefined
  );
  const { id, approve } = useParams();

  const sdgId = Number(id);
  const isApprove = (approve ?? "0") == "1" ? true : false;
  useEffect(() => {
    if (selectedLanguage && id) {
      setViewModel(
        new SDGQuestionsViewModel(selectedLanguage, sdgId, isApprove)
      );
    }
  }, [selectedLanguage, id, isApprove]);

  if (viewModel) {
    return <SDGQuestionsView viewModel={viewModel} />;
  } else {
    return null;
  }
});

interface ISDGQuestionsViewProps {
  viewModel: SDGQuestionsViewModel;
}

const SDGQuestionsView = observer((props: ISDGQuestionsViewProps) => {
  if (props.viewModel) {
    return (
      <BasePage pageDetails={props.viewModel as IBasePageViewModel}>
        <SDGQuestionsForm viewModel={props.viewModel} />
      </BasePage>
    );
  }
  return null;
});

const SDGQuestionsForm = observer(
  (props: { viewModel: SDGQuestionsViewModel }) => {
    const theme = useTheme();
    return (
      <>
        <Box borderColor={theme.palette.primary.light}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="30px"
            padding="4px"
          >
            <Typography flexGrow="1" variant="subtitle1">
              Sections
            </Typography>
            <ActionButton
              action={props.viewModel.addSectionAction}
              onClick={() => props.viewModel.handleAddSection()}
            />
          </Box>
          <Box padding="4px">
            {props.viewModel?.sections.map((section, index) => {
              return <SDGQuestionSection section={section} key={index} />;
            })}
          </Box>
        </Box>
      </>
    );
  }
);

const SDGQuestionSection = observer(
  (props: { section: QuestionSectionViewModel }) => {
    const theme = useTheme();
    return (
      <Box border="1px solid" borderColor={theme.palette.primary.light}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          height="30px"
          padding="4px"
          bgcolor={theme.palette.primary.light}
        >
          <Typography flexGrow="1" variant="body1">
            {props.section.section.name.value}
            {props.section.section.isDraft && (
              <Chip
                sx={{ ml: "5px" }}
                label="Amended"
                variant="outlined"
                size="small"
              />
            )}
            {props.section.section.isDeleted && (
              <Chip
                sx={{ ml: "5px" }}
                label="Deleted"
                variant="outlined"
                size="small"
              />
            )}
          </Typography>
          <ActionButton
            action={props.section.section.editAction}
            onClick={() => props.section.handleEditAction()}
          />
          <ActionButton
            action={props.section.section.deleteAction}
            onClick={() => props.section.handleDeleteAction()}
          />
          <ActionButton
            action={props.section.section.addQuestionAction}
            onClick={() => props.section.handleAddQuestionAction()}
          />
          <ActionButton
            action={props.section.section.approveAction}
            onClick={() => props.section.handleReviewSection()}
          />
        </Box>
        <Box>
          {props.section.questions.map((x, index) => (
            <SDGQuestion question={x} key={index} />
          ))}
        </Box>
      </Box>
    );
  }
);
const SDGQuestion = (props: { question: QuestionViewModel }) => {
  const theme = useTheme();
  return (
    <Box borderBottom="1px solid" borderColor={theme.palette.primary.light}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height="30px"
        padding="4px"
      >
        <Typography flexGrow="1" variant="body1">
          {props.question.question.questionText.value}
          {props.question.question.isDraft && (
            <Chip
              sx={{ ml: "5px" }}
              label="Amended"
              variant="outlined"
              size="small"
            />
          )}
          {props.question.question.isDeleted && (
            <Chip
              sx={{ ml: "5px" }}
              label="Deleted"
              variant="outlined"
              size="small"
            />
          )}
        </Typography>
        <ActionButton
          action={props.question.question.editAction}
          onClick={() => props.question.handleEditAction()}
        />
        <ActionButton
          action={props.question.question.deleteAction}
          onClick={() => props.question.handleDeleteAction()}
        />
        <ActionButton
          action={props.question.question.approveAction}
          onClick={() => props.question.handleReviewQuestion()}
        />
      </Box>
    </Box>
  );
};
export interface ISectionEditProps {
  id: string;
  sectionName: FieldWrapper;
  rowVersion: string;
}
export const SDGSectionEdit = observer((props: ISectionEditProps) => {
  return <FieldWrapperControl {...props?.sectionName} />;
});

export interface IQuestionEditProps {
  editModel: QuestionViewModel;
}
export const SDGQuestionEdit = observer((props: IQuestionEditProps) => {
  return (
    <Box>
      <FieldWrapperControl {...props?.editModel.quesionText} />
      <FieldWrapperControl {...props?.editModel.description} />
      <FieldWrapperControl {...props?.editModel.questionType} />
      <FieldWrapperControl {...props?.editModel.choices} />
      <FieldWrapperControl {...props?.editModel.choicePoints} />
      <FieldWrapperControl {...props?.editModel.hint} />
      <FieldWrapperControl {...props?.editModel.requiresUpload} />
      <FieldWrapperControl {...props?.editModel.notesToApprover} />
      <FieldWrapperControl {...props?.editModel.targets} />
    </Box>
  );
});
