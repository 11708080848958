import React, { ReactNode } from "react";
import { Box, Container } from "@mui/material";

interface AutoWidthBoxProps {
  children: ReactNode;
  widthOverride?: "lg" | "md" | "xl" | "xs";
  paddingOverride?:string;
}

const AutoWidthBox: React.FC<AutoWidthBoxProps> = ({ children, ...props }) => {
  return (
    <Container maxWidth={props.widthOverride ? props.widthOverride : "md"} sx={{mt:props.paddingOverride? props.paddingOverride:"8px"}} >
      <Box width="100%">{children}</Box>
    </Container>
  );
};

export default AutoWidthBox;
