import { useContext, useMemo } from "react";
import { LanguageContext } from "../../core/language";
import { SignedInUserContext } from "../../core/signedInUser";
import { IUserManagementVM, UserManagementViewModel } from "./usersViewModel";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { ActivityToolBar } from "../../controls/activityToolbar";
import { PageMode } from "../../core/types";
import { UserListView } from "./userListView";
import { UserView } from "./userView";
import { FirebaseContext } from "../../core/firebase";

export interface IUserManagementViewProps {
  viewModel: IUserManagementVM;
}

export const UserManagement = observer(() => {
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const { signedInUser } = useContext(SignedInUserContext);
  const { sendPasswordResetEmail } = useContext(FirebaseContext);
  const viewModel: IUserManagementVM = useMemo(() => {
    return new UserManagementViewModel(
      selectedLanguage,
      translate,
      signedInUser,
      sendPasswordResetEmail
    );
  }, [selectedLanguage, translate, signedInUser, sendPasswordResetEmail]);

  return <UserManagementView viewModel={viewModel} />;
});

const UserManagementView = observer(
  ({ viewModel }: IUserManagementViewProps) => {
    console.log("user management view", viewModel);
    return (
      <Box>
        <ActivityToolBar
          callback={viewModel.onActivityCallback}
          actions={viewModel.activities}
          title={viewModel.pageEntries["usermng_activity_title"]}
        />
        {viewModel.pageMode === PageMode.List && (
          <UserListView viewModel={viewModel} />
        )}
        {viewModel.pageMode === PageMode.Custom && (
          <UserListView viewModel={viewModel} />
        )}
        {(viewModel.pageMode === PageMode.Add ||
          viewModel.pageMode === PageMode.Edit) && (
          <UserView viewModel={viewModel} />
        )}
      </Box>
    );
  }
);
