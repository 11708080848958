import { observer } from "mobx-react";
import { QuestionMessage, UserMessage } from "../../../utils/api";
import { Box, Typography, useTheme } from "@mui/material";

export const QuestionMessageView = observer(
  (props: { messages: UserMessage[] }) => {
    const theme = useTheme();
    console.log("Messages", props.messages);
    
    const getUserMessages = (
      messages: QuestionMessage[],
      rightAligned: boolean
    ) => {
      return (
        <Box>
          {messages.map((msg) => {
            return (
              <Box sx={{ marginBottom: "2px",
                borderRadius: "4px",
                background: (rightAligned==true? `${theme.palette.primary.main}`: `${theme.palette.primary.light}`),
                color: (rightAligned==true? theme.palette.common.white :  theme.palette.text.primary),
               }}>
                <Typography variant = "caption" >{msg.message}</Typography>
                <Box
                  sx={{
                    textWrap: "nowrap",
                  }}
                >
                  <Typography variant = "smallfont" >{msg.time}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    };
    return (
      <Box
        height="100%"
        width="100%"
        maxHeight="400px"
        border="1px solid"
        overflow="auto"
        borderColor={theme.palette.primary.light}
      >
        {props.messages.map((msg) => {
          if (msg.rightAligned) {
            return (
              <Box
                sx={{
                  borderRadius: "4px",
                  marginTop: "2px",
                  padding: "2px",
                  marginLeft: "25px",
                  marginRight: "5px",
                  textAlign: "right",
                }}
              >
                <Typography variant="caption">{msg.user}</Typography>
                {getUserMessages(msg.messages, msg.rightAligned)}
              </Box>
            );
          }
          return (
            <Box
              sx={{
                borderRadius: "4px",
                marginTop: "2px",
                padding: "2px",
                marginLeft: "5px",
                marginRight: "25px",
                
              }}
            >
              <Typography variant="caption">{msg.user}</Typography>
              {/* {getUserMessages(msg.messages, msg.rightAligned)} */}
              {getUserMessages(msg.messages, false)}
            </Box>
          );
        })}
      </Box>
    );
  }
);
