import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { AppBar } from "./appBar";
import { SettingsMenu } from "./settingsMenu";
import { MainMenu } from "./mainMenu";
import SideBar from "../controls/sideBar";
import { useMediaQuery } from "@mui/material";


//const drawerWidth = 240;
import { desktopWidth, drawerWidth } from "../core/constants";

var isDesktop = true; //setting up default value

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// const closedMixin = (theme: Theme): CSSObject => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

const closedMixin = (theme: Theme): CSSObject => ({

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing((isDesktop?7:0))} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(isDesktop?8:0)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function NavBar() {
  //isDesktop = useMediaQuery("(min-width:960px)");
  
  isDesktop = useMediaQuery("(min-width:" + desktopWidth + "px)");

  const [open, setOpen] = React.useState(false);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = React.useState<
    Element | undefined
  >(undefined);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSettingsMenuClick = (event: any) => {
    setSettingsMenuAnchor(event.currentTarget);
  };
  const handleSettingMenuClose = () => {
    setSettingsMenuAnchor(undefined);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <MainMenu
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          onSettingsMenuClick={onSettingsMenuClick}
        />
      </AppBar>
      <SettingsMenu
        anchorElement={settingsMenuAnchor}
        handleMenuClose={handleSettingMenuClose}
      />
      <SideBar open={open} onDrawerClose={handleDrawerClose} />
    </Box>
  );
 
}
