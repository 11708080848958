import { observer } from "mobx-react";
import { QuestionViewModel } from "./QuestionViewModel";
import { Box } from "@mui/material";
import { QuestionSDGView } from "./QuestionSDGView";
import { QuestionFieldView } from "./QuestionFieldView";


export interface IQuestionViewProps {
  viewModel: QuestionViewModel | undefined;
  listAction?: () => void;
}

export const QuestionView = observer((props: IQuestionViewProps) => {
  return (
    <Box display="flex" flexGrow="1" flexDirection="column" padding="10px">
      <QuestionSDGView viewModel={props.viewModel} />
      <Box display="flex" flexDirection="row">
        <QuestionFieldView
          viewModel={props.viewModel}
          listAction={props.listAction}
        />
      </Box>
    </Box>
  );
});
