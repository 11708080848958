import {
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Skeleton,
  useTheme,
} from "@mui/material";
import { ROUTES, buttonWidth, mobileWidth } from "../../core/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../core/activityContext";
import { observer } from "mobx-react";
import { HomepageDto } from "../../utils/api";
import { LandingViewModel } from "./landingViewModel";
import { LanguageContext } from "../../core/language";
import { useAuth } from "../../core/firebase";
export const Landing = observer(() => {
  const { setBarVisibilityForPage: setBarVisibility } =
    useContext(ActivityContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<LandingViewModel | undefined>(
    undefined
  );
  const { authToken, signIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (authToken) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [authToken, navigate]);

  //const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isSmallScreen = useMediaQuery("(min-width:" + mobileWidth + "px)");
  const isAdmin = window.location.hostname.startsWith("admin");

  useEffect(() => {
    setBarVisibility(false);
    return () => setBarVisibility(true);
  });

  useEffect(() => {
    //if (selectedLanguage && !viewModel) {
    setViewModel(new LandingViewModel(selectedLanguage));
    //}
  }, [selectedLanguage]);

  if (viewModel) {
    return (
      <HomepageView
        model={viewModel.model}
        isAdmin={isAdmin}
        isSmallScreen={isSmallScreen}
      />
    );
  }
  return null;
  /*
  return (
    <Grid container spacing={2} sx={{ marginTop: { xs: "1px", sm: "55px" } }}>
      <Grid item xs={12} sm={6}>
        {isSmallScreen ? (
          <SmallCompanyDetails isAdmin={isAdmin} />
        ) : (
          <LargeCompanyDetails isAdmin={isAdmin} />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              position: "relative",
              top: 0,
              left: 0,
            }}
          >
            {!isAdmin && (
              <Box
                component="img"
                sx={{
                  position: "absolute",
                  top: 30,
                  left: 30,
                  width: "90%",
                  height: "auto",
                  maxHeight: { xs: "45vh", sm: "80vh" },
                }}
                src="mainimageBack.png"
              />
            )}
            <Box
              component="img"
              sx={{
                position: "relative",
                top: 0,
                left: 0,
                width: "90%",
                height: "auto",
                maxHeight: { xs: "45vh", sm: "80vh" },
              }}
              src={isAdmin ? "imageAdmin.png" : "mainimage.png"}
            />
          </Box>
        </Box>
      </Grid>
      {isSmallScreen && (
        <Grid item xs={12} sm={6}>
          <Box>{isSmallScreen && <LoginComponent />}</Box>
        </Grid>
      )}
    </Grid>
  );
  */
});
const HomepageView = observer(
  (props: {
    model: HomepageDto | undefined;
    isSmallScreen: boolean;
    isAdmin: boolean;
  }) => {
    if (!props.model) {
      return null;
    }

    const logoSource: string = props.model.logoImage;
    const theme = useTheme();
    /*     return (
      <Grid container spacing={2} sx={{ marginTop: { xs: "1px", sm: "55px" } }}>
        <Grid item xs={12} sm={6}>
          {props.isSmallScreen ? (
            <SmallCompanyDetails isAdmin={props.isAdmin} model={props.model} />
          ) : (
            <LargeCompanyDetails isAdmin={props.isAdmin} model={props.model} />
          )}

        </Grid>

        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box
              sx={{
                position: "relative",
                top: 0,
                left: 0,
              }}
            >
              {!props.isAdmin && (
                <Box
                  component="img"
                  sx={{
                    position: "absolute",
                    top: 30,
                    left: 30,
                    width: "90%",
                    height: "auto",
                    maxHeight: { xs: "45vh", sm: "80vh" },
                  }}
                  src="mainimageBack.png"
                />
              )}

                {props.model.homepageImage && (
                <Box
                  component="img"
                  sx={{
                    position: "relative",
                    top: 0,
                    left: 0,
                    width: "90%",
                    height: "auto",
                    maxHeight: { xs: "45vh", sm: "80vh" },
                  }}
                  src={props.model.homepageImage}
                />
              )}

            </Box>
          </Box>
        </Grid>
        {props.isSmallScreen && (
          <Grid item xs={12} sm={6}>
            <Box>{props.isSmallScreen && <LoginComponent />}</Box>
          </Grid>
        )}
      </Grid>
    ); */

    return (
      <Grid
        container
        spacing={2}
        sx={{ p: 2, marginTop: { xs: "1px", sm: "55px" } }}
      >
        <Grid item xs={12}>
          {logoSource && (
            <Box
              component="img"
              sx={{
                width: { xs: "50px", sm: "100px" },
                height: { xs: "50px", sm: "100px" },
              }}
              src={logoSource}
            />
          )}
          {!logoSource && (
            <Skeleton
              variant="rectangular"
              sx={{
                width: { xs: "50px", sm: "100px" },
                height: { xs: "50px", sm: "100px" },
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={5}>
          <LargeCompanyDetails
            isAdmin={props.isAdmin}
            model={props.model}
            isSmallScreen={props.isSmallScreen}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          {/*               {!props.isAdmin && (
                <Box
                  component="img"
                  sx={{
                    position: "absolute",
                    top: 30,
                    left: 30,
                    width: "90%",
                    height: "auto",
                    maxHeight: { xs: "45vh", sm: "80vh" },
                  }}
                  src="mainimageBack.png"
                />
              )} */}

          {props.model.homepageImage && (
            <Box
              component="img"
              sx={{
                boxShadow: "10px 10px " + theme.palette.primary.light,
                width: "100%",
                maxHeight: { xs: "40vh", sm: "60vh" },
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={props.model.homepageImage}
            />
          )}
          {!props.model.homepageImage && (
            <Skeleton
              variant="rectangular"
              sx={{
                boxShadow: "10px 10px " + theme.palette.primary.light,
                width: "100%",
                minHeight: { xs: "40vh", sm: "60vh" },
              }}
            />
          )}
        </Grid>
        <Grid item xs={0} sm={3}></Grid>
        <Grid item xs={12}>
          <LoginComponent model={props.model} />
        </Grid>
      </Grid>
    );
  }
);
/* const SmallCompanyDetails = (props: {
  isAdmin: boolean;
  model: HomepageDto;
}) => {
  const logoSource: string = props.model.logoImage; 
  const title: string = props.model.title;
  const subTitle: string = props.model.subTitle; 
  return (
    <Box
      sx={{
        paddingLeft: {
          xs: "0px",
          sm: "75px",
          display: "flex",
          flexDirection: "row",
        },
      }}
    >
      {logoSource && (
        <Box
          component="img"
          sx={{
            width: { xs: "50px", sm: "100px" },
            height: { xs: "50px", sm: "100px" },
          }}
          src={logoSource}
        />
      )}
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "120px" },
            fontFamily: "Calibri, sans-serif",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "60px" },
            fontFamily: "Calibri, sans-serif",
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
}; */

const LargeCompanyDetails = (props: {
  isAdmin: boolean;
  model: HomepageDto;
  isSmallScreen: boolean;
}) => {
  const logoSource: string = props.model.logoImage; /* props.isAdmin
    ? "https://image.pngaaa.com/311/5315311-middle.png"
    : "https://cdn.shopify.com/s/files/1/1852/5423/files/Inclusive_trade_logo_4_155x.png?v=1679313183"; */
  const title: string = props.model.title; // props.isAdmin ? "IVet Admin" : "Inclusive Trade";
  const subTitle: string = props.model.subTitle; /*  props.isAdmin
    ? "Serious work is being done...."
    : "the amazon of ethical retail"; */
  return (
    <Box>
      {/*       {logoSource && (
        <Box
          component="img"
          sx={{
            width: { xs: "50px", sm: "100px" },
            height: { xs: "50px", sm: "100px" },
          }}
          src={logoSource}
        />
      )}
      {!logoSource && (
        <Skeleton
          sx={{
            width: { xs: "50px", sm: "100px" },
            height: { xs: "50px", sm: "100px" },
          }}
        />
      )} */}

      <Typography
        variant={!props.isSmallScreen ? "h4" : "h2"}
        /*           sx={{
            fontSize: { xs: "30px", sm: "120px" },
            fontFamily: "Calibri, sans-serif",
          }} */
      >
        {title}
      </Typography>
      <Typography
        variant={!props.isSmallScreen ? "h6" : "h4"}
        /*           sx={{
            fontSize: { xs: "20px", sm: "60px" },
            fontFamily: "Calibri, sans-serif",
          }} */
      >
        {subTitle}
      </Typography>
      {/*         <LoginComponent /> */}
    </Box>
  );
};
const LoginComponent = (props: { model: HomepageDto }) => {
  return (
    <>
      <NavLink to={ROUTES.SIGN_IN} style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: `calc(${buttonWidth}px)` }}
          /*           sx={{
            marginTop: "50px",
            fontSize: { xs: "12px", sm: "24px" },
            width: { xs: "120px", sm: "250px" },
          }} */
        >
          {props.model.loginLabel}
        </Button>
      </NavLink>
      <div style={{ marginTop: "10px" }}>
        <Link
          component={RouterLink}
          to={ROUTES.SIGN_UP}
          variant="body2"
          /*           sx={{ fontSize: { xs: "12px", sm: "24px" } }} */
        >
          {props.model.signupLabel}
        </Link>
      </div>
    </>
  );
};
