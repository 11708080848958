import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useAuth } from "../../core/firebase";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/constants";
import { Language, LanguageContext } from "../../core/language";
import { IKeyedObject } from "../../core/types";
import { Link as RouterLink } from "react-router-dom";
import { ActivityContext } from "../../core/activityContext";

const pageEntries: IKeyedObject<string> = {
  signin_title: "Sign In",
  signin_email_label: "Email Address",
  signin_password_label: "Password",
  signin_button_label: "Sign In",
  signin_forgotpwd_label: "Forgot Password?",
  signin_signup_label: "Don't have an account? Sign Up",
};

export const Login = () => {
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const { authToken, signIn } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const [entries, setEntries] = useState(pageEntries);
  const { setBarVisibilityForPage: setBarVisibility } =
    useContext(ActivityContext);

  useEffect(() => {
    setBarVisibility(false);
    return () => setBarVisibility(true);
  });

  useEffect(() => {
    if (authToken) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [authToken, navigate]);

  useEffect(() => {
    const languageEntries = translate(
      Language.EntryCategory.SignIn,
      pageEntries
    );
    setEntries(languageEntries);
  }, [selectedLanguage, translate]);

  const handleSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    const signInResult = await signIn(email, password);

    if (signInResult?.hasEmailError) {
      setEmailError(signInResult.error);
      setPasswordError(undefined);
      setError(undefined);
    } else if (signInResult?.hasPasswordError) {
      setPasswordError(signInResult.error);
      setEmailError(undefined);
      setError(undefined);
    } else if (signInResult?.hasSomeOtherError) {
      setEmailError(undefined);
      setPasswordError(undefined);
      setError(signInResult.error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {entries["signin_title"]}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={emailError ? true : false}
            helperText={emailError ?? ""}
            margin="normal"
            required
            fullWidth
            id="email"
            label={entries["signin_email_label"]}
            name="email"
            autoComplete="email"
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            error={passwordError ? true : false}
            helperText={passwordError ?? ""}
            required
            fullWidth
            name="password"
            label={entries["signin_password_label"]}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {entries["signin_button_label"]}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to={ROUTES.PASSWORD_FORGOT}
                variant="body2"
              >
                {entries["signin_forgotpwd_label"]}
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to={ROUTES.SIGN_UP} variant="body2">
                {entries["signin_signup_label"]}
              </Link>
            </Grid>
          </Grid>
          {error && (
            <Typography
              component="div"
              variant="body2"
              color="error"
              sx={{ mt: 3, alignContent: "center" }}
            >
              Error : {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};
