import {
  ArrowDropDown,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { MenuItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { useState } from "react";
import { IMenuItem } from "../layouts/menuTypes";

export interface IChildMenuItem {
  value: string;
  isSelected: boolean;
  key: string;
}

export interface INestedMenuItem {
  menu: IMenuItem;
  childItems: IChildMenuItem[];
  onNestedItemClicked: (menu: IMenuItem, child: IChildMenuItem) => void;
  showIsSelected: boolean;
}

export const NestedMenuItem = (props: INestedMenuItem) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onDropDownClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChildItemClicked = (item: IChildMenuItem) => {
    handleClose();
    props.onNestedItemClicked(props.menu, item);
  };

  const open = Boolean(anchorEl);
  const id = open ? `menu-popover-${props.menu.key}` : undefined;
  console.log("Navbar nested menu", props);
  return (
    <div>
      <MenuItem
        aria-describedby={id}
        onClick={onDropDownClick}
        key={`nesteditem-${props.menu.key}`}
      >
        <ListItemIcon>
          <ListItemIcon>{props.menu.icon}</ListItemIcon>
        </ListItemIcon>
        <ListItemText primary={props.menu.value} />
        <ListItemIcon sx={{ marginLeft: 1 }}>
          <ArrowDropDown fontSize="small" />
        </ListItemIcon>
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {props.childItems &&
          props.childItems.map((childItem) => {
            return (
              <MenuItem
                onClick={() => onChildItemClicked(childItem)}
                key={`${props.menu.key}-${childItem.key}`}
              >
                {props.showIsSelected && (
                  <ListItemIcon>
                    <ListItemIcon>
                      {childItem.isSelected && <RadioButtonChecked />}
                      {!childItem.isSelected && <RadioButtonUnchecked />}
                    </ListItemIcon>
                  </ListItemIcon>
                )}
                <ListItemText primary={childItem.value} />
              </MenuItem>
            );
          })}
      </Popover>
    </div>
  );
};
