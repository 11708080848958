import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import { makeAutoObservable, runInAction } from "mobx";
import {
  DialogViewModel,
  IDialogViewModel,
} from "../../common/dialogViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  CertificationClient,
  IBaseScreenDto,
  ResultOfMSMECertificationDto,
} from "../../utils/api";
import { MSMEGoalsViewModel } from "./msme-goals/msmeGoalsViewModel";

interface IMSMECertificationManagerViewModel extends IBasePageViewModel {
  msmeGoalsViewModel: MSMEGoalsViewModel;
}

export class MSMECertificationManagerViewModel
  implements IMSMECertificationManagerViewModel
{
  msmeGoalsViewModel: MSMEGoalsViewModel;
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();

  constructor(
    private selectedLanguage: string,
    private msmeId: string
  ) {
    this.msmeGoalsViewModel = new MSMEGoalsViewModel(
      this.selectedLanguage,
      this.dialogViewModel
    );
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  setModel = (result: ResultOfMSMECertificationDto) => {
    if (result.succeeded) {
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        undefined
      );
      this.msmeGoalsViewModel.setViewModelData(result);
      this.msmeGoalsViewModel.setIsVisible(true);
    }
  };

  fetchData = () => {
    const client = new CertificationClient();
    client
      .getMsmeCertificationModel(this.selectedLanguage, this.msmeId)
      .then((result) => this.setModel(result))
      .catch((reason) => console.log("Could not fetch certification model"));
  };
}
