import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTheme } from "@mui/material/styles";
import ActivityBar from "../controls/activityBar";
import { drawerBleeding } from "../core/constants";
import { Divider, Typography } from "@mui/material";
import useWindowDimensions from "../common/windowDimension";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 3,
  left: "calc(50% - 15px)",
}));

export default function SwipeableActivityBar() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const toggleDrawer =
    (newOpen: boolean | ((prevState: boolean) => boolean)) => () => {
      setOpen(newOpen);
    };
  const { windowheight } = useWindowDimensions();

  return (
    <Box sx={{ display: "flex" }}>
      {/* Below definition Global defines the area of the drawer when opened

    Also overFlow property displays the bar to open the container
 */}
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            overflow: "visible",
          },
          display: "flex",
        }}
      />

      <SwipeableDrawer
        // This is the main drawer container
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          //this box displays the drawer header with the swaipeable bar when it is closed
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            backgroundColor: theme.palette.primary.light,
            height: drawerBleeding,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "10px",
            borderTop: 1,
          }}
        >
          <Puller /> {/* This is the bar */}
          <Box sx={{ paddingTop: "5px" }}>
            <Typography variant="body1">Taskbar </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            px: 2,
            pb: 2,
            height: `calc(${windowheight / 2}px)`,
            overflow: "auto",
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <ActivityBar />
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
