import React, { useContext, useEffect, useState } from "react";
import { IHigherContextProviderProps } from "./firebase";
import { IFavoritesDto, TenantUserClient } from "../utils/api";
import { SignedInUserContext } from "./signedInUser";
import { LanguageContext } from "./language";

const defaultFavorites: IFavoritesDto = {
  label: "Favourites",
  favoriteActions: [],
  noFavoritesLabel: "No Favourites Set",
};

export interface IFavoritesContext {
  favorites: IFavoritesDto;
  addFavorite: (id: string, value: string | undefined) => void;
  removeFavorite: (url: string) => void;
}

export const FavoritesContext = React.createContext<IFavoritesContext>({
  favorites: defaultFavorites,
  addFavorite: (id: string, value: string | undefined) => {},
  removeFavorite: (id: string) => {},
});

export const FavoritesContextProvider: React.FC<
  IHigherContextProviderProps
> = ({ children }) => {
  const { signedInUser } = useContext(SignedInUserContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const [favoritesDto, setFavoritesDto] =
    useState<IFavoritesDto>(defaultFavorites);
  useEffect(() => {
    if (selectedLanguage && signedInUser) {
      const client = new TenantUserClient();
      client.getUserFavorites(selectedLanguage).then((result) => {
        if (result.succeeded) {
          setFavoritesDto(result.value ?? defaultFavorites);
        }
      });
    }
  }, [signedInUser, selectedLanguage]);

  const addFavorite = (id: string, value: string | undefined) => {
    const client = new TenantUserClient();
    client.addUserFavorite(selectedLanguage, id, value ?? "").then((result) => {
      setFavoritesDto(result.value ?? defaultFavorites);
    });
  };

  const removeFavorite = (id: string) => {
    const client = new TenantUserClient();
    client.removeUserFavorite(selectedLanguage, id).then((result) => {
      setFavoritesDto(result.value ?? defaultFavorites);
    });
  };
  return (
    <FavoritesContext.Provider
      value={{
        favorites: favoritesDto,
        addFavorite,
        removeFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
