import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../core/language";
import { SDGEditFormModel, SDGEditViewModel } from "./sdgEditViewModel";
import { useNavigate, useParams } from "react-router-dom";
import { BasePage } from "../../controls/basePage";
import { Paper, Typography } from "@mui/material";
import {
  FieldWrapperControl,
  FormButtonSection,
} from "../../controls/fieldWrapperControl";
import { IBasePageViewModel } from "../../common/pageTitleViewModel";
import { TodoContext } from "../../core/todoContext";

export const SDGEdit = observer((props: { isApprove: boolean }) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<SDGEditViewModel | undefined>(
    undefined
  );
  const { refreshTodo } = useContext(TodoContext);
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    setViewModel(
      new SDGEditViewModel(
        selectedLanguage,
        id ?? "new",
        props.isApprove,
        navigate,
        refreshTodo
      )
    );
  }, [selectedLanguage, id, navigate, props.isApprove, refreshTodo]);

  if (viewModel) {
    return <SDGEditView viewModel={viewModel} />;
  }

  return null;
});

interface ISDGEditViewProps {
  viewModel: SDGEditViewModel;
}
const SDGEditView = observer((props: ISDGEditViewProps) => {
  return (
    <BasePage pageDetails={props.viewModel as IBasePageViewModel}>
      <SDGEditForm model={props.viewModel.model} />
    </BasePage>
  );
});

const SDGEditForm = observer(
  (props: { model: SDGEditFormModel | undefined }) => {
    if (!props.model) {
      return null;
    }
    return (
      <Paper sx={{background: "transparent" }} elevation={0}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {props.model.title}
        </Typography>
        <FieldWrapperControl {...props?.model.name} />
        <FieldWrapperControl {...props?.model.description} />
        <FieldWrapperControl {...props?.model.image} />
        <FieldWrapperControl {...props?.model.comment} />
        <FormButtonSection
          actions={props.model.actions}
          cancelAction={props.model.cancelAction}
        />
      </Paper>
    );
  }
);
