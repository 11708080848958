import { makeAutoObservable, runInAction } from "mobx";
import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../../common/contextSensitiveViewModel";
import {
  DialogViewModel,
  IDialogViewModel,
} from "../../../common/dialogViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../../common/pageTitleViewModel";
import {
  CertificationClient,
  IBaseScreenDto,
  IQuestionVerificationModel,
  MSMEQuestionnaireDto,
  QuestionVerificationModel,
  ResultOfMSMEQuestionnaireDto,
} from "../../../utils/api";
import { IActionButtonProps } from "../../../controls/actionButton";
import {
  IQuestionStepperData,
  IQuestionStepperViewModel,
  QuestionStepperViewModel,
} from "./QuestionStepperViewModel";
import { QuestionViewModel } from "./QuestionViewModel";
import { NavigateFunction } from "react-router-dom";
import {
  DialogResult,
  DialogType,
  IComponentDialogProps,
} from "../../../controls/dialogs";
import { IVerifyConfirmProps, VerifyConfirmView } from "./verifyConfirm";
import { FieldWrapper } from "../../../common/formTypes";
import { IQuestionListProps, QuestionList } from "./QuestionList";
export interface IQuestionnaireViewModel extends IBasePageViewModel {
  questionStepper: IQuestionStepperViewModel | undefined;
  currentQuestion: QuestionViewModel | undefined;
  previous: IActionButtonProps | undefined;
  next: IActionButtonProps | undefined;
  submit: IActionButtonProps | undefined;
  saveDraft: IActionButtonProps | undefined;
  verifyAccept: IActionButtonProps | undefined;
  verifyReject: IActionButtonProps | undefined;
  currentQuestionIndex: number;
  model: MSMEQuestionnaireDto | undefined;
  isLastQuestion: boolean;
  questions: QuestionViewModel[];
}

export class QuestionnaireViewModel implements IQuestionnaireViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  questionStepper = new QuestionStepperViewModel();
  questions: QuestionViewModel[] = [];

  currentQuestion: QuestionViewModel | undefined;
  previous: IActionButtonProps | undefined;
  next: IActionButtonProps | undefined;
  submit: IActionButtonProps | undefined;
  saveDraft: IActionButtonProps | undefined;
  verifyAccept: IActionButtonProps | undefined;
  verifyReject: IActionButtonProps | undefined;
  currentQuestionIndex: number = 0;
  model: MSMEQuestionnaireDto | undefined;
  widthOverride?: "xl" | "xs" | "lg" | "md" | undefined = "xl";
  isLastQuestion: boolean = false;
  verifyDialogProps: IComponentDialogProps | undefined = undefined;
  questionListDialogProps: IComponentDialogProps | undefined = undefined;

  verifyViewModel: IVerifyConfirmProps = {
    commentField: new FieldWrapper(),
  };

  questionListViewModel: IQuestionListProps = {
    questions: this.questions,
    selectionCallback: this.setCurrentQuestion,
  };

  setCurrentQuestionNew = (index: number) => {
    this.currentQuestionIndex = index;
    this.questionStepper.setCurrentQuestion(index);
    this.currentQuestion = this.questions[index];
    this.isLastQuestion = index === this.questions.length - 1;
    this.handleQuestionStateChange();

    this.dialogViewModel.clearDialog();
  };

  handleQuestionListClick = () => {
    this.questionListViewModel = {
      questions: this.questions,
      selectionCallback: this.setCurrentQuestionNew,
    };

    this.questionListDialogProps = {
      okDisabled: true,
      okLabel: "",
      cancelLabel: "Cancel",
      component: <QuestionList {...this.questionListViewModel} />,
      okVisible: false,
      cancelVisible: true,
    };
    this.dialogViewModel.setDialog(
      "Question List",
      "",
      DialogType.ComponentDialog,
      this.handleVerifyAcceptDialogResult,
      this.questionListDialogProps
    );
  };

  setCurrentQuestion(index: number) {
    this.currentQuestionIndex = index;
    this.questionStepper.setCurrentQuestion(index);
    this.currentQuestion = this.questions[index];
    this.isLastQuestion = index === this.questions.length - 1;
    this.handleQuestionStateChange();
  }
  saveUserResponse = (): Promise<boolean> => {
    if (this.currentQuestion) {
      const data = this.currentQuestion.getAnswerModel();
      const client = new CertificationClient();
      return client
        .saveAnswer(
          data.questionNumber,
          data.questionId,
          data.isFlagged,
          data.isAnswered,
          data.answer,
          data.filesUploaded,
          data.additionalInformation,
          data.filesRemoved,
          data.comments,
          this.selectedLanguage
        )
        .then((result) => {
          if (result.succeeded && result.value && this.currentQuestion) {
            this.currentQuestion.setModelData(result.value);
          }
          return Promise.resolve(true);
        })
        .catch((reason) => {
          console.log("Could not Save Question Reseponse");
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  };

  saveVerifierResponse = (): Promise<boolean> => {
    if (this.currentQuestion) {
      const data = this.currentQuestion.getVerificationModel();
      data.language = this.selectedLanguage;
      console.log("Verification model", data);

      const client = new CertificationClient();
      return client
        .saveQuestionVerification(data)
        .then((result) => {
          if (result.succeeded && result.value && this.currentQuestion) {
            console.log("Approver response saved");
          }
          return Promise.resolve(true);
        })
        .catch((reason) => {
          console.log("Could not Save Question Reseponse");
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(false);
  };
  saveQuestion = (): Promise<boolean> => {
    if (this.model?.isSaveAllowed) {
      return this.saveUserResponse();
    } else if (this.model?.isVerifyAllowed) {
      return this.saveVerifierResponse();
    }
    return Promise.resolve(false);
  };

  handlePrevious = () => {
    if (this.currentQuestionIndex > 0) {
      this.saveQuestion().then((result) =>
        this.setCurrentQuestion(this.currentQuestionIndex - 1)
      );
    }
  };

  handleNext = () => {
    if (this.currentQuestionIndex < this.questions.length - 1) {
      this.saveQuestion().then((result) => {
        this.setCurrentQuestion(this.currentQuestionIndex + 1);
      });
    }
  };

  handleSubmitDialogClose = (result: any) => {
    this.navigate(-1);
  };

  handleSubmit = () => {
    this.saveQuestion().then((result) => {
      const client = new CertificationClient();
      return client
        .markSubmitted(this.selectedLanguage, this.certificateId)
        .then((result) =>
          this.dialogViewModel.setDialog(
            "",
            result.value ?? "",
            DialogType.Alert,
            this.handleSubmitDialogClose
          )
        )
        .catch((reason) =>
          console.log("Error while submitting certificate" + reason)
        );
    });
  };

  saveVerify = (accept: boolean) => {
    const client = new CertificationClient();
    client
      .saveVerification(
        this.selectedLanguage,
        this.certificateId,
        this.verifyViewModel.commentField.fieldValue,
        accept
      )
      .then((result) => {
        if (result.succeeded) {
          this.refreshTodo();
          this.navigate(-1);
        } else {
          this.dialogViewModel.setDialog(
            "",
            result.error ?? "",
            DialogType.Alert,
            () => {}
          );
        }
      })
      .catch((reason) =>
        console.log("Error while saving verification" + reason)
      );
  };

  handleVerifyAcceptDialogResult = (result: DialogResult) => {
    if (result === DialogResult.Yes) {
      this.saveVerify(true);
    }
  };

  handleVerifyRejectDialogResult = (result: DialogResult) => {
    if (result === DialogResult.Yes) {
      this.saveVerify(false);
    }
  };

  handleVerifyValueChange = () => {
    const okAllowed = this.verifyViewModel.commentField.canSave();

    this.dialogViewModel.setOkAllowed(okAllowed);
  };

  setVerifyDialog = (message: string, accept: boolean) => {
    if (this.model?.commentField && this.model.ratingField) {
      this.verifyViewModel.commentField.setField(
        this.model?.commentField,
        this.handleVerifyValueChange
      );
      this.verifyDialogProps = {
        okDisabled: true,
        okLabel: this.model.verifyConfirmOkLabel,
        cancelLabel: this.model.verifyConfirmCancelLabel,
        component: <VerifyConfirmView {...this.verifyViewModel} />,
        okVisible: true,
        cancelVisible: true,
      };

      this.dialogViewModel.setDialog(
        message,
        "",
        DialogType.ComponentDialog,
        accept
          ? this.handleVerifyAcceptDialogResult
          : this.handleVerifyRejectDialogResult,
        this.verifyDialogProps
      );
    }
  };
  handleVerifyAccept = () => {
    this.setVerifyDialog(
      this.model?.verifyAcceptDialogLabel ?? "Accept Submission",
      true
    );
  };

  handleVerifyReject = () => {
    this.setVerifyDialog(
      this.model?.verifyRejectDialogLabel ?? "Reject Submission",
      false
    );
  };

  handleSaveDraft = () => {
    this.saveQuestion().then((result) => {
      console.log("Question Saved");
      this.navigate(-1);
    });
  };

  handleQuestionStateChange = () => {
    if (this.currentQuestion) {
      this.questionStepper.setCurrentQuestionState(
        this.currentQuestion?.isAnswered,
        this.currentQuestion?.isFlagged
      );
    }
  };
  handleMessagePost = (message: string) => {
    const client = new CertificationClient();
    client
      .saveQuestionMessage(
        message,
        this.currentQuestion?.question.id,
        this.certificateId
      )
      .then((result) => {
        if (result.succeeded) {
          this.currentQuestion?.setMessages(result.value ?? []);
        }
      })
      .catch((reason) =>
        console.log("Error while saving verification" + reason)
      );
  };
  setModel = (result: ResultOfMSMEQuestionnaireDto) => {
    if (result.succeeded) {
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        undefined
      );
      if (result.value) {
        this.model = result.value;

        const stepper: IQuestionStepperData[] = [];

        const questionViewModels: QuestionViewModel[] = [];
        this.model.questions.forEach((item, index) => {
          const questionViewModel = new QuestionViewModel(
            item,
            this.handleQuestionStateChange,
            this.dialogViewModel,
            this.handleQuestionListClick,
            this.handleMessagePost
          );
          questionViewModels.push(questionViewModel);
          stepper.push({
            number: index,
            isAnswered: questionViewModel.isAnswered,
            isFlagged: questionViewModel.isFlagged,
          });
        });
        this.questionStepper.setQuestions(stepper);

        this.questions = questionViewModels;
        this.currentQuestion = this.questions[0];
        this.currentQuestionIndex = 0;

        this.previous = {
          action: this.model.previous,
          onClick: this.handlePrevious,
        };
        this.next = { action: this.model.next, onClick: this.handleNext };
        this.saveDraft = {
          action: this.model.save,
          onClick: this.handleSaveDraft,
        };
        this.submit = {
          action: this.model.submit,
          onClick: this.handleSubmit,
        };
        this.verifyAccept = {
          action: this.model.verifyAccept,
          onClick: this.handleVerifyAccept,
        };
        this.verifyReject = {
          action: this.model.verifyReject,
          onClick: this.handleVerifyReject,
        };
      }
    }
  };

  fetchData = () => {
    const client = new CertificationClient();
    client
      .getCertificateQuestionnaire(this.selectedLanguage, this.certificateId)
      .then((result) => this.setModel(result))
      .catch((reason) => console.log("Could not fetch certification model"));
  };

  constructor(
    private selectedLanguage: string,
    private certificateId: string,
    private navigate: NavigateFunction,
    private refreshTodo: () => void
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }
}
