import { observer } from "mobx-react";
import { Box, Grid } from "@mui/material";
import { ActionButton } from "../../../controls/actionButton";
import { IQuestionnaireViewProps } from "./questionnaire";

export const QuestionActions = observer((props: IQuestionnaireViewProps) => {
  if (!props.viewModel) {
    return null;
  }
  return (
    <Grid container sx={{ display: "flex", flexDirection: "row" }}>
      <Grid item xs={12} sm={6}>
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-evenly",
            flex: "1",
          }}
        >
          <ActionButton
            action={props.viewModel?.previous?.action}
            onClick={props.viewModel.handlePrevious}
            overrideColor="secondary"
          />

          <ActionButton
            action={props.viewModel?.next?.action}
            onClick={props.viewModel.handleNext}
            overrideColor="secondary"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-evenly",
            flex: "1",
          }}
        >
          <ActionButton
            action={props.viewModel?.saveDraft?.action}
            onClick={props.viewModel.handleSaveDraft}
            overrideColor="warning"
          />
          {props.viewModel.isLastQuestion && (
            <>
              <ActionButton
                action={props.viewModel?.submit?.action}
                onClick={props.viewModel.handleSubmit}
              />
              <ActionButton
                action={props.viewModel?.verifyReject?.action}
                onClick={props.viewModel.handleVerifyReject}
              />
              <ActionButton
                action={props.viewModel?.verifyAccept?.action}
                onClick={props.viewModel.handleVerifyAccept}
              />
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
});
