import { observer } from "mobx-react";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { IQuestionViewModel } from "./QuestionViewModel";
import { mobileWidth } from "../../../core/constants";
import { JSX } from "react/jsx-runtime";

export interface IQuestionListProps {
  questions: IQuestionViewModel[];
  selectionCallback: (index: number) => void;
}

export const QuestionList = observer((props: IQuestionListProps) => {
  console.log("QuewstionList render", props);
  const isMobile = !useMediaQuery("(min-width:" + mobileWidth + "px)");

  const hadleRowClick = (question: IQuestionViewModel) => {
    const index = props.questions.findIndex(
      (val) => val.question.id == question.question.id
    );
    props.selectionCallback(index);
  };

  const getMobileRow = (question: IQuestionViewModel) => {
    return (
      <tr
        key={question.question.id}
        onClick={() => hadleRowClick(question)}
        className=""
      >
        <td>{question.question.sdgName}</td>
        <td>{question.question.section}</td>
        <td>{question.question.questionText}</td>
        <td>
          {question.question.isAnswered && (
            <Icon color={"primary"}>{"done"}</Icon>
          )}
          {question.question.isFlagged && (
            <Icon color={"error"}>{question.question.flagAction.icon}</Icon>
          )}
        </td>
      </tr>
    );
  };

  const getDesktopRow = (question: IQuestionViewModel) => {
    return (
      <tr key={question.question.id} onClick={() => hadleRowClick(question)}>
        <td>{question.question.sdgName}</td>
        <td>{question.question.section}</td>
        <td>{question.question.questionText}</td>
        <td>
          {question.question.isAnswered && (
            <Icon color={"primary"}>{"done"}</Icon>
          )}
          {question.question.isFlagged && (
            <Icon color={"error"}>{question.question.flagAction.icon}</Icon>
          )}
        </td>
      </tr>
    );
  };
  const getRows = () => {
    let sdg: string = "";
    let section: string = "";
    const rows: JSX.Element[] = [];
    return (
      <table className="questionlist">
        {props.questions &&
          props.questions.map((question) => {
            if (isMobile) {
              return getMobileRow(question);
            } else {
              return getDesktopRow(question);
            }
          })}
      </table>
    );
  };
  return (
    <Paper
      sx={{
        minHeight: "300px",
        height: "400px",
        backgroundColor: "transparent",
        overflow: "auto",
        fontSize: "12px",
      }}
      elevation={0}
    >
      {getRows()}
    </Paper>
  );
});
