import { useCallback } from "react";
import {
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

export interface IActivityAction {
  icon: any;
  toolTip: any;
  actionKey: string;
}

export interface IActivityActionButton extends IActivityAction {
  callback?: (key: string) => void;
}

export const ActivityActionButton = (props: IActivityActionButton) => {
  const handleClick = () => {
    if (props.callback) {
      props?.callback(props.actionKey);
    }
  };
  return (
    <Tooltip title={props.toolTip}>
      <IconButton onClick={handleClick}>
        <Icon>{props.icon}</Icon>
      </IconButton>
    </Tooltip>
  );
};
export interface ICurrentActivityContext {
  title: string;
  actions: IActivityAction[];
  callback: (key: string) => void;
}
export const ActivityToolBar = (props: ICurrentActivityContext | undefined) => {
  const theme = useTheme();

  const getIcon = useCallback(
    (action: IActivityAction) => {
      const handleClick = (key: string) => {
        props?.callback(key);
      };
      return (
        <ActivityActionButton
          icon={action.icon}
          key={action.actionKey}
          actionKey={action.actionKey}
          callback={handleClick}
          toolTip={action.toolTip}
        />
      );
    },
    [props]
  );

  if (props) {
    return (
      <Paper
        elevation={5}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "5px 10px 7px", // Add your desired padding here
          backgroundColor: theme.palette.primary.contrastText,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1, width: "100%" }}>
          {props.title}
        </Typography>
        {props.actions.map((action) => {
          return getIcon(action);
        })}
      </Paper>
    );
  } else {
    return null;
  }
};
