export const ROUTES = {
  LANDING: "/",
  SIGN_UP: "/register",
  SIGN_IN: "/signin",
  SIGN_OUT: "/signout",
  PASSWORD_FORGOT: "/forgotpwd",
  RESET_PWD: "/reset-password/:oobCode",
  DASHBOARD: "/dashboard/",
  TNC: "/tnc",
  PRIVACY: "/privacy",
  USERS: "/users",
  TENANTS: "/tenants",
  SDGS: "/sdgs",
  SDGS_EDIT: "/sdgsedit/:id",
  SDGS_APPROVE: "/sdgsapprove/:id",
  BADGES: "/badges",
  TENANTPROFILE: "/tenantprofileview",
  SETTINGS: "/settingsmain",
  MSMES: "/msmes",
  MSME_EDIT: "/msmeEdit/:id",
  AGENCIES: "/agencies",
  AGENCY_EDIT: "/agencyEdit/:id",
  CERTIFICATIONS: "/certifications",
  MSMECERTIFICATION: "/certifications/:id",
  QUESTIONNAIRE: "/questionnaire/:id",
  SDGQUESTIONS: "/sdgsquestions/:id",
  SDGQUESTIONS_APPORVE: "/sdgsquestions/:id/:approve",
};
export const drawerWidth = 240;

export const drawerBleeding = 50;

export const desktopWidth = 900;

export const mobileWidth = 480;

export const swipeableHeight = 56;

export const buttonWidth = 150;
export const iconbuttonWidth = 80;

export const textareaMinMaxRows = 3;
export enum QuestionTypeEnum {
  Number = 0,
  Text = 1,
  Percentage = 2,
  MultipleChoice = 3,
  SingleChoice = 4,
  YesNo = 5,
}
