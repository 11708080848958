import React, { useCallback, useContext, useEffect, useState } from "react";
import { IHigherContextProviderProps } from "./firebase";
import { IKeyedObject } from "./types";
import { IFavoritesDto, IUIAction } from "../utils/api";
import { FavoritesContext } from "./favoritesContext";

export enum ActivitySection {
  favorites,
  context,
  todo,
}

export interface IActivityContext {
  registerActivities: (
    section: ActivitySection,
    activities: IUIAction[]
  ) => void;
  deRegisterActivities: (
    section: ActivitySection,
    activities: IUIAction[]
  ) => void;
  activities: IKeyedObject<IActivitySection>;
  activityBarVisible: boolean;
  setBarVisibilityForPage: (visible: boolean) => void;
  toggleTaskBar: () => void;
}

export const ActivityContext = React.createContext<IActivityContext>({
  registerActivities: (section: ActivitySection, activities: IUIAction[]) => {},
  deRegisterActivities: (
    section: ActivitySection,
    activities: IUIAction[]
  ) => {},
  activities: {},
  activityBarVisible: true,
  setBarVisibilityForPage: (visible: boolean) => {},
  toggleTaskBar: () => {},
});
export interface IActivitySection {
  header: string;
  showHeader: boolean;
  activitiesCanBeRemoved: boolean;
  activities: IUIAction[];
  noEntry: string;
}
const defaultActivities: IKeyedObject<IActivitySection> = {
  Favorites: {
    header: "Favorites",
    showHeader: true,
    activitiesCanBeRemoved: true,
    activities: [],
    noEntry: "No Favourites set",
  },
  Context: {
    header: "Context",
    showHeader: false,
    activitiesCanBeRemoved: true,
    activities: [],
    noEntry: "",
  },
};
export const ActivityContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const { favorites } = useContext(FavoritesContext);

  const [contextActivities, setContextActivities] = useState<
    IKeyedObject<IActivitySection>
  >({ ...defaultActivities });

  const [activityBarVisibleForPage, setActivityBarVisibleForPage] =
    useState<boolean>(true);

  const [toggleBar, setToggleBar] = useState<boolean>(true);

  const handleToggleBar = useCallback(() => {
    setToggleBar((prev) => !prev);
  }, []);

  const setBarVisibilityForPage = useCallback((visible: boolean) => {
    setActivityBarVisibleForPage(visible);
  }, []);
  const registerFavorites = useCallback((favorites: IFavoritesDto) => {
    if (favorites) {
      setContextActivities((previous) => {
        return {
          ...previous,
          Favorites: {
            header: favorites.label,
            showHeader: true,
            activitiesCanBeRemoved: true,
            activities: favorites.favoriteActions,
            noEntry: favorites.noFavoritesLabel,
          },
        };
      });
    }
  }, []);

  useEffect(() => {
    registerFavorites(favorites);
  }, [favorites, registerFavorites]);

  const registerActivities = useCallback(
    (section: ActivitySection, activities: IUIAction[]) => {
      setContextActivities((previous) => {
        if (section === ActivitySection.context) {
          return {
            ...previous,
            Context: {
              ...previous.Context,
              activities: [...previous.Context.activities, ...activities],
            },
          };
        }

        return previous;
      });
    },
    []
  );

  const deRegisterActivities = useCallback(
    (section: ActivitySection, activitiesToRemove: IUIAction[]) => {
      setContextActivities((previous) => {
        if (section === ActivitySection.favorites) {
          const updatedActivities = previous.Favorites.activities.filter(
            (activity) =>
              !activitiesToRemove.some(
                (activityToRemove) => activityToRemove.code === activity.code
              )
          );

          return {
            ...previous,
            Favorites: {
              ...previous.Favorites,
              activities: updatedActivities,
            },
          };
        } else if (section === ActivitySection.context) {
          const updatedActivities = previous.Context.activities.filter(
            (activity) =>
              !activitiesToRemove.some(
                (activityToRemove) => activityToRemove.code === activity.code
              )
          );

          return {
            ...previous,
            Context: {
              ...previous.Context,
              activities: updatedActivities,
            },
          };
        }

        return previous;
      });
    },
    []
  );

  return (
    <ActivityContext.Provider
      value={{
        registerActivities,
        deRegisterActivities,
        activities: contextActivities,
        activityBarVisible: activityBarVisibleForPage && toggleBar,
        setBarVisibilityForPage: setBarVisibilityForPage,
        toggleTaskBar: handleToggleBar,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
