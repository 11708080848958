import { makeAutoObservable, runInAction } from "mobx";
import { IDialogViewModel } from "../../../common/dialogViewModel";
import {
  CertificateQuestionDto,
  FileParameter,
  QuestionMessage,
  QuestionVerificationModel,
  UserMessage,
} from "../../../utils/api";
import { FieldWrapper } from "../../../common/formTypes";
import { DialogType } from "../../../controls/dialogs";

export type IAnswerModel = {
  questionId: string;
  isFlagged: boolean;
  isAnswered: boolean;
  answer: string;
  additionalInformation?: string | undefined;
  filesRemoved: string;
  filesUploaded: FileParameter[];
  questionNumber: number;
  comments: string | undefined;
};
export interface IQuestionViewModel {
  isValidData: boolean;
  question: CertificateQuestionDto;
  questionField: FieldWrapper;
  additionalInfoField: FieldWrapper;
  commentField: FieldWrapper;
  ratingField: FieldWrapper;
  notesToApproverField: FieldWrapper;
  evidence: FieldWrapper;
  isFlagged: boolean;
  isAnswered: boolean;
  removedFiles: string[];
  handleTargetInfoClick: () => void;
  handleIsFlaggedClick: () => void;
  handleQuestionListClick: () => void;
  isSaveRequired: boolean;
  messages: UserMessage[];
  setMessages: (messages: UserMessage[]) => void;
}
export class QuestionViewModel implements IQuestionViewModel {
  isValidData: boolean = true;
  questionField = new FieldWrapper();
  additionalInfoField = new FieldWrapper();
  evidence = new FieldWrapper();
  isFlagged = false;
  isAnswered = false;
  removedFiles: string[] = [];
  isSaveRequired: boolean = false;
  commentField = new FieldWrapper();
  ratingField = new FieldWrapper();
  notesToApproverField = new FieldWrapper();
  messages: UserMessage[] = [];

  setMessages = (newMessages: UserMessage[]) => {
    this.messages = newMessages;
    this.commentField.setFieldValue(undefined);
  };

  onValueChange = () => {
    this.isValidData = this.questionField.canSave() && this.evidence.canSave();
    this.isAnswered = this.isValidData ?? false;
    this.isSaveRequired = true;
    this.handleQuestionStateChange();
  };

  handleTargetInfoClick = () => {
    this.dialogViewModel.setDialog(
      this.question.targetInfoAction.title,
      this.question.targets.join("<br/>"),
      DialogType.Alert
    );
  };

  handleIsFlaggedClick = () => {
    this.isFlagged = !this.isFlagged;
    this.handleQuestionStateChange();
  };

  getAnswerModel = () => {
    const answer: IAnswerModel = {
      questionId: this.question.id,
      isFlagged: this.isFlagged,
      isAnswered: this.isAnswered,
      answer: this.questionField.fieldValue,
      filesRemoved: this.evidence.removedFiles.join(","),
      filesUploaded: this.evidence.fieldValue,
      questionNumber: this.question.number,
      additionalInformation: this.additionalInfoField.fieldValue,
      comments: this.commentField.fieldValue,
    };
    return answer;
  };

  getVerificationModel = () => {
    const verificationModel = new QuestionVerificationModel();
    verificationModel.questionId = this.question.id;
    verificationModel.rating = this.ratingField.fieldValue;
    return verificationModel;
  };

  setModelData = (questionData: CertificateQuestionDto) => {
    this.question = questionData;
    this.setModel();
  };

  setModel = () => {
    this.messages = this.question.messages;
    this.questionField.setField(this.question.questionField, () =>
      this.onValueChange()
    );

    this.additionalInfoField.setField(this.question.additionalInfoField, () =>
      this.onValueChange()
    );

    this.evidence.setField(this.question.evidenceField, () =>
      this.onValueChange()
    );

    this.commentField.setField(this.question.comment, () =>
      this.onValueChange()
    );

    this.notesToApproverField.setField(this.question.notesToApprover, () => {});
    this.ratingField.setField(this.question.stars, () => this.onValueChange());
    this.isFlagged = this.question.isFlagged;
    this.isAnswered = this.question.isAnswered;
    this.isSaveRequired = false;
  };

  handleMessageSend = () => {
    if (this.commentField.canSave()) {
      this.handleMessagePost(this.commentField.fieldValue);
    }
  };
  constructor(
    public question: CertificateQuestionDto,
    private handleQuestionStateChange: () => void,
    private dialogViewModel: IDialogViewModel,
    public handleQuestionListClick: () => void,
    public handleMessagePost: (message: string) => void
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.setModel();
    });
  }
}
