import { NavigateFunction } from "react-router-dom";
import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  DialogViewModel,
  IDialogViewModel,
} from "../../common/dialogViewModel";
import { FormButtonWrapper, FieldWrapper } from "../../common/formTypes";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import { DialogType } from "../../controls/dialogs";
import {
  IBaseScreenDto,
  ResultOfSdgModelWrapperDto,
  SDGClient,
  SdgApprovalModel,
  SdgModelDto,
} from "../../utils/api";
import { makeAutoObservable, runInAction } from "mobx";
import { ROUTES } from "../../core/constants";

interface ISDGEditViewModel extends IBasePageViewModel {
  model: ISDGEditFormModel | undefined;
}

export class SDGEditViewModel implements ISDGEditViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  model: SDGEditFormModel | undefined = new SDGEditFormModel();
  rowVersion: string | undefined = undefined;

  constructor(
    private selectedLanguage: string,
    private id: string,
    private isApprove: boolean,
    private navigate: NavigateFunction,
    private refreshTodo: () => void
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  handleSave = () => {
    if (!this.model?.isValidData(false)) {
      console.log("Invalid data");
      return;
    }
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    const client = new SDGClient();
    if (this.id === "new") {
      client
        .createSdg(
          this.model.name.fieldValue,
          this.model.description.fieldValue,
          this.model.image.fieldValue,
          this.selectedLanguage
        )
        .then((result) => {
          if (result.succeeded) {
            this.dialogViewModel.clearDialog();
            this.refreshTodo();
            this.navigate(ROUTES.SDGS);
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) =>
          this.dialogViewModel.setDialog("Error", reason, DialogType.Alert)
        );
    } else {
      client
        .updateSdg(
          +this.id,
          this.model.name.fieldValue,
          this.model.description.fieldValue,
          this.model.image.fieldValue,
          this.rowVersion,
          this.selectedLanguage
        )
        .then((result) => {
          if (result.succeeded) {
            this.dialogViewModel.clearDialog();
            this.refreshTodo();
            this.navigate(ROUTES.SDGS);
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) =>
          this.dialogViewModel.setDialog("Error", reason, DialogType.Alert)
        );
    }
  };

  handleAcceptReject = (action: string) => {
    if (!this.model?.isValidData(true)) {
      return;
    }
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    const approveModel = new SdgApprovalModel();
    approveModel.action = action;
    approveModel.id = +this.id;
    approveModel.comment = this.model.comment.fieldValue;
    approveModel.language = this.selectedLanguage;
    approveModel.rowVersion = this.rowVersion ?? "";

    const client = new SDGClient();
    client
      .acceptRejectChange(approveModel)
      .then((result) => {
        if (result.succeeded) {
          this.dialogViewModel.clearDialog();
          this.refreshTodo();
          this.navigate(ROUTES.SDGS);
        } else {
          this.dialogViewModel.setDialog(
            result.errorCode,
            result.error,
            DialogType.Alert
          );
        }
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog("Error", reason, DialogType.Alert);
      });
  };

  onSave = (action: string) => {
    if (action === "SAVE") {
      this.handleSave();
    }
    this.handleAcceptReject(action);
  };

  onCancel = () => {
    this.navigate(-1);
  };

  setSdg = (result: ResultOfSdgModelWrapperDto) => {
    if (result.succeeded) {
      this.rowVersion = result.value?.rowVersion;
      this.contextViewModel.setContextActivities(
        result.value?.contextActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        this.id
      );
      this.dialogViewModel.clearDialog();
      if (result.value?.sdg) {
        this.model?.setData(result.value?.sdg, this.onSave, this.onCancel);
      }

      this.dialogViewModel.clearDialog();
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };

  fetchData = () => {
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    if (this.id === "new") {
      const client = new SDGClient();
      client
        .getSdgNewModel(this.selectedLanguage)
        .then((result) => {
          this.setSdg(result);
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    } else {
      const client = new SDGClient();
      client
        .getSdgModel(this.selectedLanguage, this.id, this.isApprove)
        .then((result) => {
          this.setSdg(result);
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    }
  };
}

export interface ISDGEditFormModel {
  title: string;
  name: FieldWrapper | undefined;
  description: FieldWrapper | undefined;
  image: FieldWrapper | undefined;
  comment: FieldWrapper | undefined;
  actions: FormButtonWrapper[];
  cancelAction: FormButtonWrapper | undefined;
  setData: (model: SdgModelDto) => void;
  isValidData: (isApprove: boolean) => boolean;
}

export class SDGEditFormModel implements ISDGEditFormModel {
  onValueChange = () => {
    const isValidData =
      this.name.canSave() && this.description.canSave() && this.image.canSave();

    this.actions.forEach((action) => action.setIsDisabled(!isValidData));
  };

  onCommentChange = () => {
    const isValidData = this.comment.canSave();
    this.actions.forEach((action) => action.setIsDisabled(!isValidData));
  };

  title: string = "";
  name: FieldWrapper = new FieldWrapper();
  description: FieldWrapper = new FieldWrapper();
  image: FieldWrapper = new FieldWrapper();
  cancelAction: FormButtonWrapper | undefined = undefined;
  actions: FormButtonWrapper[] = [];
  comment: FieldWrapper = new FieldWrapper();

  isValidData = (isApprove: boolean) => {
    if (!isApprove) {
      this.name.validate();
      this.description.validate();
      this.image.validate();

      return !(
        this.name.hasError ||
        this.description.hasError ||
        this.image.hasError
      );
    } else {
      this.comment.validate();
      return !this.comment.hasError;
    }
  };
  cancelHandle = () => {
    if (this.onCancel) {
      this.onCancel();
    }
  };

  actionHandle = (action: string) => {
    console.log("handling action 2");
    this.onSave?.(action);
  };

  private onSave?: (action: string) => void;
  private onCancel?: () => void;

  setData = (
    model: SdgModelDto,
    onSave?: (action: string) => void,
    onCancel?: () => void
  ) => {
    this.title = model.formTitle;
    this.name.setField(model.name, this.onValueChange);
    this.image.setField(model.image, this.onValueChange);
    this.description.setField(model.description, this.onValueChange);
    this.comment.setField(model.approvalComment, this.onCommentChange);
    this.onSave = onSave;
    this.onCancel = onCancel;
    this.actions = model.actions.map((button) => {
      const wrapper = new FormButtonWrapper(this.actionHandle);
      wrapper.setButton(button, true);
      return wrapper;
    });

    this.cancelAction = new FormButtonWrapper(this.cancelHandle);
    this.cancelAction.setButton(model.cancelAction, true);
  };

  constructor() {
    makeAutoObservable(this);
  }
}
