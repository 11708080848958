import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TenantDto } from "../../utils/api";
import { Edit, PeopleAltOutlined } from "@mui/icons-material";
import { IKeyedObject } from "../../core/types";
import { ActivityActionButton } from "../../controls/activityToolbar";

interface ITenantListProps {
  onEdit: (tenant: TenantDto) => void;
  onShowUsers: (tenant: TenantDto) => void;
  entries: IKeyedObject<string>;
  tenants: TenantDto[];
}
export const TenantList = (props: ITenantListProps) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box width="100%">
        <Typography variant="h6" sx={{ mb: 2, mt: 5 }}>
          {props.entries["tenant_list_title"]}
        </Typography>
        <Paper sx={{ minHeight: "400px", padding: "5px" }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    {props.entries["tenant_name_label"]}
                  </TableCell>
                  <TableCell component="th">
                    {props.entries["tenant_url_label"]}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {props.entries["tenant_edit_action"]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tenants?.map((tenant) => {
                  return (
                    <TableRow key={tenant.id}>
                      <TableCell>{tenant.name}</TableCell>
                      <TableCell>{tenant.url}</TableCell>
                      <TableCell align="center">
                        <ActivityActionButton
                          toolTip={props.entries["tenant_edit_action"]}
                          actionKey="editTenant"
                          callback={() => props.onEdit(tenant)}
                          icon={<Edit />}
                        />
                        <ActivityActionButton
                          toolTip={props.entries["tenant_view_users"]}
                          actionKey="viewUser"
                          callback={() => props.onShowUsers(tenant)}
                          icon={<PeopleAltOutlined />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
};
