import { observer } from "mobx-react-lite";
import { IUserManagementViewProps } from "./userManagement";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Label, StyledBox } from "../../controls/borderBox";

export const UserRolesView = observer(
  ({ viewModel }: IUserManagementViewProps) => {
    console.log(viewModel);
    return (
      <StyledBox marginTop={2}>
        <Label>Roles Allowed</Label>
        <Grid>
          {viewModel.currentUser?.roles.map((role) => {
            return (
              <Grid xs={6} key={role.roleId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={role.selected}
                      name={role.roleName}
                      onChange={(e) =>
                        viewModel.currentUser?.setUserRole(
                          role,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={role.roleName}
                />
              </Grid>
            );
          })}
        </Grid>
      </StyledBox>
    );
  }
);
