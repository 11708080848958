import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { FirebaseContextProvider } from "./core/firebase";
import { SignedInUserContextProvider } from "./core/signedInUser";
import { LanguageContextProvider } from "./core/language";
import { ThemeContextProvider } from "./core/theme";
import { ActivityContextProvider } from "./core/activityContext";
import { FavoritesContextProvider } from "./core/favoritesContext";
import { TodoContextProvider } from "./core/todoContext";
import { MSMEContextProvider } from "./core/msmeSelectionContext";

const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string | undefined;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <ThemeContextProvider>
        <FirebaseContextProvider>
          <SignedInUserContextProvider>
            <FavoritesContextProvider>
              <ActivityContextProvider>
                <TodoContextProvider>
                  <MSMEContextProvider>
                  <BrowserRouter basename={baseUrl}>
                    <App />
                  </BrowserRouter>
                  </MSMEContextProvider>
                </TodoContextProvider>
              </ActivityContextProvider>
            </FavoritesContextProvider>
          </SignedInUserContextProvider>
        </FirebaseContextProvider>
      </ThemeContextProvider>
    </LanguageContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
