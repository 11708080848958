import { observer } from "mobx-react";
import { FieldWrapperControl } from "../../../controls/fieldWrapperControl";
import { Box } from "@mui/material";
import { FieldWrapper } from "../../../common/formTypes";

export interface IVerifyConfirmProps {
  commentField: FieldWrapper;
}
export const VerifyConfirmView = observer((props: IVerifyConfirmProps) => {
  return (
    <Box sx={{ marginTop: "8px" }}>
      <FieldWrapperControl {...props.commentField} />
    </Box>
  );
});
