import { useContext } from "react";
import { TodoItemDto, TodoSectionDto } from "../utils/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ExpandMore, RefreshOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TodoContext } from "../core/todoContext";

export const TodoSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { todos, todoLabel, assignClicked, refreshTodo } =
    useContext(TodoContext);

  const getSectionLabel = (section: TodoSectionDto) => {
    return (
      <span>
        {section.section}{" "}
        <Badge
          sx={{ ml: 2 }}
          badgeContent={section.todoItems.length}
          color="primary"
        ></Badge>
      </span>
    );
  };

  const getTodoItem = (todoItem: TodoItemDto) => {
    return (
      <Card
        key={todoItem.code}
        elevation={3}
        sx={{ background: "transparent", mb: 1 }}
      >
        <CardContent>
          <Tooltip title={todoItem.title} placement="bottom">
            <Link
              component="button"
              onClick={() => navigate(todoItem.url)}
              align="left"
              sx={{
                maxWidth: "100%",
                flexWrap: "wrap",
                wordBreak: "break-word",
              }}
            >
              <Typography variant="caption"> {todoItem.title}</Typography>
            </Link>
          </Tooltip>
        </CardContent>
        <CardActions sx={{ flexWrap: "wrap" }}>
          <Typography variant="caption">{todoItem.assignedTo}</Typography>
          <Tooltip title={todoItem.assignActionTitle}>
            <IconButton onClick={() => assignClicked(todoItem)}>
              <Icon>assignment_add</Icon>
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  };

  const getTodoSection = (section: TodoSectionDto) => {
    return (
      <Accordion
        key={section.section}
        defaultExpanded={false}
        elevation={0}
        sx={{
          mb: 1,
          border: 1,
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="todo"
          id="todo"
          sx={{
            justifyContent: "flex-start",
            flexWrap: "wrap",
            wordBreak: "break-word",
          }}
        >
          <Typography variant="body1">{getSectionLabel(section)}</Typography>*
        </AccordionSummary>
        <AccordionDetails>
          {section.todoItems.map((item) => getTodoItem(item))}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mt: 1,
        mb: 1,
        p: 1,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box display="flex" flexDirection="row">
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ mt: 1, ml: 1, flex: 1 }}
        >
          {todoLabel}
        </Typography>

        <IconButton
          sx={{ alignSelf: "flex-end" }}
          onClick={() => refreshTodo()}
        >
          <RefreshOutlined />
        </IconButton>
      </Box>

      <TreeView
        aria-label="multi-select"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
      >
        {todos.map((section) => {
          return getTodoSection(section);
        })}
      </TreeView>
    </Paper>
  );
};
