import { ROUTES } from "../core/constants";

import {
  Language as LanguageIcon,
  Home,
  PaletteOutlined,
  Settings,
  Menu as MenuIcon,
} from "@mui/icons-material";

export enum MenuType {
  Name,
  Image,
  UserName,
  SettingDropDown,
  MenuItem,
  Icon,
  Language,
  Theme,
  SidebarButton,
  TaskbarButton,
}

export enum AuthType {
  None,
  SignedIn,
  SignedOut,
}

export interface IMenuItem {
  key: string;
  value: string;
  path: string;
  type: MenuType;
  authType: AuthType;
  childMenu: IMenuItem[] | undefined;
  icon: any;
}

export const icon: string =
  "https://cdn.shopify.com/s/files/1/1852/5423/files/Inclusive_trade_logo_4_155x.png?v=1679313183"; // TO DO this should actually be picked up from db for the tenant

export const defaultMenu: IMenuItem[] = [
  {
    key: "menu_sidebar",
    value: "", //icon,
    path: "",
    type: MenuType.SidebarButton,
    authType: AuthType.SignedIn,
    childMenu: undefined,
    icon: <MenuIcon />,
  },
  {
    key: "menu_icon",
    value: icon,
    path: ROUTES.LANDING,
    type: MenuType.Image,
    authType: AuthType.None,
    childMenu: undefined,
    icon: undefined,
  },
  {
    key: "menu_name",
    value: "Inclusive Trade",
    path: "",
    type: MenuType.Name,
    authType: AuthType.None,
    childMenu: undefined,
    icon: undefined,
  },
  {
    key: "menu_signin",
    value: "Sign In",
    path: ROUTES.SIGN_IN,
    type: MenuType.Icon,
    authType: AuthType.SignedOut,
    childMenu: undefined,
    icon: "account_circle",
  },
  {
    key: "menu_loggedinuser",
    value: "",
    path: ROUTES.DASHBOARD,
    type: MenuType.UserName,
    authType: AuthType.SignedIn,
    icon: Home,
    childMenu: undefined,
  },
  {
    key: "menu_setting",
    value: "Settings",
    path: "",
    type: MenuType.SettingDropDown,
    authType: AuthType.None,
    icon: <Settings />,
    childMenu: undefined,
  },
];

export const settingsMenuDefault: IMenuItem[] = [
  // {
  //   key: "menu_tnc",
  //   value: "T&C",
  //   path: ROUTES.TNC,
  //   type: MenuType.MenuItem,
  //   authType: AuthType.None,
  //   childMenu: undefined,
  //   icon: <Gavel />,
  // },
  // {
  //   key: "menu_privacy",
  //   value: "Privacy",
  //   path: ROUTES.PRIVACY,
  //   type: MenuType.MenuItem,
  //   authType: AuthType.None,
  //   childMenu: undefined,
  //   icon: <Security />,
  // },
  {
    key: "menu_language",
    value: "Language",
    path: "",
    type: MenuType.Language,
    authType: AuthType.None,
    childMenu: undefined,
    icon: <LanguageIcon />,
  },
  {
    key: "menu_theme",
    value: "Theme",
    path: "",
    type: MenuType.Theme,
    authType: AuthType.None,
    childMenu: undefined,
    icon: <PaletteOutlined />,
  },
  {
    key: "menu_signout",
    value: "Sign Out",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    childMenu: undefined,
    icon: "logout",
  },
];

export const SidebarBottomMenu: IMenuItem[] = [
  {
    key: "menu_taskbar",
    value: "Show Taskbar",
    path: "",
    type: MenuType.TaskbarButton,
    authType: AuthType.SignedIn,
    icon: "side_navigation",
    childMenu: undefined,
  },
];

export const adminActivities: IMenuItem[] = [
  /*{
    key: "menu_dashboard",
    value: "Dashboard",
    path: ROUTES.DASHBOARD,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Home />,
    childMenu: undefined,
  },*/
  {
    key: "U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manage_accounts",
    childMenu: undefined,
  },
  {
    key: "T_M_VIEW",
    value: "Tenants",
    path: ROUTES.TENANTS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
  {
    key: "SDG_M_VIEW",
    value: "SDG Management",
    path: ROUTES.SDGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "quiz",
    childMenu: undefined,
  },
  {
    key: "SDG_M_VIEW_BADGE",
    value: "Badges",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "military_tech",
    childMenu: undefined,
  },
  {
    key: "T_M_PROFILE",
    value: "Tenant Loginpage Settings",
    path: ROUTES.TENANTPROFILE,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "dashboard",
    childMenu: undefined,
  },
];

export const tenantActivities: IMenuItem[] = [
  {
    key: "menu_dashboard",
    value: "Dashboard",
    path: ROUTES.DASHBOARD,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "home",
    childMenu: undefined,
  },
  {
    key: "T_ACT_MSME_VIEW",
    value: "MSMEs",
    path: ROUTES.MSMES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "storefront",
    childMenu: undefined,
  },
  {
    key: "T_ACT_AG_VIEW",
    value: "Agencies",
    path: ROUTES.AGENCIES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "corporate_fare",
    childMenu: undefined,
  },
  {
    key: "T_ACT_CERT_VIEW",
    value: "Certifications",
    path: ROUTES.CERTIFICATIONS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "workspace_premium",
    childMenu: undefined,
  },
  /*
  {
    key: "T_ACT_FINANCIALS_VIEW",
    value: "Financials",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "credit_card",
    childMenu: undefined,
  },
  {
    key: "T_ACT_ANALYTICS",
    value: "Analytics",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
  */
  /*
  {
    key: "T_U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
  {
    key: "T_SDG_M_VIEW",
    value: "SDG Management",
    path: ROUTES.SDGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Quiz />,
    childMenu: undefined,
  },
  {
    key: "T_SDG_M_VIEW_BADGE",
    value: "Badges",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <MilitaryTech />,
    childMenu: undefined,
  },
  */
/*   {
    key: "T_ACT_COMMUNICATE",
    value: "Communications",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "inbox",
    childMenu: undefined,
  }, */
  {
    key: "T_ACT_SETTINGS",
    value: "Maintenance",
    path: ROUTES.SETTINGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manufacturing",
    childMenu: undefined,
  },
  /*
  {
    key: "T_M_PROFILE",
    value: "Tenant Loginpage Settings",
    path: ROUTES.TENANTPROFILE,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Web />,
    childMenu: undefined,
  }, */
];

export const msmeActivities: IMenuItem[] = [
  {
    key: "menu_dashboard",
    value: "Dashboard",
    path: ROUTES.DASHBOARD,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "home",
    childMenu: undefined,
  },
  {
    key: "MSME_ACT_VIEW_CERT",
    value: "Certifications",
    path: ROUTES.CERTIFICATIONS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "workspace_premium",
    childMenu: undefined,
  },
  /*
  {
    key: "MSME_ACT_VIEW_BILL",
    value: "Payments",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "credit_card",
    childMenu: undefined,
  },
  {
    key: "MSME_ACT_ANALYTICS",
    value: "Analytics",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
  */
/*   {
    key: "MSME_ACT_COMMUNICATE",
    value: "Messages",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "inbox",
    childMenu: undefined,
  }, */
  {
    key: "M_SETTINGS",
    value: "Maintenance",
    path: ROUTES.SETTINGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manufacturing",
    childMenu: undefined,
  },
];
export const settingsMsme: IMenuItem[] = [
  {
    key: "MSME_U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manage_accounts",
    childMenu: undefined,
  },
  /*{
    key: "MSME_SETTINGS_HOMEPAGE",
    value: "Homepage Layout",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "dashboard",
    childMenu: undefined,
  },*/
  {
    key: "MSME_SETTINGS_DETAILS",
    value: "Update MSME Detail",
    path: ROUTES.MSME_EDIT,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
];
export const settingsTenant: IMenuItem[] = [
  {
    key: "T_M_PROFILE",
    value: "Tenant Loginpage Settings",
    path: ROUTES.TENANTPROFILE,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "view_list",
    childMenu: undefined,
  },
  {
    key: "T_SETTINGS_HOMEPAGE",
    value: "Homepage Layout",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "dashboard",
    childMenu: undefined,
  },
  {
    key: "T_SETTINGS_ROLEPROFILE",
    value: "Role Profile",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "recent_actors",
    childMenu: undefined,
  },
  {
    key: "T_SETTINGS_APPROVALWORKFLOW",
    value: "Approval Workflow",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "approval",
    childMenu: undefined,
  },
  {
    key: "T_SDG_M_VIEW",
    value: "SDG Management",
    path: ROUTES.SDGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "editor_choice",
    childMenu: undefined,
  },
  {
    key: "T_SDG_M_VIEW_QUESTIONAIRE",
    value: "Questionnaire",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "dynamic_form",
    childMenu: undefined,
  },
  {
    key: "T_SDG_M_VIEW_BADGE",
    value: "Badges",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "military_tech",
    childMenu: undefined,
  },

  {
    key: "T_U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manage_accounts",
    childMenu: undefined,
  },
];
export const settingsAgency: IMenuItem[] = [
  {
    key: "AG_U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manage_accounts",
    childMenu: undefined,
  },
  {
    key: "AG_SETTINGS_HOMEPAGE",
    value: "Homepage Layout",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "home",
    childMenu: undefined,
  },
  {
    key: "AG_SETTINGS_DETAILS",
    value: "Update Agency Detail",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "room_preferences",
    childMenu: undefined,
  },
];
export const settingsActivities: IMenuItem[] = [
  {
    key: "menu_dashboard",
    value: "Dashboard",
    path: ROUTES.DASHBOARD,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "home",
    childMenu: undefined,
  },
  {
    key: "T_SETTINGS",
    value: "Settings",
    path: ROUTES.SETTINGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manufacturing",
    childMenu: undefined,
  },
];

export const agentActivities: IMenuItem[] = [
  {
    key: "menu_dashboard",
    value: "Dashboard",
    path: ROUTES.DASHBOARD,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "home",
    childMenu: undefined,
  },
  /*
  {
    key: "AG_U_M_VIEW",
    value: "Users",
    path: ROUTES.USERS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <People />,
    childMenu: undefined,
  },*/
  {
    key: "AG_ACT_VIEW_MSME",
    value: "MSMEs",
    path: ROUTES.MSMES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "storefront",
    childMenu: undefined,
  },
  {
    key: "AG_ACT_VIEW_CERT",
    value: "Certifications",
    path: ROUTES.CERTIFICATIONS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "workspace_premium",
    childMenu: undefined,
  },
  /*
  {
    key: "AG_ACT_VIEW_COMMISSION",
    value: "Commissions",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "credit_card",
    childMenu: undefined,
  },
  {
    key: "AG_ACT_ANALYTICS",
    value: "Analytics",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "finance",
    childMenu: undefined,
  },
  */
  {
    key: "AG_ACT_COMMUNICATE",
    value: "Communications",
    path: "",
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "inbox",
    childMenu: undefined,
  },
  /*
  {
    key: "AG_ACT_COMMUNICATE_TENANT",
    value: "Tenant Communications",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Sms />,
    childMenu: undefined,
  },
  
  {
    key: "AG_ACT_COMMUNICATE_MSME",
    value: "MSME Communications",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Chat />,
    childMenu: undefined,
  },
  */
  {
    key: "A_SETTINGS",
    value: "Maintenance",
    path: ROUTES.SETTINGS,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: "manufacturing",
    childMenu: undefined,
  },
  /*
  {
    key: "AG_ACT_VIEW_BILL",
    value: "Payments",
    path: ROUTES.BADGES,
    type: MenuType.MenuItem,
    authType: AuthType.SignedIn,
    icon: <Receipt />,
    childMenu: undefined,
  },*/
];
