import React, { ReactNode, useContext, useEffect, useState } from "react";
import AutoWidthBox from "./autoWidthBox";
import {
  AlertDialog,
  ComponentDialog,
  DialogType,
  ProgressDialog,
  YesNoDialog,
} from "./dialogs";
import { IBasePageViewModel } from "../common/pageTitleViewModel";
import { PageTitle } from "./pageTitle";
import { IDialogViewModel } from "../common/dialogViewModel";
import { ActivityContext, ActivitySection } from "../core/activityContext";
import { Card, CardContent, CardHeader } from "@mui/material";
import { observer } from "mobx-react";
import { useTheme } from "@mui/material/styles";
import useWindowDimensions from "../common/windowDimension";

interface IBasePageProps {
  children: ReactNode;
  pageDetails: IBasePageViewModel;
}

const BasePageView: React.FC<IBasePageProps> = ({ children, ...props }) => {
  const { registerActivities, deRegisterActivities } =
    useContext(ActivityContext);
  const theme = useTheme();
  useEffect(() => {
    if (props.pageDetails?.contextViewModel?.contextActivities) {
      registerActivities(
        ActivitySection.context,
        props.pageDetails.contextViewModel.contextActivities
      );
      return () => {
        if (props.pageDetails?.contextViewModel?.contextActivities) {
          deRegisterActivities(
            ActivitySection.context,
            props.pageDetails.contextViewModel.contextActivities
          );
        }
      };
    }
  }, [
    props.pageDetails?.contextViewModel?.contextActivities,
    registerActivities,
    deRegisterActivities,
  ]);

  return (
    <AutoWidthBox
      widthOverride={props.pageDetails?.widthOverride ?? undefined}
      paddingOverride="8px"
    >
      <Card
        variant="outlined"
        sx={{
          mt: "4px",
          borderColor: theme.palette.primary.light,
          color: "inherit",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CardHeader
          sx={{
            backgroundColor: theme.palette.primary.light,
          }}
          title={<PageTitle {...props.pageDetails?.pageTitleViewModel} />}
        ></CardHeader>
        {/*         <CardContent sx={{maxHeight:`calc(${windowheight-190}px)`, overflow:"auto"}}>
         */}{" "}
        <CardContent>
          {children}
          <PageDialog {...props.pageDetails?.dialogViewModel} />
        </CardContent>
      </Card>
    </AutoWidthBox>
  );
};

const PageDialog = observer((viewModel: IDialogViewModel) => {
  const [isOkAllowed] = useState<boolean>(viewModel.okAllowed);
  if (viewModel?.dialogType === DialogType.Alert) {
    return (
      <AlertDialog
        isOpen={viewModel.hasMessage}
        title={viewModel.dialogTitle}
        description={viewModel.dialogMessage}
        handleClose={viewModel.handleDialogClose}
        isOkAllowed={isOkAllowed}
      />
    );
  } else if (viewModel?.dialogType === DialogType.YesNo) {
    return (
      <YesNoDialog
        isOpen={viewModel.hasMessage}
        title={viewModel.dialogTitle}
        description={viewModel.dialogMessage}
        handleClose={viewModel.handleDialogClose}
        isOkAllowed={isOkAllowed}
      />
    );
  } else if (viewModel?.dialogType === DialogType.Progress) {
    return (
      <ProgressDialog
        isOpen={viewModel.hasMessage}
        title={viewModel.dialogTitle}
      />
    );
  } else if (viewModel?.dialogType === DialogType.ComponentDialog) {
    return (
      <ComponentDialog
        isOpen={viewModel.hasMessage}
        title={viewModel.dialogTitle}
        description={viewModel.dialogMessage}
        handleClose={viewModel.handleDialogClose}
        componentProps={viewModel.componentProps}
        isOkAllowed={viewModel.okAllowed}
      />
    );
  }
  return null;
});

export const BasePage = observer(BasePageView);
