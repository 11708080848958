import { observer } from "mobx-react";
import { FieldWrapperControl } from "../../controls/fieldWrapperControl";
import { BusinessEditFormModel } from "./msmeEditViewModel";

export const UserInformation = observer(
  (props: { model: BusinessEditFormModel | undefined }) => {
    if (!props.model) {
      return null;
    }
    return (
      <>
        <FieldWrapperControl {...props.model.firstName} />
        <FieldWrapperControl {...props.model.lastName} />
        <FieldWrapperControl {...props.model.email} />
        <FieldWrapperControl {...props.model.password} />
        <FieldWrapperControl {...props.model.confirmPassword} />
      </>
    );
  }
);
