import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { IUserManagementViewProps } from "./userManagement";
import { UserRolesView } from "./userPermissions";

export const UserView = observer(({ viewModel }: IUserManagementViewProps) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box width="70%" sx={{ pt: 5 }}>
        <Paper sx={{ p: 2 }} elevation={5}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {viewModel.pageEntries["usermng_detail_title"]}
          </Typography>
          <Box alignContent="center" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              error={viewModel.currentUser?.hasFirstNameError}
              helperText={
                viewModel.currentUser?.hasFirstNameError
                  ? viewModel.pageEntries["usermng_fname_error"]
                  : ""
              }
              required
              fullWidth
              name="firstName"
              label={viewModel.pageEntries["usermng_fname_label"]}
              type="text"
              id="firstName"
              value={viewModel.currentUser?.firstName}
              onChange={(e) =>
                viewModel.currentUser?.setValues(e.target.name, e.target.value)
              }
            />
            <TextField
              margin="normal"
              error={viewModel.currentUser?.hasLastNameError}
              helperText={
                viewModel.currentUser?.hasLastNameError
                  ? viewModel.pageEntries["usermng_lname_error"]
                  : ""
              }
              required
              fullWidth
              name="lastName"
              label={viewModel.pageEntries["usermng_lname_label"]}
              type="text"
              id="lastName"
              value={viewModel.currentUser?.lastName}
              onChange={(e) =>
                viewModel.currentUser?.setValues(e.target.name, e.target.value)
              }
            />
            <TextField
              margin="normal"
              error={viewModel.currentUser?.hasEmailError}
              helperText={
                viewModel.currentUser?.hasEmailError
                  ? viewModel.pageEntries["usermng_email_error"]
                  : ""
              }
              required
              fullWidth
              name="email"
              label={viewModel.pageEntries["usermng_email_label"]}
              type="text"
              id="email"
              disabled={(viewModel.currentUser?.id ?? "") !== ""}
              value={viewModel.currentUser?.email}
              onChange={(e) =>
                viewModel.currentUser?.setValues(e.target.name, e.target.value)
              }
            />
            {viewModel.permissionsAllowed &&
              (viewModel.currentUser?.roles?.length ?? 0) > 0 && (
                <UserRolesView viewModel={viewModel} />
              )}
            <Box display="flex" sx={{ mt: 2 }}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={viewModel.saveUser}
                disabled={!viewModel.currentUser?.canSave}
              >
                {viewModel.pageEntries["usermng_save_action"]}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ ml: 2 }}
                onClick={viewModel.cancelSave}
              >
                {viewModel.pageEntries["usermng_cancel_action"]}
              </Button>
            </Box>
            {viewModel.currentUser?.hasServerError && (
              <Typography
                component="div"
                variant="body2"
                color="error"
                sx={{ mt: 3, alignContent: "center" }}
              >
                {viewModel.currentUser?.serverError}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
});
