import { IActivityAction } from "../../controls/activityToolbar";
import { ISignedInUserContext } from "../../core/signedInUser";
import { IKeyedObject, ILanguageContext, PageMode } from "../../core/types";
import {
  IUserDto,
  ResultOfListOfUserDto,
  ResultOfUserDto,
  TenantUserClient,
  UserAccountType,
  UserDto,
  UserRoleDto,
} from "../../utils/api";
import { makeAutoObservable, runInAction } from "mobx";

import { AddCircle, ListAlt } from "@mui/icons-material";
import { Language } from "../../core/language";
import { IAuthContext } from "../../core/firebase";

const userActivities: IKeyedObject<IActivityAction> = {
  view: {
    actionKey: "user_activity_list",
    icon: <ListAlt />,
    toolTip: "View Users",
  },
  add: {
    actionKey: "user_activity_add",
    icon: <AddCircle />,
    toolTip: "Add User",
  },
};

const userPageEntries: IKeyedObject<string> = {
  usermng_activity_title: "User Management",
  usermng_activity_add: "Add User",
  usermng_activity_list: "View Userss",
  usermng_list_title: "Users",
  usermng_edit_action: "Edit",
  usermng_permission_action: "Manage Permissions",
  usermng_delete_action: "Delete",
  usermng_save_action: "Save",
  usermng_cancel_action: "Cancel",
  usermng_actions_label: "Actions",
  usermng_detail_title: "User Details",
  usermng_fname_label: "First Name",
  usermng_lname_label: "Last Name",
  usermng_email_label: "Email",
  usermng_fname_error: "First Name is required",
  usermng_lname_error: "Last Name is required",
  usermng_email_error: "Email is required",
  usermng_passwordreset_action: "Send Password Reset Email",
  usermng_pwdresetconfirmation_message:
    "Are you sure, you want to reset password for",
  usermng_passwordreset_title: "Reset Password",
  usermng_passwordreset_success: "Password reset email sent",
  usermng_passwordreset_failure: "Could not send Password reset email",
};

export interface IUserManagementVM {
  pageEntries: IKeyedObject<string>;
  activities: IActivityAction[];
  users: UserDto[];
  pageMode: PageMode;
  currentUser: UserViewModel | undefined;
  editAllowed: boolean;
  viewAllowed: boolean;
  deleteAllowed: boolean;
  permissionsAllowed: boolean;
  addAllowed: boolean;
  hasMessage: boolean;
  dialogMessage: string;
  dialogTitle: string;
  resetDialogOpen: boolean;
  passwordResetEmail: string;
  onActivityCallback: (key: string) => void;
  editUser: (id: string) => void;
  editPermissions: (id: string) => void;
  saveUser: () => void;
  cancelSave: () => void;
  resetPassword: (email: string) => void;
  handlePasswordResetConfirmation: (confirmed: boolean) => void;
  handleDialogClose: () => void;
}

export class UserManagementViewModel implements IUserManagementVM {
  pageEntries: IKeyedObject<string> = {};
  pageMode = PageMode.List;
  currentUser: UserViewModel | undefined;
  users: UserDto[] = [];
  activities: IActivityAction[] = [];
  addAllowed: boolean = false;
  editAllowed: boolean = false;
  viewAllowed: boolean = false;
  deleteAllowed: boolean = false;
  permissionsAllowed: boolean = false;
  hasMessage: boolean = false;

  dialogMessage: string = "";
  dialogTitle: string = "";
  resetDialogMessage: string = "";
  resetDialogTitle: string = "";
  resetDialogOpen: boolean = false;
  passwordResetEmail: string = "";

  handleDialogClose = () => {
    this.clearDialog();
  };

  handlePasswordResetConfirmation = (confirmed: boolean) => {
    this.resetDialogOpen = false;
    if (confirmed) {
      this.sendPasswordResetEmail(this.passwordResetEmail)
        .then((message) => {
          if (message) {
            this.setDialog(
              this.pageEntries["usermng_passwordreset_title"],
              this.pageEntries["usermng_passwordreset_failure"]
            );
          } else {
            this.setDialog(
              this.pageEntries["usermng_passwordreset_title"],
              this.pageEntries["usermng_passwordreset_success"]
            );
          }
        })
        .finally(() => (this.passwordResetEmail = ""));
    }
  };

  resetPassword = (email: string) => {
    this.passwordResetEmail = email;
    this.resetDialogOpen = true;
  };

  getUserModel = () => {
    const user = new UserDto();
    user.email = this.currentUser?.email ?? "";
    user.firstName = this.currentUser?.firstName ?? "";
    user.lastName = this.currentUser?.lastName ?? "";
    user.id = this.currentUser?.id ?? "";
    user.roles = this.currentUser?.roles ?? [];
    return user;
  };

  handleSaveResult = (result: ResultOfUserDto) => {
    if (result.succeeded && result.value) {
      const index = this.users.findIndex((x) => x.id === result.value?.id);
      if (index === -1) {
        this.users.push(result.value);
      } else {
        this.users[index] = result.value;
      }
      this.pageMode = PageMode.List;
    } else {
      this.setSaveError(result.error);
    }
  };

  setSaveError = (reason: string) => {
    this.currentUser?.setServerError(reason);
  };

  saveUser = () => {
    if (this.currentUser === undefined) {
      return;
    }
    this.currentUser.setIsSaving(true);
    const userModel = this.getUserModel();
    const client = new TenantUserClient();
    if (this.pageMode === PageMode.Add) {
      client
        .createUser(this.selectedLanguage, userModel)
        .then((result) => this.handleSaveResult(result))
        .catch((reason) => this.setSaveError(reason.toString()));
    } else {
      client
        .updateUser(this.selectedLanguage, userModel)
        .then((result) => this.handleSaveResult(result))
        .catch((reason) => this.setSaveError(reason.toString()));
    }
    this.currentUser.setIsSaving(false);
  };

  cancelSave = () => {
    this.pageMode = PageMode.List;
  };
  onActivityCallback = (key: string) => {
    if (key === "user_activity_list") {
      this.currentUser = undefined;
      this.pageMode = PageMode.List;
    } else {
      this.currentUser = new UserViewModel(undefined, this.pageEntries);
      this.pageMode = PageMode.Add;
    }
  };

  editPermissions = (id: string) => {
    this.pageMode = PageMode.Custom;
  };

  editUser = (id: string) => {
    const user = this.users.find((x) => x.id === id);
    if (user) {
      this.currentUser = new UserViewModel(user, this.pageEntries);
      this.pageMode = PageMode.Edit;
    }
  };

  constructor(
    private selectedLanguage: string,
    private translate: ILanguageContext["translate"],
    private signedInUser: ISignedInUserContext["signedInUser"],
    private sendPasswordResetEmail: IAuthContext["sendPasswordResetEmail"]
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.setPermissions();
      this.translateEntries();
      this.setActivities();
      this.fetchData();
    });
  }

  setPermissions() {
    this.signedInUser?.activitiesAllowed.forEach((activity) => {
      if (this.signedInUser?.userType === UserAccountType.AppUser) {
        if (activity === "U_M_ADD") {
          this.addAllowed = true;
        } else if (activity === "U_M_EDIT") {
          this.editAllowed = true;
        } else if (activity === "U_M_DELETE") {
          this.deleteAllowed = true;
        } else if (activity === "U_M_VIEW") {
          this.viewAllowed = true;
        } else if (activity === "U_M_MANAGE_PERMISSION") {
          this.permissionsAllowed = true;
        }
      } else if (this.signedInUser?.userType === UserAccountType.TenantUser) {
        if (activity === "T_U_M_ADD") {
          this.addAllowed = true;
        } else if (activity === "T_U_M_EDIT") {
          this.editAllowed = true;
        } else if (activity === "T_U_M_DELETE") {
          this.deleteAllowed = true;
        } else if (activity === "T_U_M_VIEW") {
          this.viewAllowed = true;
        } else if (activity === "T_U_M_MANAGE_PERMISSION") {
          this.permissionsAllowed = true;
        }
      } else if (this.signedInUser?.userType === UserAccountType.AgentUser) {
        if (activity === "AG_U_M_ADD") {
          this.addAllowed = true;
        } else if (activity === "AG_U_M_EDIT") {
          this.editAllowed = true;
        } else if (activity === "AG_U_M_DELETE") {
          this.deleteAllowed = true;
        } else if (activity === "AG_U_M_VIEW") {
          this.viewAllowed = true;
        } else if (activity === "AG_U_M_MANAGE_PERMISSION") {
          this.permissionsAllowed = true;
        }
      } else if (this.signedInUser?.userType === UserAccountType.MsmeUser) {
        if (activity === "MSME_U_M_ADD") {
          this.addAllowed = true;
        } else if (activity === "MSME_U_M_EDIT") {
          this.editAllowed = true;
        } else if (activity === "MSME_U_M_DELETE") {
          this.deleteAllowed = true;
        } else if (activity === "MSME_U_M_VIEW") {
          this.viewAllowed = true;
        } else if (activity === "MSME_U_M_MANAGE_PERMISSION") {
          this.permissionsAllowed = true;
        }
      }
    });
  }

  translateEntries() {
    this.pageEntries = this.translate(
      Language.EntryCategory.UserManagement,
      userPageEntries
    );
  }

  setActivities() {
    const pgActivities: IActivityAction[] = [];
    if (this.viewAllowed) {
      pgActivities.push(userActivities["view"]);
    }

    if (this.addAllowed) {
      pgActivities.push(userActivities["add"]);
    }

    pgActivities.forEach((activity) => {
      const translation =
        this.pageEntries[activity.actionKey] ?? activity.toolTip;
      this.activities.push({ ...activity, toolTip: translation });
    });
  }

  clearDialog = () => {
    this.hasMessage = false;
    this.dialogTitle = "";
    this.dialogMessage = "";
  };

  setDialog = (title: string, message: string) => {
    this.hasMessage = true;
    this.dialogTitle = title;
    this.dialogMessage = message;
  };

  setUsers(result: ResultOfListOfUserDto) {
    if (result.succeeded) {
      this.clearDialog();
      this.users = result.value ?? [];
    } else {
      this.setDialog(result.errorCode, result.error);
    }
  }

  fetchData() {
    const client = new TenantUserClient();
    client
      .getUsers(this.selectedLanguage)
      .then((result) => this.setUsers(result))
      .catch((reason) => {
        this.hasMessage = true;
        this.dialogMessage = reason;
        this.dialogTitle = reason;
      });
  }
}

interface IUserViewModel extends IUserDtoForm {
  isSaving: boolean;
  canSave: boolean;
  setValues: (target: string, value: any) => void;
  setUserRole: (role: UserRoleDto, selected: boolean) => void;
  hasError: boolean;
  hasServerError: boolean;
  serverError: string;
}

class UserViewModel implements IUserViewModel {
  firstNameLabel: string = "";
  lastNameLabel: string = "";
  emailLabel: string = "";
  hasFirstNameError: boolean = false;
  hasLastNameError: boolean = false;
  hasEmailError: boolean = false;
  id: string = "";
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  isSaving: boolean = false;
  roles: UserRoleDto[] = [];
  hasServerError: boolean = false;
  serverError: string = "";

  get canSave() {
    return !(
      this.isSaving ||
      this.hasFirstNameError ||
      this.hasLastNameError ||
      this.hasEmailError
    );
  }
  get hasError() {
    return this.hasServerError;
  }

  setIsSaving = (saving: boolean) => {
    this.serverError = "";
    this.hasServerError = false;
    this.isSaving = saving;
  };

  setServerError = (reason: string) => {
    this.serverError = reason;
    this.hasServerError = true;
  };

  setUserRole = (role: UserRoleDto, selected: boolean) => {
    this.roles = this.roles.map((x) => {
      if (x.roleId === role.roleId) {
        x.selected = selected;
      }
      return x;
    });
  };

  setValues = (fieldName: string, value: any) => {
    if (fieldName === "firstName") {
      this.firstName = value;
      this.hasFirstNameError = !value;
    } else if (fieldName === "lastName") {
      this.lastName = value;
      this.hasLastNameError = !value;
    } else if (fieldName === "email") {
      this.email = value;
      this.hasEmailError = !value;
    }
  };

  constructor(
    private userDto: UserDto | undefined,
    private entries: IKeyedObject<string>
  ) {
    makeAutoObservable(this);
    this.firstNameLabel = entries["usermng_fname_label"];
    this.lastNameLabel = entries["usermng_lname_label"];
    this.emailLabel = entries["usermng_email_label"];
    this.firstName = userDto?.firstName ?? "";
    this.lastName = userDto?.lastName ?? "";
    this.email = userDto?.email ?? "";
    this.id = userDto?.id ?? "";
    this.roles = userDto?.roles ?? [];
  }
}

interface IUserDtoForm extends IUserDto {
  firstNameLabel: string;
  lastNameLabel: string;
  emailLabel: string;
  hasFirstNameError: boolean;
  hasLastNameError: boolean;
  hasEmailError: boolean;
}
