import { Box } from "@mui/material";
import { BusinessEditFormModel } from "./msmeEditViewModel";
import { FieldWrapperControl } from "../../controls/fieldWrapperControl";
import { observer } from "mobx-react";

export const AdditionalInformation = observer(
  (props: { model: BusinessEditFormModel | undefined }) => {
    if (!props.model) {
      return null;
    }
    return (
      <>
        <Box sx={{ mt:2, mb: 2 }}>
          <FieldWrapperControl {...props.model.businessTypeId} />
        </Box>
        <FieldWrapperControl {...props.model.businessSectorId} />
        <FieldWrapperControl {...props.model.maleFemaleSplit} />
        <FieldWrapperControl {...props.model.numberOfDirectors} />
        <FieldWrapperControl {...props.model.registrationNumber} />
      </>
    );
  }
);
