import {
  Icon,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  AuthType,
  IMenuItem,
  MenuType,
  settingsMenuDefault,
} from "./menuTypes";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext, useAuth } from "../core/firebase";
import { Language, LanguageContext } from "../core/language";
import { ThemeContext } from "../core/theme";
import {
  IChildMenuItem,
  INestedMenuItem,
  NestedMenuItem,
} from "../controls/nestedMenu";

export interface ISettingsMenuProps {
  anchorElement?: Element;
  handleMenuClose: () => void;
}
export const SettingsMenu = (props: ISettingsMenuProps) => {
  const { authToken } = useContext(FirebaseContext);
  const { selectedLanguage, getCategory, changeLanguage, getLanguages } =
    useContext(LanguageContext);
  const [settingsMenu, setSettingsMenu] = useState<IMenuItem[]>([]);
  const { changeTheme, getAvailableThemes, theme } = useContext(ThemeContext);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const categories = getCategory(Language.EntryCategory.MainMenu);
    const setMenuLanguage = (menuItem: IMenuItem) => {
      const lMenu = categories[menuItem.key];
      if (lMenu) {
        if (menuItem.childMenu) {
          const childMenu: IMenuItem[] = menuItem.childMenu.map((child) =>
            setMenuLanguage(child)
          );
          return { ...menuItem, value: lMenu.value, childMenu: childMenu };
        }
        return { ...menuItem, value: lMenu.value };
      }
      return menuItem;
    };

    if (selectedLanguage) {
      const setsMenu = settingsMenuDefault.map((menu) => setMenuLanguage(menu));
      setSettingsMenu(setsMenu);
    }
  }, [selectedLanguage, getCategory]);

  const onMenuClick = (menu: IMenuItem) => {
    props.handleMenuClose();
    if (menu.key === "menu_signout") {
      signOut();
    } else {
      navigate(menu.path);
    }
  };

  const getMenuItem = (menu: IMenuItem): JSX.Element => {
    return (
      <MenuItem key={menu.key} onClick={() => onMenuClick(menu)}>
        <ListItemButton
          sx={{
            minHeight: 48,
            px: 2.5,
          }}
        >
          <Icon className="material-symbols-outlined">{menu.icon}</Icon>
        </ListItemButton>
        <ListItemText primary={menu.value} />
      </MenuItem>
    );
  };

  const onNestedItemClicked = (menu: IMenuItem, child: IChildMenuItem) => {
    if (menu.key === "menu_language" && child.value !== selectedLanguage) {
      changeLanguage(child.value);
    } else if (menu.key === "menu_theme" && child.value !== theme) {
      changeTheme(child.value);
    }
    props.handleMenuClose();
  };

  const getLangugeMenu = (menu: IMenuItem): JSX.Element => {
    const languages = getLanguages();
    const nestedMenu: INestedMenuItem = {
      menu: menu,
      childItems: languages.map((x) => {
        return { value: x, key: x, isSelected: x === selectedLanguage };
      }),
      onNestedItemClicked: onNestedItemClicked,
      showIsSelected: true,
    };
    return <NestedMenuItem key={menu.key} {...nestedMenu} />;
  };

  const getThemeMenu = (menu: IMenuItem): JSX.Element => {
    const themes = getAvailableThemes();
    const nestedMenu: INestedMenuItem = {
      menu: menu,
      childItems: themes.map((x) => {
        return { value: x, key: x, isSelected: x === theme.palette.mode };
      }),
      onNestedItemClicked: onNestedItemClicked,
      showIsSelected: true,
    };
    return <NestedMenuItem key={menu.key} {...nestedMenu} />;
  };

  const getAppBarItem = (menu: IMenuItem): null | JSX.Element => {
    if (menu.authType === AuthType.SignedIn && !authToken) {
      return null;
    } else if (menu.authType === AuthType.SignedOut && authToken) {
      return null;
    }
    if (menu.type === MenuType.MenuItem) {
      return getMenuItem(menu);
    } else if (menu.type === MenuType.Language) {
      return getLangugeMenu(menu);
    } else if (menu.type === MenuType.Theme) {
      return getThemeMenu(menu);
    }

    return null;
  };

  return (
    <Menu
      key="navbar-setting-menu"
      anchorEl={props.anchorElement}
      open={Boolean(props.anchorElement)}
      onClose={props.handleMenuClose}
    >
      {settingsMenu.map((item) => {
        return getAppBarItem(item);
      })}
    </Menu>
  );
};
