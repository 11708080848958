import {
  Toolbar,
  Typography,
  useMediaQuery,
  Tooltip,
  Link,
  Icon,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { FirebaseContext } from "../core/firebase";
import { Language, LanguageContext } from "../core/language";
import { SignedInUserContext } from "../core/signedInUser";
import { AuthType, IMenuItem, MenuType, defaultMenu } from "./menuTypes";
import { Link as RouterLink } from "react-router-dom";

export const MainMenu = (props: {
  open: boolean;
  handleDrawerOpen: () => void;
  onSettingsMenuClick: (event: any) => void;
}) => {
  const { authToken } = useContext(FirebaseContext);
  const { signedInUser } = useContext(SignedInUserContext);
  const { selectedLanguage, getCategory } = useContext(LanguageContext);
  const [menu, setMenu] = useState<IMenuItem[]>([]);
  const isScreenSmall = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const categories = getCategory(Language.EntryCategory.MainMenu);

    const setMenuLanguage = (menuItem: IMenuItem) => {
      const lMenu = categories[menuItem.key];
      if (lMenu) {
        if (menuItem.childMenu) {
          const childMenu: IMenuItem[] = menuItem.childMenu.map((child) =>
            setMenuLanguage(child)
          );
          return { ...menuItem, value: lMenu.value, childMenu: childMenu };
        }
        return { ...menuItem, value: lMenu.value };
      }
      return menuItem;
    };

    if (selectedLanguage) {
      const menuItems = defaultMenu.map((menuItem) => {
        return setMenuLanguage(menuItem);
      });
      setMenu(menuItems);
    }
  }, [selectedLanguage, getCategory]);

  const getImage = (menu: IMenuItem): JSX.Element => {
    return (
      <RouterLink to={menu.path} key={menu.key}>
        <img src={menu.value} width="24" height="24" alt="Icon" />
      </RouterLink>
    );
  };

  const getName = (menu: IMenuItem) => {
    if (isScreenSmall) {
      return (
        <Typography
          key={menu.key}
          fontSize="small"
          sx={{ flexGrow: 1, marginLeft: "10px" }}
        >
          {menu.value}
        </Typography>
      );
    }
    return (
      <Typography
        key={menu.key}
        fontSize="medium"
        sx={{ flexGrow: 1, marginLeft: "10px" }}
      >
        {menu.value}
      </Typography>
    );
  };

  const getIcon = (menu: IMenuItem): JSX.Element => {
    return (
      <div key={menu.key}>
        <Tooltip title={menu.value}>
          <Link
            color="inherit"
            component={RouterLink}
            to={menu.path}
            marginLeft={1}
            marginTop={1}
            underline="none"
          >
            <Icon fontSize="medium" sx={{ marginLeft: "10px" }}>
              {menu.icon}
            </Icon>
          </Link>
        </Tooltip>
      </div>
    );
  };
  const getUserName = (menu: IMenuItem): JSX.Element => {
    return (
      <Typography key={menu.key} fontSize="small">
        {signedInUser?.displayName}
      </Typography>
    );
  };

  const getSettingDropDownMenu = (menu: IMenuItem): JSX.Element => {
    return (
      <div key={menu.key} onClick={props.onSettingsMenuClick}>
        <Tooltip title={menu.value}>
          <Icon fontSize="medium" sx={{ marginLeft: "10px" }}>
            {menu.icon}
          </Icon>
        </Tooltip>
      </div>
    );
  };

  const getSidebarButton = (menu: IMenuItem): JSX.Element | null => {
    if (props.open) {
      return null;
    }
    return (
      <div key={menu.key} onClick={props.handleDrawerOpen}>
        <Tooltip title={menu.value}>
          <Icon
            fontSize="medium"
            sx={{ marginLeft: "-6px", marginRight: "45px" }}
          >
            {menu.icon}
          </Icon>
        </Tooltip>
      </div>
    );
  };

  const getAppBarItem = (menu: IMenuItem): null | JSX.Element => {
    if (menu.authType === AuthType.SignedIn && !authToken) {
      return null;
    } else if (menu.authType === AuthType.SignedOut && authToken) {
      return null;
    }

    if (menu.type === MenuType.Image) {
      return getImage(menu);
    } else if (menu.type === MenuType.Name) {
      return getName(menu);
    } else if (menu.type === MenuType.UserName) {
      return getUserName(menu);
    } else if (menu.type === MenuType.Icon) {
      return getIcon(menu);
    } else if (menu.type === MenuType.SettingDropDown) {
      return getSettingDropDownMenu(menu);
    } else if (menu.type === MenuType.SidebarButton) {
      return getSidebarButton(menu);
    }
    return null;
  };

  return (
    <Toolbar>
      {menu.map((item) => {
        return getAppBarItem(item);
      })}
    </Toolbar>
  );
};
