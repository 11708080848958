import { makeAutoObservable, runInAction } from "mobx";
import { HomepageDto, TenantClient } from "../../utils/api";

interface ILandingViewModel {
  model: HomepageDto | undefined;
}
export class LandingViewModel implements ILandingViewModel {
  model: HomepageDto | undefined;

  constructor(private selectedLanguage: string) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }
  fetchData = () => {
    const client = new TenantClient();
    client
      .getHomepage(this.selectedLanguage)
      .then((result) => {
        this.model = result.value;
      })
      .catch((reason) => {});
  };
}
