import { makeAutoObservable, runInAction } from "mobx";
import {
  IBaseScreenDto,
  ResultOfBusinessManagementDto,
  BusinessClient,
  BusinessManagementDto,
} from "../../utils/api";
import {
  IContextSensitiveViewModel,
  ContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import { DialogType } from "../../controls/dialogs";
export interface IBusinessListViewModel extends IBasePageViewModel {
  model: BusinessManagementDto | undefined;
}
export class BusinessListViewModel implements IBusinessListViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  model: BusinessManagementDto | undefined = undefined;

  constructor(
    private selectedLanguage: string,
    companyType: number
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData(companyType);
    });
  }
  setBusiness = (result: ResultOfBusinessManagementDto) => {
    if (result.succeeded) {
      this.dialogViewModel.clearDialog();
      this.model = result.value;
      this.contextViewModel.setContextActivities(
        this.model?.contextActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        this.model as IBaseScreenDto,
        undefined
      );
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };
  fetchData = (companyType: number) => {
    const client = new BusinessClient();
    if (companyType === 0) {
      client
        .getMSMEManagementModel(this.selectedLanguage)
        .then((result) => this.setBusiness(result))
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    } else {
      client
        .getAgencyManagementModel(this.selectedLanguage)
        .then((result) => this.setBusiness(result))
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    }
  };
}
