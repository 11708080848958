import { makeAutoObservable, runInAction } from "mobx";
import { IBaseScreenDto,
     SettingsClient, ResultOfSettingsMenuDto,SettingsMenuDto } 
     from "../../utils/api";
     import {
  IContextSensitiveViewModel,
  ContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import { DialogType } from "../../controls/dialogs";
export interface ISettingsMenuListViewModel extends IBasePageViewModel {
    model: SettingsMenuDto | undefined;
}
export class SettingsMenuListViewModel implements ISettingsMenuListViewModel 
{
    contextViewModel: IContextSensitiveViewModel=new ContextSensitiveViewModel();
    dialogViewModel: IDialogViewModel=new DialogViewModel();
    pageTitleViewModel: IPageTitleViewModel=new PageTitleViewModel();
    model: SettingsMenuDto | undefined = undefined;

    constructor (
        private selectedLanguage:string
    )
    {
        makeAutoObservable(this);
        runInAction(()=> {
            this.fetchData();    
        })
    }
    setMenus=(result:ResultOfSettingsMenuDto) => {
        if (result.succeeded) {
            this.dialogViewModel.clearDialog();
            this.model=result.value;
            this.contextViewModel.setContextActivities(this.model?.contextActions??[]);
            this.pageTitleViewModel.setPageDetails(this.model as IBaseScreenDto, undefined);
        }
        else {
            this.dialogViewModel.setDialog(
                    result.errorCode,
                    result.error,
                    DialogType.Alert
                );            
        }
    };
    fetchData=() => {
      const client=new SettingsClient();
      client.getSettingMenusModel(this.selectedLanguage)
      .then((result)=> this.setMenus(result))
      .catch((reason)=> {
        this.dialogViewModel.setDialog(reason,reason,DialogType.Alert);
      });  
    };
}