import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { IconButton, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader } from "../layouts/layout";
import { Drawer } from "../layouts/navbar";
import { FirebaseContext } from "../core/firebase";
import { SignedInUserContext } from "../core/signedInUser";
import { LanguageEntryDto, UserAccountType } from "../utils/api";
import { useContext, useEffect, useState } from "react";
import {
  IMenuItem,
  MenuType,
  SidebarBottomMenu,
  agentActivities,
  msmeActivities,
  settingsActivities,
  tenantActivities,
} from "../layouts/menuTypes";
import { useNavigate } from "react-router-dom";
import { Language, LanguageContext } from "../core/language";
import { Box, Icon, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ActivityContext } from "../core/activityContext";
import { desktopWidth } from "../core/constants";

interface ISideBarProps {
  open: boolean;
  onDrawerClose: () => void;
}

const getMenuItem = (
  menu: IMenuItem,
  onMenuClick: (menu: IMenuItem) => void,
  open: boolean
) => {
  return (
    <ListItem
      key={menu.key}
      disablePadding
      sx={{ display: "block" }}
      onClick={() => onMenuClick(menu)}
    >
      <Tooltip title={menu.value} placement="right">
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <Icon className="material-symbols-outlined">{menu.icon}</Icon>
          <ListItemText primary={menu.value} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export default function SideBar(props: ISideBarProps) {
  const { authToken } = useContext(FirebaseContext);
  const { signedInUser } = useContext(SignedInUserContext);
  const [activities, setActivities] = useState<IMenuItem[]>([]);
  const [bottomMenu, setBottomMenu] = useState<IMenuItem[]>([]);
  const { selectedLanguage, getCategory } = useContext(LanguageContext);
  const [categories, setCategories] = useState<
    { [key: string]: LanguageEntryDto } | undefined
  >(undefined);
  const navigate = useNavigate();
  const open = props.open;
  const theme = useTheme();
  const { toggleTaskBar } = useContext(ActivityContext);

  const isDesktop = useMediaQuery("(min-width:" + desktopWidth + "px)");

  useEffect(() => {
    setCategories(getCategory(Language.EntryCategory.UserMenu));
  }, [getCategory]);

  useEffect(() => {
    let userActivities: IMenuItem[] = [];
    if (signedInUser?.userType === UserAccountType.AppUser) {
      userActivities = settingsActivities;
    } else if (signedInUser?.userType === UserAccountType.TenantUser) {
      userActivities = tenantActivities;
    } else if (signedInUser?.userType === UserAccountType.MsmeUser) {
      userActivities = msmeActivities;
    } else if (signedInUser?.userType === UserAccountType.AgentUser) {
      userActivities = agentActivities;
    }
    const setMenuLanguage = (menuItem: IMenuItem) => {
      if (!categories) {
        return menuItem;
      }

      const lMenu = categories[menuItem.key];

      if (lMenu) {
        if (menuItem.childMenu) {
          const childMenu: IMenuItem[] = menuItem.childMenu.map((child) =>
            setMenuLanguage(child)
          );
          return { ...menuItem, value: lMenu.value, childMenu: childMenu };
        }
        return { ...menuItem, value: lMenu.value };
      }
      return menuItem;
    };

    if (selectedLanguage) {
      const menuBottom: IMenuItem[] = SidebarBottomMenu.map((item) =>
        setMenuLanguage(item)
      );
      const activitiesMenu: IMenuItem[] = [];
      userActivities.forEach((menu) => {
        if (menu.key === "menu_dashboard" && signedInUser) {
          activitiesMenu.push(setMenuLanguage(menu));
        } else if (
          (signedInUser?.activitiesAllowed?.findIndex(
            (act) => act === menu.key
          ) ?? -1) >= 0 ||
          menu.key === "menu_dashboard"
        ) {
          activitiesMenu.push(setMenuLanguage(menu));
        }
      });

      setActivities(activitiesMenu.filter((menu) => menu !== null));
      setBottomMenu(menuBottom);
    }
  }, [signedInUser, selectedLanguage, categories]);

  if (!authToken) {
    return null;
  }

  const onMenuClick = (menu: IMenuItem) => {
    if (menu.type === MenuType.TaskbarButton) {
      toggleTaskBar();
    } else {
      props.onDrawerClose();
      navigate(menu.path);
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={props.onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Box sx={{ flex: "1" }}>
          {activities.map((activity) => {
            return getMenuItem(activity, onMenuClick, open);
          })}
        </Box>
        {isDesktop && (
          <Box>
            <List>
              {bottomMenu.map((activity) => {
                return getMenuItem(activity, onMenuClick, open);
              })}
            </List>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
