import { makeAutoObservable, runInAction } from "mobx";
import {
  IBaseScreenDto,
  ResultOfSdgManagementDto,
  SDGClient,
  SdgManagementDto,
} from "../../utils/api";
import {
  IContextSensitiveViewModel,
  ContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import { DialogType } from "../../controls/dialogs";

export interface ISDGListViewModel extends IBasePageViewModel {
  model: SdgManagementDto | undefined;
}

export class SDGListViewModel implements ISDGListViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  model: SdgManagementDto | undefined = undefined;

  constructor(private selectedLanguage: string) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  setSdg = (result: ResultOfSdgManagementDto) => {
    console.log("SDG MANAGEMENT RESULT", result);
    if (result.succeeded) {
      this.dialogViewModel.clearDialog();
      this.model = result.value;
      this.contextViewModel.setContextActivities(
        this.model?.contextActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        this.model as IBaseScreenDto,
        undefined
      );
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };

  fetchData = () => {
    const client = new SDGClient();
    client
      .getSdgManagmentModel(this.selectedLanguage)
      .then((result) => this.setSdg(result))
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };
}
