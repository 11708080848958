import { initializeApp } from "firebase/app";
import {
  ActionCodeSettings,
  User,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { IKeyedObject } from "./types";
import { Language, LanguageContext } from "./language";
import { SignInUser } from "../utils/api";

const firebaseConfig = {
  apiKey: "AIzaSyBXTuLIaq3OVZDQS5Q7kgg8m_INgN4jGtQ",
  authDomain: "inclusive-trade-test.firebaseapp.com",
  projectId: "inclusive-trade-test",
  storageBucket: "inclusive-trade-test.appspot.com",
  messagingSenderId: "559980987658",
  appId: "1:559980987658:web:ed26ccab91f6db7166a6f3",
  measurementId: "G-874J1Y8XRC",
};
//const firebaseConfig = process.env.FIREBASE_CONFIG as FirebaseOptions;
console.log(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

export interface IHigherContextProviderProps {
  children: React.ReactNode;
}

export interface IRegisterUserResponse {
  success: boolean;
  userId: string | undefined;
  message: IFirebaseAuthError | undefined;
  providerId: string | null;
  displayName: string | null;
}

export interface IAuthContext {
  authToken: string | undefined;
  signIn: (
    email: string,
    password: string
  ) => Promise<IFirebaseAuthError | undefined>;
  signOut: () => void;
  register: (email: string, password: string) => Promise<IRegisterUserResponse>;
  getSignedInUser: () => SignInUser | undefined;
  sendPasswordResetEmail: (
    email: string
  ) => Promise<IFirebaseAuthError | undefined>;
  confirmPasswordReset: (password: string, oobCode: string) => Promise<any>;
}

export const FirebaseContext = React.createContext<IAuthContext>({
  authToken: undefined,
  signIn: (email: string, password: string) => {
    return new Promise(() => undefined);
  },
  signOut: () => {},
  register: (email: string, password: string) => {
    return new Promise(() => undefined);
  },
  getSignedInUser: () => undefined,
  sendPasswordResetEmail: (email: string) => {
    return new Promise(() => undefined);
  },
  confirmPasswordReset: (password: string, oobCode: string) => {
    return new Promise(() => undefined);
  },
});

export interface IFirebaseAuthError {
  errorCode: string;
  hasEmailError: boolean;
  hasPasswordError: boolean;
  hasSomeOtherError: boolean;
  error: string;
}

const defaultError: IFirebaseAuthError = {
  errorCode: "fberror_other",
  hasEmailError: false,
  hasPasswordError: false,
  hasSomeOtherError: false,
  error: "",
};

const fbEntries: IKeyedObject<string> = {
  fberror_email: "Invalid Email Address",
  fberror_password: "Invalid Password",
  fberror_other: "Error in Authentication",
  fberror_userexists: "User already exists with this email address",
  fberror_weakpassword: "Weak password, please provide atleast 6 characters",
  ER01: "Invalid User",
};

const getFirebaseError = (
  fbError: string,
  entries: IKeyedObject<string>
): IFirebaseAuthError => {
  switch (fbError) {
    case "EMAIL_NOT_FOUND":
    case "Firebase: Error (auth/user-not-found).":
      return {
        ...defaultError,
        errorCode: "fberror_email",
        hasEmailError: true,
        error: entries["fberror_email"],
      };
    case "Firebase: Error (auth/wrong-password).":
      return {
        ...defaultError,
        errorCode: "fberror_password",
        hasPasswordError: true,
        error: entries["fberror_password"],
      };
    case "Firebase: Error (auth/email-already-in-use).":
      return {
        ...defaultError,
        errorCode: "fberror_userexists",
        hasPasswordError: true,
        error: entries["fberror_userexists"],
      };
    case "Firebase: Password should be at least 6 characters (auth/weak-password).":
      return {
        ...defaultError,
        errorCode: "fberror_weakpassword",
        hasPasswordError: true,
        error: entries["fberror_weakpassword"],
      };
  }
  return {
    ...defaultError,
    hasSomeOtherError: true,
    error: fbError,
  };
};

export const FirebaseContextProvider: React.FC<IHigherContextProviderProps> = ({
  children,
}) => {
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [entries, setEntries] = useState<IKeyedObject<string>>(fbEntries);
  const { selectedLanguage, translate } = useContext(LanguageContext);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchAuthToken = async () => {
      const user = firebaseAuth.currentUser;
      setCurrentUser(user);
      console.log("set Current user in firebase", user);
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
      } else {
        if (authToken !== undefined) {
          setAuthToken(undefined);
        }
      }
    };

    firebaseAuth.onAuthStateChanged(fetchAuthToken);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorEntries = translate(
      Language.EntryCategory.FirebaseError,
      entries
    );
    setEntries(errorEntries);
  }, [selectedLanguage, translate]);

  const onSignIn = async (email: string, password: string) => {
    let message: IFirebaseAuthError | undefined = undefined;
    await signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(
        (user) => {},
        (rejected) => {
          message = getFirebaseError(rejected.message, entries);
        }
      )
      .catch((error) => {
        message = getFirebaseError(error.message, entries);
      });
    console.log(message);
    return message;
  };

  const onSignOut = () => {
    firebaseAuth.signOut().then(() => setAuthToken(undefined));
  };

  const onSendPasswordResetEmail = async (email: string) => {
    let message: IFirebaseAuthError | undefined = undefined;

    const settings: ActionCodeSettings = {
      url: window.location.protocol + "//" + window.location.host,
    };
    console.log("password reset settings", settings);
    await sendPasswordResetEmail(firebaseAuth, email, settings)
      .then(() => {})
      .catch((reason) => {
        console.log("Firebase Error", reason);
        message = getFirebaseError(reason.message, entries);
      });

    return message;
  };

  const onConfirmPasswordReset = async (password: string, oobCode: string) => {
    return await confirmPasswordReset(firebaseAuth, oobCode, password);
  };

  const onRegister = async (email: string, password: string) => {
    let message: IFirebaseAuthError | undefined = undefined;
    let success = false;
    let userId: string | undefined = undefined;
    let providerId: string | null = null;
    let displayName: string | null = null;

    await createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then(
        (newUser) => {
          userId = newUser.user.uid;
          providerId = newUser.providerId;
          displayName = newUser.user.displayName;
          success = true;
        },
        (rejected) => {
          message = getFirebaseError(rejected.message, entries);
        }
      )
      .catch((error) => {
        message = getFirebaseError(error.message, entries);
      });

    return { success, message, userId, providerId, displayName };
  };

  const getSignedInUser = () => {
    const signInUSer = new SignInUser();
    signInUSer.uId = currentUser?.uid ?? "";
    signInUSer.email = currentUser?.email ?? "";
    signInUSer.providerId = currentUser?.providerId ?? "";
    signInUSer.language = selectedLanguage;

    return signInUSer;
  };

  return (
    <FirebaseContext.Provider
      value={{
        authToken: authToken,
        signIn: onSignIn,
        signOut: onSignOut,
        register: onRegister,
        getSignedInUser: getSignedInUser,
        sendPasswordResetEmail: onSendPasswordResetEmail,
        confirmPasswordReset: onConfirmPasswordReset,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(FirebaseContext);
};
