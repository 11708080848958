import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { LanguageContext } from "../../core/language";
import {
  TenantProfileEditFormModel,
  TenantProfileViewModel,
} from "./tenantprofileViewModel";
import { BasePage } from "../../controls/basePage";
import { Paper, Typography } from "@mui/material";
import {
  FieldWrapperControl,
  FormButtonSection,
} from "../../controls/fieldWrapperControl";
import { useNavigate } from "react-router-dom";
import { IBasePageViewModel } from "../../common/pageTitleViewModel";

export const TenantProfile = observer(() => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [viewModel, setViewModel] = useState<
    TenantProfileViewModel | undefined
  >(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    setViewModel(new TenantProfileViewModel(selectedLanguage, navigate));
  }, [selectedLanguage, navigate]);

  if (viewModel) {
    return <TenantProfileView viewModel={viewModel} />;
  }
  return null;
});
interface ITenantProfileViewProps {
  viewModel: TenantProfileViewModel;
}

const TenantProfileView = observer((props: ITenantProfileViewProps) => {
  return (
    <BasePage pageDetails={props.viewModel as IBasePageViewModel}>
      <TenantProfileEditForm model={props.viewModel.model} />
    </BasePage>
  );
});
const TenantProfileEditForm = observer(
  (props: { model: TenantProfileEditFormModel | undefined }) => {
    if (!props.model) {
      return null;
    }
    return (
      <Paper sx={{ background: "transparent" }} elevation={0}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {props.model.pageTitle}
        </Typography>
        <FieldWrapperControl {...props?.model.title} />
        <FieldWrapperControl {...props?.model.subTitle} />
        <FieldWrapperControl {...props?.model.logoImage} />
        <FieldWrapperControl {...props?.model.homepageImage} />

        <FormButtonSection
          actions={props.model.actions}
          cancelAction={props.model.cancelAction}
        />
      </Paper>
    );
  }
);
