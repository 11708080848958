import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useCallback, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ActivityContext } from "../core/activityContext";
import { ExpandMore } from "@mui/icons-material";
import { IUIAction } from "../utils/api";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { TodoSection } from "./todoSection";
import { Box } from "@mui/material";

export const getActivityBarAcionListItem = (
  activity: IUIAction,
  navigate: NavigateFunction
) => {
  const onClick = (activity: IUIAction) => {
    navigate(activity.url);
  };

  return (
    <ListItem key={activity.code} disablePadding sx={{ display: "block" }}>
      <Tooltip title={activity.title} placement="bottom">
        <ListItemButton
          sx={{
            minHeight: 24,
            justifyContent: "center",
            px: 2.5,
          }}
          onClick={() => onClick(activity)}
        >
          <Icon
            sx={{
              minWidth: 0,
              justifyContent: "center",
            }}
          >
            {activity.icon}
          </Icon>
          <ListItemText
            sx={{ padding: "5px" }}
            primary={<Typography variant="body1">{activity.title}</Typography>}
          />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

export default function ActivityBar() {
  const { activities } = useContext(ActivityContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const getFavorites = useCallback(() => {
    const hasFavorites = activities.Favorites.activities.length > 0;
    return (
      <Accordion
        defaultExpanded={true}
        elevation={0}
        sx={{
          mb: 1,
          border: 1,
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="favorites"
          id="favorites"
          sx={{
            justifyContent: "flex-start",
            flexWrap: "wrap",
            wordBreak: "break-word",
          }}
        >
          <Typography sx={{ variant: "subtitle1", fontWeight: "bold" }}>
            {activities.Favorites.header}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ wordBreak: "break-word" }}>
          {hasFavorites &&
            activities.Favorites.activities.map((activity: IUIAction) => {
              return getActivityBarAcionListItem(activity, navigate);
            })}

          {!hasFavorites && (
            <Typography sx={{ variant: "body1" }}>
              No Favourites are set
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }, [activities, navigate, theme]);

  const getContext = useCallback(() => {
    return (
      <Paper sx={{ backgroundColor: theme.palette.background.default }}>
        {activities.Context.activities.map((activity: IUIAction) => {
          return getActivityBarAcionListItem(activity, navigate);
        })}
      </Paper>
    );
  }, [activities, navigate, theme]);

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: theme.palette.primary.light,
        width: { xs: "100%", sm: "100%", md: "275px" },
      }}
    >
      <List>
        {getFavorites()}
        {getContext()}
        <TodoSection />
      </List>
    </Box>
  );
}
