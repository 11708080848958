import {
  ContextSensitiveViewModel,
  IContextSensitiveViewModel,
} from "../../common/contextSensitiveViewModel";
import {
  IDialogViewModel,
  DialogViewModel,
} from "../../common/dialogViewModel";
import {
  IBasePageViewModel,
  IPageTitleViewModel,
  PageTitleViewModel,
} from "../../common/pageTitleViewModel";
import { makeAutoObservable, runInAction } from "mobx";
import {
  IBaseScreenDto,
  ICreateQuestion,
  ICreateQuestionData,
  IQuestionTargetModel,
  QuestionDto,
  QuestionManagementDto,
  QuestionSectionDto,
  ResultOfQuestionManagementDto,
  SDGClient,
  UIAction,
} from "../../utils/api";
import {
  DialogResult,
  DialogType,
  IComponentDialogProps,
} from "../../controls/dialogs";
import { FieldWrapper } from "../../common/formTypes";
import {
  IQuestionEditProps,
  ISectionEditProps,
  SDGQuestionEdit,
  SDGSectionEdit,
} from "./sdgQuestions";

export interface ISDGQuestionsViewModel extends IBasePageViewModel {
  sections: QuestionSectionViewModel[];
  addSectionAction: UIAction | undefined;
  handleAddSection: () => void;
}

export class SDGQuestionsViewModel implements ISDGQuestionsViewModel {
  contextViewModel: IContextSensitiveViewModel =
    new ContextSensitiveViewModel();
  dialogViewModel: IDialogViewModel = new DialogViewModel();
  pageTitleViewModel: IPageTitleViewModel = new PageTitleViewModel();
  sections: QuestionSectionViewModel[] = [];
  addSectionAction: UIAction | undefined;
  editSectionDialogProps: IComponentDialogProps | undefined = undefined;
  model: QuestionManagementDto | undefined = undefined;
  editSectionModel: ISectionEditProps | undefined;
  editQuestionModel: IQuestionEditProps | undefined;

  handleAddSection = () => {
    if (this.model) {
      this.model.editSectionModel.id = "";
      this.model.editSectionModel.name.value = "";
    }

    this.editSectionModel = {
      id: "new",
      sectionName: new FieldWrapper(),
      rowVersion: "",
    };

    if (this.model) {
      this.editSectionModel.sectionName.setField(
        this.model.editSectionModel.name,
        this.onSectionEditValueChanged
      );
    }

    this.editSectionDialogProps = {
      okDisabled: true,
      cancelLabel: this.model?.editSectionModel.cancelLabel ?? "",
      okLabel: this.model?.editSectionModel.saveLabel ?? "",
      okVisible: true,
      cancelVisible: true,
      component: <SDGSectionEdit {...this.editSectionModel} />,
    };

    this.dialogViewModel.setDialog(
      this.model?.editSectionModel.addTitle ?? "",
      "",
      DialogType.ComponentDialog,
      this.handleSaveSection,
      this.editSectionDialogProps,
      false
    );
  };

  onSectionEditValueChanged = () => {
    if (this.editSectionModel?.sectionName.canSave()) {
      this.dialogViewModel.setOkAllowed(true);
    } else {
      this.dialogViewModel.setOkAllowed(false);
    }
  };

  handleAddQuestion = (section: QuestionSectionDto) => {
    if (this.model) {
      this.editQuestionModel = {
        editModel: new QuestionViewModel(
          this.model.editQuestionModel,
          section.id,
          this.handleEditQuestion,
          this.handleDeleteQuestion,
          this.onQuestionValueChange,
          this.handleReviewQuestion
        ),
      };
      this.editSectionDialogProps = {
        okDisabled: true,
        cancelLabel: this.model?.editQuestionModel.cancelLabel ?? "",
        okLabel: this.model?.editQuestionModel.saveLabel ?? "",
        okVisible: true,
        cancelVisible: true,
        component: <SDGQuestionEdit {...this.editQuestionModel} />,
      };

      this.dialogViewModel.setDialog(
        this.model?.editQuestionModel.addTitle ?? "",
        "",
        DialogType.ComponentDialog,
        this.handleSaveQuestion,
        this.editSectionDialogProps,
        false
      );
    }
  };
  onQuestionValueChange = () => {
    this.dialogViewModel.setOkAllowed(
      this.editQuestionModel?.editModel?.saveAllowed ?? false
    );
  };

  handleApproveSection = (result: DialogResult) => {
    if (result === DialogResult.None) {
      return;
    }

    const approval: any = {
      language: this.selectedLanguage,
      sdgId: this.id,
      id: this.editSectionModel?.id ?? "",
      approved: result === DialogResult.Yes,
      rowVersion: this.editSectionModel?.rowVersion ?? "",
    };
    const client = new SDGClient();
    client
      .setQuestionSectionApproval(approval)
      .then((result) => {
        if (result.succeeded) {
          this.setSdgQuestions(result);
        } else {
          this.dialogViewModel.setDialog(
            result.errorCode,
            result.error,
            DialogType.Alert
          );
        }
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };

  handleApproveQuestion = (result: DialogResult) => {
    if (result === DialogResult.None) {
      return;
    }

    const approval: any = {
      language: this.selectedLanguage,
      sdgId: this.id,
      id: this.editQuestionModel?.editModel.question.id ?? "",
      approved: result === DialogResult.Yes,
      rowVersion: this.editQuestionModel?.editModel.question.rowVersion ?? "",
    };
    const client = new SDGClient();
    client
      .setQuestionApproval(approval)
      .then((result) => {
        if (result.succeeded) {
          this.setSdgQuestions(result);
        } else {
          this.dialogViewModel.setDialog(
            result.errorCode,
            result.error,
            DialogType.Alert
          );
        }
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };

  handleReviewQuestion = (question: QuestionViewModel) => {
    this.editQuestionModel = {
      editModel: question,
    };

    this.editSectionDialogProps = {
      okDisabled: false,
      cancelLabel: this.model?.editQuestionModel.rejectLabel ?? "",
      okLabel: this.model?.editQuestionModel.approveLabel ?? "",
      okVisible: true,
      cancelVisible: true,
      component: <SDGQuestionEdit {...this.editQuestionModel} />,
      contentDisabled: true,
    };

    this.dialogViewModel.setDialog(
      this.model?.editQuestionModel.editTitle ?? "",
      "",
      DialogType.ComponentDialog,
      this.handleApproveQuestion,
      this.editSectionDialogProps,
      true
    );
  };

  handleEditQuestion = (question: QuestionViewModel) => {
    this.editQuestionModel = {
      editModel: question,
    };

    this.editSectionDialogProps = {
      okDisabled: true,
      cancelLabel: this.model?.editQuestionModel.cancelLabel ?? "",
      okLabel: this.model?.editQuestionModel.saveLabel ?? "",
      okVisible: true,
      cancelVisible: true,
      component: <SDGQuestionEdit {...this.editQuestionModel} />,
    };

    this.dialogViewModel.setDialog(
      this.model?.editQuestionModel.editTitle ?? "",
      "",
      DialogType.ComponentDialog,
      this.handleSaveQuestion,
      this.editSectionDialogProps,
      false
    );
  };

  handleSaveQuestion = (result: DialogResult) => {
    if (result === DialogResult.Yes) {
      console.log("Saving question", this.editQuestionModel);
      const client = new SDGClient();
      client
        .saveSDGQuestion(
          this.selectedLanguage,
          this.getQuestionModel(this.editQuestionModel),
          this.editQuestionModel?.editModel.question.id
        )
        .then((result) => {
          if (result.succeeded) {
            this.setSdgQuestions(result);
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    }
  };

  getQuestionModel = (
    editQuestionModel: IQuestionEditProps | undefined
  ): any => {
    const model: ICreateQuestion = {
      languageCode: this.selectedLanguage,
      questionText: editQuestionModel?.editModel.quesionText.fieldValue,
      description: editQuestionModel?.editModel.description.fieldValue,
      questionType: editQuestionModel?.editModel.questionType.fieldValue,
      requiresUpload: editQuestionModel?.editModel.requiresUpload.fieldValue,
      sectionId: editQuestionModel?.editModel.sectionId ?? "",
      questionTargets: this.getQuestionTargets(editQuestionModel),
      hint: editQuestionModel?.editModel.hint.fieldValue,
      notesToApprover: editQuestionModel?.editModel.notesToApprover.fieldValue,
      questionData: this.getQuestionData(editQuestionModel),
    };

    return model;
  };

  getQuestionTargets = (
    editQuestionModel: IQuestionEditProps | undefined
  ): any => {
    var model: IQuestionTargetModel[] = [];
    const fieldValue =
      editQuestionModel?.editModel.targets.fieldValue ?? undefined;

    if (fieldValue) {
      const targets = fieldValue.split(/\r?\n/);
      targets.map((target: string) => {
        const [code, description] = target.split("-", 2);
        const targetModel: IQuestionTargetModel = {
          id: "",
          code: code,
          description: description,
          isDeleted: false,
        };
        if (targetModel.code !== "" && targetModel.description !== "") {
          console.log("target model", targetModel);
          model.push(targetModel);
        }
      });
    }
    return model;
  };

  getQuestionData = (
    editQuestionModel: IQuestionEditProps | undefined
  ): any => {
    var model: ICreateQuestionData = {
      choices: editQuestionModel?.editModel.choices.fieldValue ?? "",
      otherChoiceRequired: false,
      points: editQuestionModel?.editModel.choicePoints.fieldValue ?? "",
    };
    return model;
  };

  handleDeleteQuestionConfirmation = (
    result: DialogResult,
    question: QuestionViewModel
  ) => {
    if (result == DialogResult.Yes) {
      const client = new SDGClient();
      client
        .deleteSDGQuestion(this.id, this.selectedLanguage, question.question.id)
        .then((result) => {
          if (result.succeeded) {
            this.setSdgQuestions(result);
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    }
  };
  handleDeleteQuestion = (question: QuestionViewModel) => {
    this.dialogViewModel.setDialog(
      (this.model?.deleteQuestionModel.deleteTitle ?? "") +
        " - '" +
        question.quesionText.fieldValue +
        "'",
      this.model?.deleteQuestionModel.deleteMessage ?? "",
      DialogType.YesNo,
      (result) => this.handleDeleteQuestionConfirmation(result, question)
    );
  };

  handleReviewSection = (section: QuestionSectionDto) => {
    if (this.model) {
      this.model.editSectionModel.id = section.id;
      this.model.editSectionModel.name.value = section.name.value;
    }

    this.editSectionModel = {
      id: section.id,
      sectionName: new FieldWrapper(),
      rowVersion: section.rowVersion,
    };

    if (this.model) {
      this.editSectionModel.sectionName.setField(
        this.model.editSectionModel.name,
        this.onSectionEditValueChanged
      );
    }

    this.editSectionDialogProps = {
      okDisabled: false,
      cancelLabel: this.model?.editSectionModel.rejectLabel ?? "",
      okLabel: this.model?.editSectionModel.approveLabel ?? "",
      okVisible: true,
      cancelVisible: true,
      component: <SDGSectionEdit {...this.editSectionModel} />,
    };

    this.dialogViewModel.setDialog(
      this.model?.editSectionModel.addTitle ?? "",
      "",
      DialogType.ComponentDialog,
      this.handleApproveSection,
      this.editSectionDialogProps,
      true
    );
  };

  handleEditSection = (section: QuestionSectionDto) => {
    if (this.model) {
      this.model.editSectionModel.id = section.id;
      this.model.editSectionModel.name.value = section.name.value;
    }

    this.editSectionModel = {
      id: section.id,
      sectionName: new FieldWrapper(),
      rowVersion: section.rowVersion,
    };

    if (this.model) {
      this.editSectionModel.sectionName.setField(
        this.model.editSectionModel.name,
        this.onSectionEditValueChanged
      );
    }

    this.editSectionDialogProps = {
      okDisabled: true,
      cancelLabel: this.model?.editSectionModel.cancelLabel ?? "",
      okLabel: this.model?.editSectionModel.saveLabel ?? "",
      okVisible: true,
      cancelVisible: true,
      component: <SDGSectionEdit {...this.editSectionModel} />,
    };

    this.dialogViewModel.setDialog(
      this.model?.editSectionModel.addTitle ?? "",
      "",
      DialogType.ComponentDialog,
      this.handleSaveSection,
      this.editSectionDialogProps,
      false
    );
  };

  handleDeleteSectionConfirmation = (
    result: DialogResult,
    section: QuestionSectionDto
  ) => {
    if (result == DialogResult.Yes) {
      console.log("Deleting Section", this.editSectionModel);
      const client = new SDGClient();
      client
        .deleteSDGQuestionSection(this.id, this.selectedLanguage, section.id)
        .then((result) => {
          if (result.succeeded) {
            this.setSdgQuestions(result);
          } else {
            this.dialogViewModel.setDialog(
              result.errorCode,
              result.error,
              DialogType.Alert
            );
          }
        })
        .catch((reason) => {
          this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
        });
    }
  };

  handleDeleteSection = (section: QuestionSectionDto) => {
    this.dialogViewModel.setDialog(
      (this.model?.deleteSectionModel.deleteTitle ?? "") +
        " - '" +
        section.name.value +
        "'",
      this.model?.deleteSectionModel.deleteMessage ?? "",
      DialogType.YesNo,
      (result) => this.handleDeleteSectionConfirmation(result, section)
    );
  };

  handleSaveSection = () => {
    console.log("Saving Section", this.editSectionModel);
    const client = new SDGClient();
    client
      .saveSDGQuestionSection(
        this.id,
        this.selectedLanguage,
        this.editSectionModel?.sectionName.fieldValue,
        this.editSectionModel?.id
      )
      .then((result) => {
        if (result.succeeded) {
          this.setSdgQuestions(result);
        } else {
          this.dialogViewModel.setDialog(
            result.errorCode,
            result.error,
            DialogType.Alert
          );
        }
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };

  setSdgQuestions = (result: ResultOfQuestionManagementDto) => {
    if (result.succeeded) {
      this.contextViewModel.setContextActivities(
        result.value?.contextActions ?? []
      );
      this.pageTitleViewModel.setPageDetails(
        result.value as IBaseScreenDto,
        this.id.toString()
      );
      this.dialogViewModel.clearDialog();
      if (result.value) {
        this.sections = result.value.sections.map(
          (x) =>
            new QuestionSectionViewModel(
              x,
              this.handleEditSection,
              this.handleDeleteSection,
              this.handleAddQuestion,
              this.handleEditQuestion,
              this.handleDeleteQuestion,
              this.onQuestionValueChange,
              this.handleReviewSection,
              this.handleReviewQuestion
            )
        );
        this.addSectionAction = result.value.addSectionAction;
        this.model = result.value;
      }

      this.dialogViewModel.clearDialog();
    } else {
      this.dialogViewModel.setDialog(
        result.errorCode,
        result.error,
        DialogType.Alert
      );
    }
  };

  constructor(
    private selectedLanguage: string,
    private id: number,
    private isApprove: boolean
  ) {
    makeAutoObservable(this);
    runInAction(() => {
      this.fetchData();
    });
  }

  fetchData = () => {
    this.dialogViewModel.setDialog("", "", DialogType.Progress);
    const client = new SDGClient();
    client
      .getSDGQuestionsModel(this.id, this.selectedLanguage, this.isApprove)
      .then((result) => {
        this.setSdgQuestions(result);
      })
      .catch((reason) => {
        this.dialogViewModel.setDialog(reason, reason, DialogType.Alert);
      });
  };
}
interface IQuestionSectionViewModel {
  handleEditAction: () => void;
  handleDeleteAction: () => void;
  questions: QuestionViewModel[];
  name: FieldWrapper;
  saveAllowed: boolean;
}

export class QuestionSectionViewModel implements IQuestionSectionViewModel {
  handleEditAction = () => {
    this.onEditCalback(this.section);
  };

  handleDeleteAction = () => {
    this.onDeleteCallback(this.section);
  };

  handleAddQuestionAction = () => {
    this.onAddQuestionCallback(this.section);
  };

  handleReviewSection = () => {
    this.onSectionReviewCallback(this.section);
  };
  questions: QuestionViewModel[] = [];
  name: FieldWrapper = new FieldWrapper();
  saveAllowed: boolean = false;
  onValueChange = () => {
    this.saveAllowed = this.name.canSave();
  };

  constructor(
    public section: QuestionSectionDto,
    private onEditCalback: (section: QuestionSectionDto) => void,
    private onDeleteCallback: (section: QuestionSectionDto) => void,
    private onAddQuestionCallback: (section: QuestionSectionDto) => void,
    private onQuestionEditCalback: (section: QuestionViewModel) => void,
    private onQuestionDeleteCallback: (question: QuestionViewModel) => void,
    private onQuestionValueChanged: () => void,
    private onSectionReviewCallback: (section: QuestionSectionDto) => void,
    private onQuestionReviewCallback: (question: QuestionViewModel) => void
  ) {
    makeAutoObservable(this);
    this.name.setField(this.section.name, this.onValueChange);
    this.questions = section.questions.map(
      (q) =>
        new QuestionViewModel(
          q,
          section.id,
          this.onQuestionEditCalback,
          this.onQuestionDeleteCallback,
          this.onQuestionValueChanged,
          this.onQuestionReviewCallback
        )
    );
  }
}

export class QuestionViewModel {
  quesionText: FieldWrapper = new FieldWrapper();
  description: FieldWrapper = new FieldWrapper();
  questionType: FieldWrapper = new FieldWrapper();
  hint: FieldWrapper = new FieldWrapper();
  requiresUpload: FieldWrapper = new FieldWrapper();
  notesToApprover: FieldWrapper = new FieldWrapper();
  choices: FieldWrapper = new FieldWrapper();
  choicePoints: FieldWrapper = new FieldWrapper();
  targets: FieldWrapper = new FieldWrapper();
  saveAllowed: boolean = false;

  onValueChange = () => {
    this.saveAllowed =
      this.quesionText.canSave() &&
      this.description.canSave() &&
      this.questionType.canSave() &&
      this.hint.canSave() &&
      this.requiresUpload.canSave() &&
      this.notesToApprover.canSave() &&
      this.choices.canSave() &&
      this.choicePoints.canSave();
    this.targets.canSave();

    this.onQuestionValueChange();
  };

  onQuestionTypeChange = () => {
    const questionType = this.questionType.fieldValue;
    if (questionType == "MultipleChoice" || questionType == "SingleChoice") {
      this.choices.setIsRequired(true);
      this.choices.setIsVisible(true);
      this.choicePoints.setIsRequired(true);
      this.choicePoints.setIsVisible(true);
    } else {
      this.choices.setIsRequired(false);
      this.choices.setIsVisible(false);
      this.choicePoints.setIsRequired(false);
      this.choicePoints.setIsVisible(false);
    }
    this.onValueChange();
  };

  handleEditAction() {
    this.onEditCalback(this);
  }
  handleDeleteAction() {
    this.onDeleteCallback(this);
  }
  handleReviewQuestion() {
    this.onReviewCallback(this);
  }

  constructor(
    public question: QuestionDto,
    public sectionId: string,
    private onEditCalback: (question: QuestionViewModel) => void,
    private onDeleteCallback: (question: QuestionViewModel) => void,
    private onQuestionValueChange: () => void,
    private onReviewCallback: (question: QuestionViewModel) => void
  ) {
    this.quesionText.setField(this.question.questionText, this.onValueChange);
    this.description.setField(this.question.description, this.onValueChange);
    this.questionType.setField(
      this.question.questionType,
      this.onQuestionTypeChange
    );
    this.hint.setField(this.question.hint, this.onValueChange);
    this.requiresUpload.setField(
      this.question.requiresUpload,
      this.onValueChange
    );
    this.notesToApprover.setField(
      this.question.notesToApprover,
      this.onValueChange
    );

    this.choices.setField(this.question.choices, this.onValueChange);
    this.choicePoints.setField(this.question.choicePoints, this.onValueChange);
    this.targets.setField(this.question.targets, this.onValueChange);
    this.onQuestionTypeChange();
    makeAutoObservable(this);
  }
}
