import { makeAutoObservable } from "mobx";
import { IUIAction } from "../utils/api";

export interface IContextSensitiveViewModel {
  contextActivities: IUIAction[];
  setContextActivities: (activities: IUIAction[]) => void;
}

export class ContextSensitiveViewModel implements IContextSensitiveViewModel {
  contextActivities: IUIAction[] = [];

  setContextActivities = (activities: IUIAction[]) => {
    this.contextActivities = activities ?? [];
  };

  constructor() {
    makeAutoObservable(this);
  }
}
